import * as React from 'react';
import { PieChart, pieArcLabelClasses  } from '@mui/x-charts/PieChart';


  const getArcLabel = (params) => {
    return `${params.value}`;
  };

const pieChartsParams = {
    
    series: [
      {
        data: [{ value: 5 ,label: "A"}, { value: 10, label:"B" }, { value: 15, label:"C" }, { value: 20, label:"D" }, { value: 25, label:"E" }],
        label: 'Series 1',
        outerRadius: 120,
        highlighted: { additionalRadius: 10 },
        arcLabel: getArcLabel,
        innerRadius: 50,
        paddingAngle: 1,
        cornerRadius: 2,
        startAngle: -90,
        endAngle: 270,
        cx: 120,
        cy: 170,
      },
     
    ],
    height: 400,
    width: 360,
    
  };
  
const PieChartLic = ({ data }) => {
    const [highlighted, setHighlighted] = React.useState('item');
  const [faded, setFaded] = React.useState('global');

  console.log("PieChartLic data",data);

 const params = {...pieChartsParams, series: [{...pieChartsParams.series[0], data: data}]};
    return (
        <div>
            <PieChart className='MuiTooltip-popper'
                colors={['#ff6900', '#fcb900', '#78dcb5', '#00d084', '#8ed1fc', '#abb8c3', '#eb144c', '#f78da7', '#9900ef', '#2196f3', 'teal', '#ffeb3d']} 
                {...params}
                series={params.series.map((series) => ({
                ...series,
                highlightScope: {
                    highlighted,
                    faded,
                },
                }))}
                sx={{
                    [`& .${pieArcLabelClasses.root}`]: {
                    fill: 'black',
                    fontWeight: 'bold',
                        fontSize: 12,
                    },
                }}
                tooltip={{classes:"MuiTooltip-popper", trigger: 'item', formatter: (params) => `${params.label}: ${params.value}`}}
             />
        </div>
    )
}

export default PieChartLic;