import "./Dialog.scss";

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import ExerciseTextEdit from "../Exercises/ExerciseTextEdit";

const NewExerciseTextDialog = ({open, id, handleClose, handleUpdateRows, languageList, exerciseId}) => {
  //console.log("New exercise dialog");
  console.log("NewExerciseTextDialog: ", exerciseId);
    return (
        <Dialog 
        PaperProps={{
          sx: {
            minHeight: 550
          }
        }}
        className="editdialog" 
        open={open} 
        fullWidth={true}
        maxWidth={"sm"}
        >
        <DialogTitle className="dialogtitle">
          <div className="left">
          Exercise Linguistic Information
          </div>
            <IconButton onClick={handleClose} className="right">
              <CloseIcon />
            </IconButton>
        </DialogTitle>
        <DialogContent  className="dialogcontent" >
            <div style={{padding:"10px"}}>
          <ExerciseTextEdit id ={id} handleUpdateRows={handleUpdateRows} handleClose={handleClose} languageList={languageList} exerciseId={exerciseId} />
          </div>
        </DialogContent>
      </Dialog>
    );

}

export default NewExerciseTextDialog;