import "./Dialog.scss";

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import CustomFieldsEdit from "../CustomFieldMetadata/CustomFieldsEdit";

const CustomFieldsMetadataDialog = ({open, handleClose, id, handleSave}) => {
    return ( <Dialog 
        PaperProps={{
          sx: {
            minHeight: 770
          }
        }}
          className="editdialog" 
          open={open} 
          fullWidth={true}
          maxWidth={"lg"}
          >
          <DialogTitle className="dialogtitle">
            <div className="left">
                Custom Fields Metadata
            </div>
              <IconButton onClick={handleClose} className="right">
                <CloseIcon />
              </IconButton>
          </DialogTitle>
          <DialogContent  className="dialogcontent">
            <CustomFieldsEdit id={id} handleSave={handleSave} handleClose={handleClose} />
          </DialogContent>
        </Dialog>
    )

}

export default CustomFieldsMetadataDialog;