import {addMonths, format} from 'date-fns'
export const CSVString2Array = (csvString) => {
    //console.log("csvString",csvString)
    if (csvString === null) return [];
    if (csvString === "") return [];
    return csvString.split(',');
}

export const Array2CSVString = (array) => {
    if (array.length === 0) return "";
    if (array.length < 2) return array[0];
    return array.join(',');
}

export const String2NumberOfItems = (csvString) => {
    //console.log("csvString: ",csvString);

    if (csvString === null) {
        return 0;
    } else if (csvString === "")  {
        return 0;
    } else {
        return csvString.split(',').length;
    }
}

export const ServerURL = () => {
    return "http://localhost:5021";
}

export const GetServerURLFromName = (name) => {
    let URL = '';
    if (name === "kinetisenseclouddev") {
        URL="https://kinetisenseclouddevapp.azurewebsites.net";
    } else if (name ==="kinetisensecloudeducationapp") {
        URL="https://kinetisensecloudeducation.azurewebsites.net";
    } else if (name ==="kinetisensecloudeastusapp") {
        URL="https://kinetisensecloudeastusapp.azurewebsites.net";
    } else if (name ==="kinetisensecloudcentralcanada") {
        URL="https://kinetisensecloudcentralcanada.azurewebsites.net";
    } else if (name ==="kinetisensecloudwestusapp") {
        URL="https://kinetisensecloudwestusapp.azurewebsites.net";
    } else if (name ==="kinetisensecloudeastasiaapp") {
        URL="https://kinetisensecloudeastasiaapp.azurewebsites.net";
    } else if (name ==="kinetisensecloudwesteurope") {
        URL="https://kinetisensecloudwesteurope.azurewebsites.net";
    } else if (name ==="kinetisensecloudeducation") {
        URL="https://kinetisensecloudeducation.azurewebsites.net";
    } else if (name ==="kinetisensecloudsoutheastaustralia") {
        URL="https://kinetisensecloudsoutheastaustralia.azurewebsites.net";
    } else if (name ==="Local") {
        URL="http://localhost:5000";
    }  else {
        URL="";
    }
    return URL;
}

export const GetServerNameFromURL = (URL) => {
    let name = '';
    if (URL === "https://kinetisenseclouddevapp.azurewebsites.net") {
        name="kinetisenseclouddev";
    } else if (URL === "https://kinetisensecloudeducation.azurewebsites.net") {
        name="kinetisensecloudeducationapp";
    } else if (URL === "https://kinetisensecloudeastusapp.azurewebsites.net") {
        name="kinetisensecloudeastusapp";
    } else if (URL === "https://kinetisensecloudcentralcanada.azurewebsites.net") {
        name="kinetisensecloudcentralcanada";
    } else if (URL === "https://kinetisensecloudwestusapp.azurewebsites.net") {
        name="kinetisensecloudwestusapp";
    } else if (URL === "https://kinetisensecloudeastasiaapp.azurewebsites.net") {
        name="kinetisensecloudeastasiaapp";
    } else if (URL === "https://kinetisensecloudwesteurope.azurewebsites.net") {
        name="kinetisensecloudwesteurope";
    } else if (URL ==="https://kinetisensecloudeducation.azurewebsites.net") {
        name="kinetisensecloudeducation";
    } else if (URL === "https://kinetisensecloudsoutheastaustralia.azurewebsites.net") {
        name="kinetisensecloudsoutheastaustralia";
    } else if (URL === "http://localhost:5000") {
        name="Local";
    }  else {
        name="";
    }
    return name;
}

export const DatePlus30 = () => {
    // var d0 = new Date();
    // var year = d0.getFullYear();
    // var month = d0.getMonth();
    // var day = d0.getDate();
    // var hours = d0.getHours();
    // var minutes = d0.getMinutes();
    // var seconds = d0.seconds();
    // console.log("date--------------------------------------: ", formatRFC3339(new Date(year , month, day,hours, minutes,seconds)))
    // if (month + 1 <= 12) {
    //     console.log("date: ", formatRFC3339(new Date(year , month-1, day,hours, minutes,seconds)))
    //     return (formatRFC3339(new Date(year , month-1, day,hours, minutes,seconds)));
    // } else {
    //     console.log(formatRFC3339("Date: ",new Date(year + 1, 1, day,hours,minutes,seconds)));
    //     return (formatRFC3339(new Date(year + 1, 1, day,hours,minutes,seconds)));
    // }
    
    const Plus1Month = addMonths(new Date(), 1)
    console.log("New date------------------------:",format(Plus1Month , 'YYYY-MM-DDTHH:mm:ss.SSSZZ'));

   //return format(Plus1Month , 'YYYY-MM-DDTHH:mm:ss.SSSZZ') 
   return Date.UTC;
}
