import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { OrganizationUserRoles } from "../../../data/ControlledLists";
import Checkbox from '@mui/material/Checkbox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import {CSVString2Array, Array2CSVString} from "../../../Utilities/Utilities";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;


 const  OrganizationInformation = ({data, onFieldChange}) => {    //  const [row, setRow] = useState(LicenceData);


    const handleListUpdate= (event, newValue) => {
        console.log(Array2CSVString(newValue));
        onFieldChange(event, newValue, "roles");
    }

    if (data.name !== undefined) {
    return (
        <div >
            <div>
                <TextField
                    fullWidth
                    disabled={!data.isActive}
                    id="username" 
                    label="Username" 
                    variant="outlined"
                    className="TextField"
                    value={data.username}
                    onChange={(event, value) => onFieldChange(event, event.target.value, event.target.id)}
                    InputLabelProps={{
                        shrink: true,
                      }}
                />
            </div>
            <div>
                <TextField
                    fullWidth
                    disabled={!data.isActive}
                    id="name" 
                    label="Name" 
                    variant="outlined"
                    className="TextField"
                    value={data.name}
                    onChange={(event, value) => onFieldChange(event, event.target.value, event.target.id)}
                    InputLabelProps={{
                        shrink: true,
                      }}
                />
            </div>
            <div>
                <TextField
                    fullWidth
                    disabled={!data.isActive}
                    id="middleName" 
                    label="Middle Name" 
                    variant="outlined"
                    className="TextField"
                    value={data.middleName}
                    onChange={(event, value) => onFieldChange(event, event.target.value, event.target.id)}
                    InputLabelProps={{
                        shrink: true,
                      }}
                />
            </div>
            <div>
                <TextField
                    fullWidth
                    disabled={!data.isActive}
                    id="surname" 
                    label="Surname" 
                    variant="outlined"
                    className="TextField"
                    value={data.surname}
                    onChange={(event, value) => onFieldChange(event, event.target.value, event.target.id)}
                    InputLabelProps={{
                        shrink: true,
                      }}
                />
            </div>
            <div>
                <TextField
                    fullWidth
                    disabled={!data.isActive}
                    id="email" 
                    label="Email" 
                    variant="outlined"
                    className="TextField"
                    value={data.email}
                    onChange={(event, value) => onFieldChange(event, event.target.value, event.target.id)}
                    InputLabelProps={{
                        shrink: true,
                      }}
                />
            </div>
            <div>
                <TextField
                    fullWidth
                    disabled={!data.isActive}
                    id="phone" 
                    label="Phone Number" 
                    variant="outlined"
                    className="TextField"
                    value={data.phone}
                    onChange={(event, value) => onFieldChange(event, event.target.value, event.target.id)}
                    InputLabelProps={{
                        shrink: true,
                      }}
                />
            </div>
            
            <div>
               
                    <Autocomplete
                                className="combobox"
                                fullWidth
                                disabled={!data.isActive}
                                multiple
                                id="roles"
                                options={OrganizationUserRoles}
                                disableCloseOnSelect
                                getOptionLabel={(option) => option}
                                value = {CSVString2Array(data.roles).sort((a,b) => a > b ? 1 : -1)}
                                renderOption={(props, option, { selected }) => (
                                    <li {...props}>
                                    <Checkbox
                                        icon={icon}
                                        checkedIcon={checkedIcon}
                                        style={{ marginRight: 8 }}
                                        checked={selected}
                                        />
                                    {option}
                                    </li>
                                )}
                                renderInput={(params) => (
                                    <TextField {...params} label="Roles" placeholder={"Filter"}/>
                                    )}
                                    onChange = {(event,newValue) => handleListUpdate(event, newValue)}
                                    onInputChange={(event,newValue) => handleListUpdate(event, newValue)}
                        />
            </div>

            <div>
                <TextField
                    fullWidth
                    disabled={!data.isActive}
                    id="notes" 
                    label="Notes" 
                    variant="outlined"
                    multiline
                    rows={5}
                    type="TextField"
                    className="TextField"
                    value={data.notes}
                    onChange={(event, value) => onFieldChange(event, event.target.value, event.target.id)}
                    InputLabelProps={{
                        shrink: true,
                      }}
                />
             </div>
           
        </div>
    );
    } else {
        return (<label>Loading...</label>)
    }
}

export default OrganizationInformation;