import { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import LicenceData from "../../data/LicenceData";
import { useSession } from "../../context/SessionContext";
import ErrorOkDialog from "../dialogs/ErrorOkDialog";

const LicenceDelete = ({id, handleClose, handleDeleteRow}) => {

    const [row,setRow] = useState(LicenceData);
    const [displayError, setDisplayError] = useState(false);
    const [message, setMessage] = useState();
    const session = useSession();

        console.log("LicenceDelete row: ", row);
   useEffect (() => {
        
        try { 
          fetch(session.subscriptionServer + "/tables/subscription/" + id, {
              method: 'GET',
              headers:{"Content-type":"application/json"}
          }).then ((response) => response.json())
          .then ((data) => {
              setRow(data.queryable[0]);
          }).catch ((error) => {
              console.log(error);
          });  
        } catch(err) {
          console.log(err);
        }
     },[id,  session]);

     const handleDelete = () => {
        console.log("Delete licence");
        try { 
            fetch(session.subscriptionServer + "/tables/subscription/" + id, {
                method: 'DELETE',
                headers:{"Content-type":"application/json"}
            })//.then ((response) => response.json())
            .then ((data) => {
                handleDeleteRow(id);
                handleClose();
            }).catch ((error) => {
                console.log(error);
                setMessage(error.message);
                setDisplayError(true);
            });  
          } catch(err) {
            console.log(err);
          }
     }

     const handleErrorDialogClose = () =>{
        setDisplayError(false)
     }
    return (
    <>
        <ErrorOkDialog open={displayError} message={message} handleClose={handleErrorDialogClose} />
        <div>
            <div className="forminput">
                <strong>Do you want to delete the Licence: {row.licenseKey}?</strong>
            </div>
            <div className="formButtons" >
                <div className="right">
                <Button onClick={handleDelete}>Yes</Button>
                <Button onClick={handleClose}>No</Button>
                </div>
            </div>
        </div>
    </>
    )
}

export default LicenceDelete;