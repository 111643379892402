import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { LicenceTypes } from '../../data/ControlledLists';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

const getDate = () => {
    var d = new Date();
    var year = d.getFullYear() + 1;
    var month = d.getMonth() + 1;
    var day = d.getDate();
    var datestring = year  + "/" + month + "/" + day;
    return datestring;
}

 const  LicenceNewInformation = ({data, onFieldChange, qty, organizations, flexLicenceTemplates}) => {    //  const [row, setRow] = useState(LicenceData);

   const max=100;
   const min = 0;
   console.log("data: ", data);

   const [value, setValue] = React.useState(new Date(getDate()));

   const handleChange = (newValue) => {
     setValue(newValue);
     //console.log(newValue);
     try {
        onFieldChange(null, newValue.toISOString(), "expirationDate")
     } catch (err) {
        
     }
   };

   const showTemplates = () => {
        const templates = flexLicenceTemplates.map((el) => {
            return el.name;
        });

        return (
            <div>
                <Autocomplete
                id="template"
                className="combobox"
                autoHighlight={true}
                disablePortal
                noOptionsText="Licence Template"
                value={data.displayName}
                options={templates.sort((a,b) => a > b ? 1 : -1)}
                onChange={(event, value) => onFieldChange(event, value, "displayName")}
                renderInput={(params) => <TextField {...params} label="Licence Template" />}
            />
            </div>
        );
    }

    if (data.purchaseEmail !== undefined) {
    return (
        <div className='panel'>

           
            <div>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                        label="Licence Expiration Date"
                        value={value}
                        onChange={(newValue) => {
                            handleChange(newValue);
                        }}
                        renderInput={(params) => <TextField {...params} />}
                        autoFocus={true} // <===========
                    />
                </LocalizationProvider>
            </div>        
            <div>
               
                <Autocomplete
                id="name"
                className="combobox"
                autoHighlight={true}
                disablePortal
                noOptionsText="Licence Type"
                value={data.name}
                options={LicenceTypes}
                onChange={(event, value) => onFieldChange(event, value, "name")}
                renderInput={(params) => <TextField {...params} label="Licence Type" />}
               
                />
            </div>
            {data.name === 'kinetisense-flex' && showTemplates()}
            <div>
                <TextField
                    fullWidth
                    id="purchaseName" 
                    label="Purchase Name" 
                    variant="outlined"
                    className="TextField"
                    value={data.purchaseName}
                    onChange={(event, value) => onFieldChange(event, event.target.value, event.target.id)}
                    InputLabelProps={{
                        shrink: true,
                      }}
                />
            </div>
            <div>
                <TextField
                    fullWidth
                    id="purchaseEmail" 
                    label="Purchase Email" 
                    variant="outlined"
                    className="TextField"
                    value={data.purchaseEmail}
                    onChange={(event, value) => onFieldChange(event, event.target.value, event.target.id)}
                    InputLabelProps={{
                        shrink: true,
                      }}
                />
            </div>
            <div>
                <TextField
                    fullWidth
                    id="salesPersonEmail" 
                    label="Kinetisense Sales Contact Email" 
                    variant="outlined"
                    className="TextField"
                    value={data.salesPersonEmail}
                    onChange={(event, value) => onFieldChange(event, event.target.value, event.target.id)}
                    InputLabelProps={{
                        shrink: true,
                      }}
                />
            </div>
            <div>
                <FormControl fullWidth className='TextField'>
                    <InputLabel id="Organization-label">Organization</InputLabel>
                    <Select
                        // labelId="Organization"
                        id="organizationId"
                        value={data.organizationId}
                        label="Organization"
                        onChange={(event, value) => onFieldChange(event, event.target.value, "organizationId")}
                        
                    >
                        <MenuItem value={""}>None</MenuItem>
                        {organizations.map(row => {return (<MenuItem value={row.id}>{row.name}</MenuItem>)} )}
                        
                    </Select>
                </FormControl>
            </div>
           <div className="horizontal">
                    <TextField
                        fullWidth
                        id="qty" 
                        label="Quantity" 
                        variant="outlined"
                        type="number"
                        className="TextField"
                        InputProps={{
                            inputProps: { 
                                max: {max}, min: {min} 
                            }
                        }}
                        value={qty}
                        onChange={(event) => {
                            var value = parseInt(event.target.value, 10);
                            if (value > max) value = max;
                            if (value < min) value = min;
                            onFieldChange(event, value, event.target.id
                            )}}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                    <TextField
                        fullWidth
                        id="amount" 
                        label="Seats" 
                        variant="outlined"
                        type="number"
                        className="TextField"
                        InputProps={{
                            inputProps: { 
                                max: {max}, min: {min} 
                            }
                        }}
                        value={data.amount}
                        onChange={(event) => {
                            var value = parseInt(event.target.value, 10);
                            if (value > max) value = max;
                            if (value < min) value = min;
                            onFieldChange(event, value, event.target.id
                            )}}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                    <TextField
                        fullWidth
                        id="maxNumberOfDevices" 
                        label="Max # of devices" 
                        variant="outlined"
                        type="number"
                        className="TextField"
                        InputProps={{
                            inputProps: { 
                                max: {max}, min: {min} 
                            }
                        }}
                        value={data.maxNumberOfDevices}
                        onChange={(event) => {
                            var value = parseInt(event.target.value, 10);
                            if (value > max) value = max;
                            if (value < min) value = min;
                            onFieldChange(event, value, event.target.id
                            )}}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    /> 
                    <div className='Checkbox-box'>
                        <span class="Checkbox-title">Test License</span>
                        <FormControlLabel 
                            id="isTestLicence"
                            value={data.isTestLicence}
                            control={<Checkbox 
                                style={{marginLeft:-100, marginTop: 10}}
                                checked={data.isTestLicence} 
                                color="primary"
                                id="isTestLicence"
                                onChange={(event) => {onFieldChange(event, event.target.checked, event.target.id)}}
                            />}
                            // label="Test Licence"
                            labelPlacement="top"
                            onChange={(event) => {onFieldChange(event, event.target.checked, event.target.id)}}
                        />
                    </div>
            </div>
            
        </div>
    );
    } else {
        return (<label>Loading...</label>)
    }
}

export default LicenceNewInformation;