
import "./Widget";

import { BarChart, CartesianGrid, XAxis, YAxis,Tooltip,Legend, Bar, ResponsiveContainer } from "recharts";
const Number = ({title, amount}) =>{

   
   

    return (
        <div className="widget">
          <div className="left">
            <span className="title">{title}</span>
            <span className="number">
              <center>{amount}</center> 
            </span>
            {/* <span className="link">{data.link}</span> */}
          </div>
          
        </div>
      );
};

export default Number;