import * as React from 'react';
import TextField from '@mui/material/TextField';
import  Button  from '@mui/material/Button';
import Autocomplete from '@mui/material/Autocomplete';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import Checkbox from '@mui/material/Checkbox';

import { ExerciseTextColumns } from '../../datatablesource';
import { useState } from 'react';
import ExerciseTextDataTable from '../datatable/ExerciseTextDataTable';
// import {languageList} from '../../data/ControlledLists';


const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

 const  ExerciseInformationNew = ({data, handleFieldUpdate, equipmentList, equipmentNeeded, languageList}) => {    

    //console.log("data:",data);

    const handleListUpdate= (event, newValue) => {
        handleFieldUpdate(event, newValue, "equipmentNeeded");
    }

    const handleTextUpdate = (event, newvalue) => {
        handleFieldUpdate(event, newvalue, "exerciseTexts");
    }


    if (data.market !== undefined) {
    return (
        <div>
             <div>
                <TextField
                    fullWidth
                    id="name" 
                    label="Name" 
                    variant="outlined"
                    className="TextField"
                    value={data.exerciseTexts[0].name}
                    onChange={(event, value) => handleFieldUpdate(event, event.target.value, event.target.id)}
                    InputLabelProps={{
                        shrink: true,
                      }}
                />
            </div>
            <div>
                <Autocomplete
                    className="combobox"
                    multiple
                    id={data.id}
                    options={equipmentList.map((item) => {return item.id})}
                    disableCloseOnSelect
                    getOptionLabel={(option) => option}
                    value = {equipmentNeeded}
                    renderOption={(props, option, { selected }) => (
                        <li {...props}>
                        <Checkbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 8 }}
                            checked={selected}
                        />
                        {option}
                        </li>
                    )}
                    style={{ width: 500 }}
                    renderInput={(params) => (
                        <TextField {...params} label={"Equipment"} placeholder={"Filter"}/>
                    )}
                    onChange = {(event,newValue) => handleListUpdate(event, newValue)}
                    onInputChange={(event,newValue) => handleListUpdate(event, newValue)}
                />
               
               
            </div>
            <div>
                <TextField
                    fullWidth
                    id="videoURL" 
                    label="videoURL" 
                    variant="outlined"
                    className="TextField"
                    value={data.videoURL}
                    onChange={(event, value) => handleFieldUpdate(event, event.target.value, event.target.id)}
                    InputLabelProps={{
                        shrink: true,
                      }}
                />
            </div>
            <div>
                <TextField
                    fullWidth
                    id="instructions" 
                    label="Instructions" 
                    variant="outlined"
                    className="TextField"
                    multiline = {true}
                    rows={5}
                    value={data.exerciseTexts[0].Instructions}
                    onChange={(event, value) => handleFieldUpdate(event, event.target.value, event.target.id)}
                    InputLabelProps={{
                        shrink: true,
                      }}
                />
            </div>
            <div>
                <TextField
                    fullWidth
                    id="market" 
                    label="market" 
                    variant="outlined"
                    className="TextField"
                    value={data.market}
                    onChange={(event, value) => handleFieldUpdate(event, event.target.value, event.target.id)}
                    InputLabelProps={{
                        shrink: true,
                      }}
                />
            </div>
            <div>
                <TextField
                    fullWidth
                    id="group" 
                    label="Group" 
                    variant="outlined"
                    className="TextField"
                    value={data.group}
                    onChange={(event, value) => handleFieldUpdate(event, event.target.value, event.target.id)}
                    InputLabelProps={{
                        shrink: true,
                      }}
                />
             </div>
             <div>
                <FormControl fullWidth className='TextField'>
                    <InputLabel id="Primary-Language-label">Primary Language</InputLabel>
                    <Select
                        labelId="primaryLanguage"
                        id="primaryLanguage"
                        value={data.primaryLanguage}
                        label="Primary Language"
                        // size="small"
                        onChange={(event, value) => handleFieldUpdate(event, event.target.value, "primaryLanguage")}
                    >
                        <MenuItem value={""}>""</MenuItem>
                        {languageList.map(row => {return (<MenuItem value={row.id}>{row.name}</MenuItem>)} )}
                        
                    </Select>
                </FormControl>
             </div>
            
          

        </div>
    );
    } else {
        return (<label>Loading...</label>)
    }
}

export default ExerciseInformationNew;