import "./Dialog.scss";

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import LicenceEdit from '../Licences/LicenceEdit';

const EditLicenceDialog = ({open, id, handleClose, handleUpdateRows}) => {

    return (

        <Dialog 
        PaperProps={{
          sx: {
            minHeight: 900
          }
        }}
        className="editdialog" 
        open={open} 
        fullWidth={true}
        maxWidth={"sm"}
        >
        <DialogTitle className="dialogtitle">
          <div className="left">
          Licence
          </div>
            <IconButton onClick={handleClose} className="right">
              <CloseIcon />
            </IconButton>
        </DialogTitle>
        <DialogContent  className="dialogcontent">
          <LicenceEdit id ={id} handleUpdateRows={handleUpdateRows} />
        </DialogContent>
      </Dialog>
    );

}

export default EditLicenceDialog;