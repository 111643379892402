import TextField from "@mui/material/TextField";
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Box from "@mui/material/Box";

const AccountInformation = ({data, onFieldChange}) => {

    // console.log("ID: ",data.id);
    console.log("Data: ",data);

   
    return (
        <div className="panel">
            <div>
                <TextField
                    fullWidth
                    required
                    id="username" 
                    label="Username" 
                    variant="outlined"
                    className="TextField"
                    value={data.username}
                    size="small"
                    onChange={(event, value) => onFieldChange(event, event.target.value, event.target.id)}
                    InputLabelProps={{
                        shrink: true,
                        }}
                />
            </div>
            <div>
                <TextField
                    fullWidth
                    required
                    id="email" 
                    label="Email Address" 
                    variant="outlined"
                    className="TextField"
                    type="email"
                    value={data.email}
                    size="small"
                    onChange={(event, value) => onFieldChange(event, event.target.value, event.target.id)}
                    InputLabelProps={{
                        shrink: true,
                        }}
                />
            </div>
            <div>
                <TextField
                    fullWidth
                    id="name" 
                    label="Name" 
                    variant="outlined"
                    className="TextField"
                    value={data.name}
                    size="small"
                    onChange={(event, value) => onFieldChange(event, event.target.value, event.target.id)}
                    InputLabelProps={{
                        shrink: true,
                        }}
                />
            </div>
            <div>
                <TextField
                    fullWidth
                    id="middleName" 
                    label="MiddleName" 
                    variant="outlined"
                    className="TextField"
                    value={data.middleName}
                    size="small"
                    onChange={(event, value) => onFieldChange(event, event.target.value, event.target.id)}
                    InputLabelProps={{
                        shrink: true,
                        }}
                />
            </div>
            <div>
                <TextField
                    fullWidth
                    id="surname" 
                    label="Surname" 
                    variant="outlined"
                    className="TextField"
                    value={data.surname}
                    size="small"
                    onChange={(event, value) => onFieldChange(event, event.target.value, event.target.id)}
                    InputLabelProps={{
                        shrink: true,
                        }}
                />
            </div>
            
            <div>
            <FormControl fullWidth>
                <InputLabel id="App-label">Application Theme</InputLabel>
                <Select
                    labelId="app"
                    id="demo-simple-select"
                    value={data.app}
                    label="Application Theme"
                    size="small"
                    onChange={(event, value) => onFieldChange(event, event.target.value, "app")}
                >
                    <MenuItem value={0}>Kinetisense</MenuItem>
                    <MenuItem value={1}>Parker</MenuItem>
                    <MenuItem value={2}>Metis</MenuItem>
                    <MenuItem value={3}>Fluid</MenuItem>
                    <MenuItem value={4}>KongIQ</MenuItem>
                    <MenuItem value={5}>Longitude6</MenuItem>
                    <MenuItem value={6}>UofA</MenuItem>

                </Select>
            </FormControl>
              
           </div>
            <FormGroup>
               <div id="FormGroup">
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={data.isAdministrator}
                            id="isAdministrator" 
                            inputProps={{ 'aria-label': 'IsAdministrator' }}
                            onChange={(event, value) => onFieldChange(event, event.target.checked, event.target.id)}
                            InputLabelProps={{
                                shrink: true,
                                }}
                        />
                    }
                    label="IsAdministrator"
                />
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={data.isOperator}
                            id="isOperator" 
                            onChange={(event, value) => onFieldChange(event, event.target.checked, event.target.id)}
                            inputProps={{ 'aria-label': 'IsOperator' }}
                            InputLabelProps={{
                                shrink: true,
                                }}
                        />
                    }
                    label="IsOperator"
                />
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={data.isActive}
                            id="isActive" 
                            onChange={(event, value) => onFieldChange(event, event.target.checked, event.target.id)}
                            inputProps={{ 'aria-label': 'IsActive' }}
                            InputLabelProps={{
                                shrink: true,
                                }}
                     />
                    }
                    label="IsActive"
                />
            </div>
           </FormGroup>
            
           
         </div>

        
    )
}

export default AccountInformation;
