export const FlexLicenceTemplateData = {
    "name": "",
    "modules": "",
    "features": "",
    "isDeleted": false,
    "errorCode": null,
    "errorMessage": null,
    "id": "",
    "version": "",
    "createdAt": "",
    "updatedAt": "",
    "deleted": false
}