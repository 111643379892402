import * as React from 'react';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import {OrganizationThemes} from '../../data/ControlledLists';


 const  OrganizationInformation = ({data, onFieldChange, organizations}) => {    //  const [row, setRow] = useState(LicenceData);

    console.log("------", organizations)
    if (data.name !== undefined) {
    return (
        <div >
            <div>
                <TextField
                    fullWidth
                    id="name" 
                    label="Organization Name" 
                    variant="outlined"
                    className="TextField"
                    value={data.name}
                    onChange={(event, value) => onFieldChange(event, event.target.value, event.target.id)}
                    InputLabelProps={{
                        shrink: true,
                      }}
                />
            </div>
            {/* <div>
                <TextField
                    fullWidth
                    id="organizationKey" 
                    label="Organization Key" 
                    variant="outlined"
                    className="TextField"
                    value={data.organizationKey}
                    onChange={(event, value) => onFieldChange(event, event.target.value, event.target.id)}
                    InputLabelProps={{
                        shrink: true,
                      }}
                />
            </div> */}
            <div>
                <FormControl fullWidth className='TextField'>
                    <InputLabel id="Theme-label">Theme</InputLabel>
                    <Select
                        id="theme"
                        value={data.theme}
                        label="Theme"
                        onChange={(event, value) => onFieldChange(event, event.target.value, "theme")}
                    >
                        {OrganizationThemes.map(row => {return (<MenuItem value={row}>{row}</MenuItem>)} )}
                        
                    </Select>
                </FormControl>
            </div>
            <div>
                <FormControl fullWidth className='TextField'>
                    <InputLabel id="Organization-label" notched={true} shrink={true} >Parent Organization</InputLabel>
                    <Select
                        notched={true}
                        labelId="Organization-label"
                        id="organizationId"
                        value={data.organizationId}
                        label="Parent Organization"
                        onChange={(event, value) => onFieldChange(event, event.target.value, "organizationId")}
                    >
                        {(organizations|| []).map(row => {return (<MenuItem value={row.id}>{row.name}</MenuItem>)} )}
                        
                    </Select>
                </FormControl>
            </div>
            <div>
                <TextField
                    fullWidth
                    id="notes" 
                    label="Notes" 
                    variant="outlined"
                    multiline
                    rows={5}
                    type="TextField"
                    className="TextField"
                    value={data.notes}
                    onChange={(event, value) => onFieldChange(event, event.target.value, event.target.id)}
                    InputLabelProps={{
                        shrink: true,
                      }}
                />
             </div>
           
        </div>
    );
    } else {
        return (<label>Loading...</label>)
    }
}

export default OrganizationInformation;