import './LicenceAccountTableView.scss';
import "./licenceDataTable.scss";
import React from "react";
import {useState, useEffect} from 'react';
import { useSession } from '../../context/SessionContext';
import LicenceData from "../../data/LicenceData";
import LicenceNewInformation from "../Licences/LicenceNewInformation";
import ListSelection from "../Licences/ListSelection";
import { modules, features} from "../../data/ControlledLists";
import {CSVString2Array, Array2CSVString, ServerURL} from "../../Utilities/Utilities";
import { LicenceColumns} from "../../datatablesource";
import EditLicenceDialog from '../dialogs/EditLicenceDialog';
import ViewLicenceDialog from '../dialogs/ViewLicencesDialog';
import DeleteLicenceDialog from '../dialogs/DeleteLicenceDialog';
import SuccessOkDialog from '../dialogs/SuccessOkDialog'
import WarningOkDialog from '../dialogs/WarningOkDialog'
import InfoOkDialog from '../dialogs/InfoOkDialog'
import ErrorOkDialog from '../dialogs/ErrorOkDialog'
import { DataGrid } from "@mui/x-data-grid";
import  Button  from '@mui/material/Button';
import Box from "@mui/material/Box";
import TabPanel from '@mui/lab/TabPanel';
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import TabContext from "@mui/lab/TabContext";
import { useAuthContext } from '../../context/AuthContext';
import { useLoginServer } from '../../context/LoginServerContext';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined'; 
import CustomFieldsMetadata from '../../data/CustomFieldsMetadata';


const alertStates = {"info":false,"warning":false,"error":false,"success":false};
const LicenceNew = (props) => {

    const [edit, setEdit] = useState(false);
    const [view, setView] = useState(false);
    const [del, setDelete] = useState(false);
    const [id, setId] = useState();
    const [value, setValue] = useState('1');
    const [row, setRow] = useState(LicenceData);
    const [rows, setRows] = useState([]);
    const [errorFlag, setErrorFlag] = useState(false);
    const [savedFlag, setSavedFlag] = useState(false);
    const [warningFlag, setWarningFlag] = useState(false);
    const [message, setMessage] = useState();
    const [qty, setQty] = useState(1);
    const [alertState, setAlertState] = useState(alertStates);
    const [organizations, setOrganizations] = useState([]);
    const session = useSession();
    const loginServerURL = useLoginServer();
    const {currentToken} = useAuthContext();
    const [CFFeatures, setCFFeatures] = useState([]);
    const [branding, setBranding] = useState([]);
    const [flexLicenceTemplates, setFlexLicenceTemplates] = useState([]);



     useEffect (() => {
        getBrandingFeatures();
        getAllFeatures();
        getFlexLicenceTemplates();
        var d = new Date();
        var year = d.getFullYear();
        var month = d.getMonth();
        var day = d.getDate();
        var newDate = new Date(Date.UTC(year + 1, month, day,0,0,0));
        setRow({...row, amount: 1, maxNumberOfDevices: 1 , expirationDate: newDate})
        getOrganizations();
     },[]);
  
     const getAllFeatures = () => {
        var rows = [CustomFieldsMetadata];
        let queryString = `?$filter=((id ne null) and (updatedAt ge 1970-01-01T20:00:00Z))&$orderby=updatedAt&$skip=0&top=1000`
        fetch(loginServerURL + "/tables/CustomFieldsMetaData" + queryString, {
            method: 'GET',
            headers:{
                "X-ZUMO-AUTH": currentToken,
                "Content-type":"application/json"
            }
        }).then ((response) => response.json())
        .then ((data) => {
            rows = data.filter((r) => r.isDeleted === false);
            var list = [];
            rows.forEach((p) => {
                list.push(p.featureName);
            });
            setCFFeatures(list);
            
        }).catch ((error) => {
            console.log("MD Error: ",error);
        });  
    }

    // get the branding features from the subscription server
    function getBrandingFeatures () {
        fetch(session.subscriptionServer + "/api/GetBrandingList" , {
            method: 'GET',
            headers:{
                "X-ZUMO-AUTH": currentToken,
                "Content-type":"application/json"
            }
        }).then ((response) => response.json())
        .then ((data) => {
            setBranding(data); 
        }).catch ((error) => {
            console.log("BF Error: ",error);
        });  
    }

    // get the flex licence templates from the subscription server
    function getFlexLicenceTemplates () {
        let queryString = `?$filter=((id ne null) and (updatedAt ge 1970-01-01T20:00:00Z))&$orderby=updatedAt&$skip=0&top=1000`
        console.log("queryData: ",queryString)
      try { 
        fetch(session.subscriptionServer + "/tables/FlexLicenceTemplate/" + queryString, {
            method: 'GET',
            headers:{"Content-type":"application/json"}
        }).then ((response) => response.json())
        .then ((data) => {
          setFlexLicenceTemplates(data);
          
        }).catch ((error) => {
            console.log(error);
        });  
      } catch(err) {
        console.log(err);
      }
    }

    // get the organizations from the regional server
     const getOrganizations = () => {
        try { 
            const sqlCommand = loginServerURL + "/api/Organizations/GetOrganizations";
            console.log(sqlCommand);
            fetch(sqlCommand, {
                method: 'GET',
                headers:{
                    "X-ZUMO-AUTH": currentToken,
                    "Content-type":"application/json"
                }
            }).then ((response) => {
              if(response.status>= 200 && response.status < 300){
                return response.json();
              } else {
                throw new Error(response.status);
              }
            })
            .then ((data) => {
                console.log("Organization fetched: ",data);
                setOrganizations(data)
            }).catch ((error) => {
                console.log(error);
            });  
        } catch(err) {
            console.log(err);
        }
    }



    const handleChange = (e, newValue) => {
        setValue(newValue);
      }
    
      const handleAdd = async (e) => {
        e.preventDefault();
        console.log("Save");
        if (validateInput()) {
            var newRow = row;
            newRow.purchaseDate = new Date().toUTCString();
            console.log("newrow:", newRow);
            try { 
                fetch(session.subscriptionServer + "/api/subscriptions/new?qty=" + qty, {
                    method: 'POST',
                    body: JSON.stringify(newRow),
                    headers:{"Content-type":"application/json"}
                }).then ((response) => response.json())
                .then ((data) => {
                    console.log("Updated: ",data);
                    setRows(data);
                    setSavedFlag(true);
                }).catch ((error) => {
                    console.log(error);
                    setErrorFlag(true);
                });  
            } catch(err) {
            console.log(err);
            }
        }

      };
    
      const handleFieldUpdate = (event, newValue, id) => {
        // console.log("Case: ",id,  newValue)
        setErrorFlag(false);
        setSavedFlag(false);
        setMessage("");
        
        var updatedDate = new Date();
        updatedDate = updatedDate.toUTCString();
        console.log("Updated Date: ", updatedDate);
        
        if (id ==='name') {
            console.log("NewValue: ", newValue);
            if (newValue === "kinetisense-flex") {
                setRow({...row, name: newValue, updatedAt: updatedDate })
            } else {
                setRow({...row, name: newValue, displayName: "", modules: "", features: "", updatedAt: updatedDate })
            }
        } else if (id ==='displayName') {
            flexLicenceTemplates.forEach((el) =>{
                console.log(el.name)
                if (el.name === newValue) {
                    setRow({...row, displayName:newValue, modules: el.modules, features: el.features, updatedAt: updatedDate });
                }
            })
        } else if (id ==='purchaseName') {
            setRow({...row, purchaseName: newValue, updatedAt: updatedDate })
        } else if (id ==='purchaseEmail') {
            setRow({...row, purchaseEmail: newValue, updatedAt: updatedDate })
        } else if (id ==='expirationDate') {
            setRow({...row, expirationDate: newValue, updatedAt: updatedDate })
        } else if (id ==='modules') {
            setRow({...row, modules: Array2CSVString(newValue), updatedAt: updatedDate  })
        } else if (id ==='features') {
            setRow({...row, features: Array2CSVString(newValue), updatedAt: updatedDate  })
        } else if (id ==='qty') {
            setQty(newValue);
        } else if (id ==='amount') {
            setRow({...row, amount: newValue, updatedAt: updatedDate })
        } else if (id ==='organizationId') {
            setRow({...row, organizationId: newValue, updatedAt: updatedDate })
        } else if (id ==='maxNumberOfDevices') {
            setRow({...row, maxNumberOfDevices: newValue, updatedAt: updatedDate })
        } else if (id ==='isTestLicence') {
            setRow({...row, isTestLicence: newValue, updatedAt: updatedDate })
        } else if (id ==='salesPersonEmail') {
            setRow({...row, salesPersonEmail: newValue, updatedAt: updatedDate })
        } else if (id ==='readOnlyDevices') {
            setRow({...row, readOnlyDevices: newValue, updatedAt: updatedDate })
        } else {
            console.log("Missing: ",id)
        }
      }

      const validateInput = () => {
        setErrorFlag(false);
        setSavedFlag(false);
        setWarningFlag(false);
        setMessage("");
        var flag = false;
        if (row.purchaseName === '' || row.purchaseName === null){
            setMessage('Enter a purchase Name');
            setWarningFlag(true);
            flag=true;
        } else if (row.purchaseEmail === '' || row.purchaseEmail === null) {
            setMessage('Enter a purchase Email');
            setWarningFlag(true);
            flag=true;
        } else if (qty < 1) {
            setMessage('Enter a Quantity');
            setWarningFlag(true);
            flag=true;
        }
        console.log("Warning Flag: ",warningFlag);
        return !flag;
      }

    

      const handleCloseEditDialog = () => {
        setEdit(false);
      }
      const handleCloseViewDialog = () => {
        setView(false);
      }
      const handleCloseDeleteDialog = () => {
        setDelete(false);
      }
    
      const handleEdit = async (id) =>{
        setEdit(true);
        setId(id);
        
      }
      const handleView = (id) => {
        setView(true);
        setId(id);
      }
    
      const handleDelete = (id) => {
        setDelete(true);
        setId(id);
      }
    
      const handleRemoveFromRows = (id) =>{
        const newList = rows.filter((r) => r.id !== id );
        setRows(newList);
      }
    
      const handleUpdateRows = (r) =>{
        console.log("handleUpdateRows: ", r)
          let i =0;
          let j = 0;
          rows.forEach((el) => {
              if (el.id === r.id) {
                  j=i;
              }
              i++;
          })
          const newList = [...rows];
          newList[j]=r;
          setRows(newList);
      }
    
      const actionColumn = [
        {
          field: "action",
          headerName: "Action",
          width: 120,
          renderCell: (params) => {
            return (
              <div className="cellAction">
                <RemoveRedEyeOutlinedIcon style={{color:"green", cursor: "pointer"}} onClick={() => handleView(params.row.id)}>View</RemoveRedEyeOutlinedIcon>
                  <ModeEditOutlineOutlinedIcon style={{color:"dodgerblue", cursor: "pointer"}} onClick={() => handleEdit(params.row.id)}>Edit</ModeEditOutlineOutlinedIcon>
                <DeleteOutlineOutlinedIcon style={{color:"red", cursor: "pointer"}} onClick={() => handleDelete(params.row.id)}>Delete</DeleteOutlineOutlinedIcon>
              </div>
            );
          },
        },
      ];

      const handleCloseDisplayMessage = () => {
        setAlertState({"info":false,"warning":false,"error":false,"success":false});
    }


    
    
    return (
        <>
        <InfoOkDialog open={alertState.info} message={message} handleClose={handleCloseDisplayMessage}/>
        <SuccessOkDialog open={alertState.success} message={message} handleClose={handleCloseDisplayMessage}/>
        <ErrorOkDialog open={alertState.error} message={message} handleClose={handleCloseDisplayMessage}/>
        <WarningOkDialog open={alertState.warning} message={message} handleClose={handleCloseDisplayMessage}/>
        <EditLicenceDialog id={id} handleClose = {handleCloseEditDialog} open={edit} handleUpdateRows={handleUpdateRows}/>
        <ViewLicenceDialog id={id} handleClose = {handleCloseViewDialog} open={view} />
        <DeleteLicenceDialog id={id} handleClose = {handleCloseDeleteDialog} open={del} handleDeleteRow={handleRemoveFromRows} />

           <div >
            <form onSubmit={handleAdd}>
                <div className="datatable">
                    <div className="datatableTitle">
                        Create Licences
                    </div>
                </div>
                <div className="formInput" style={{display: 'flex', flexDirection:'row', flexWrap:'wrap', }}>
                    <Box style={{width:600}}>
                        <TabContext value={value} >
                            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                                <Tab label="General Information" value="1" />
                                <Tab label="Modules" value="2" />
                                <Tab label="Features" value="3" />
                                </Tabs>
                            </Box>
                            <TabPanel value='1'>
                                <LicenceNewInformation 
                                data={row} 
                                title={"General Information"}
                                qty={qty}
                                onFieldChange={(event,value, id) => handleFieldUpdate(event, value, id)}
                                organizations={organizations}
                                flexLicenceTemplates={flexLicenceTemplates}
                                />
                            </TabPanel>
                                <TabPanel value='2'>
                                <ListSelection 
                                baseList={modules} 
                                selectedList={CSVString2Array(row.modules).sort((a,b) => a > b ? 1 : -1)} 
                                title={"Modules"} 
                                id = "modules" 
                                onSelectionChange={(event,value, id) => handleFieldUpdate(event, value, id)} 
                                />
                            </TabPanel>
                            <TabPanel value='3'>
                                <ListSelection 
                                baseList={CFFeatures.concat(features).concat(branding).sort((a, b) => (a > b ? 1 : -1))} 
                                selectedList={CSVString2Array(row.features).sort((a,b) => a > b ? 1 : -1)} 
                                title={"Features"} 
                                id="features" 
                                onSelectionChange={(event,value, id) => handleFieldUpdate(event, value, id)} 
                                />
                            </TabPanel> 
                        </TabContext>
                        {errorFlag && <div className="error">Error Occurred</div>}
                        {warningFlag && <div className="warning">{message}</div>}
                        {savedFlag && <div className="message">Licence Saved</div>}
                    </Box>
                    <div style={{marginTop:'200px'}} >
                        <Button  
                            type="submit"
                            variant="contained"
                            color='primary'
                            style={{width: 200, height: 40, marginLeft: 20, marginTop: 20}}
                            >
                            Generate Licences
                        </Button>
                    </div>
                </div>
            </form>
            <div className="datatable">
                    <div className="datatableTitle">
                    New Licences
                    </div>
                    <Box sx={{ height: 300, width: '100%' }}>
                        <DataGrid
                            className="datagrid"
                            rows={rows}
                            columns={LicenceColumns.concat(actionColumn)}
                            pageSize={5}
                            rowsPerPageOptions={[5]}
                            density='compact'
                        />
                    </Box>
                </div>
        </div> 
    </>   
    );
}

export default LicenceNew;