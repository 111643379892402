 const ExerciseData = {
    
        "equipmentNeeded": null,
        "videoURL": null,
        "market": "General",
        "group": "Core",
        "primaryLanguage": "EN-US",
        "clinicId": null,
        "isDeleted": false,
        "errorCode": null,
        "errorMessage": null,
        "exerciseTexts": [
            {
                "exerciseId": "",
                "languageCode": "EN-US",
                "name": "",
                "instructions": "",
                "isDeleted": false,
                "errorCode": null,
                "errorMessage": null,
                "id": "",
                "version": "",
                "createdAt": "",
                "updatedAt": "",
                "deleted": false
            }
        ],
        "id": "",
        "version": "",
        "createdAt": "",
        "updatedAt": "",
        "deleted": false
    }

    export default ExerciseData;