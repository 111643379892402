import React from "react";
import {useState, useEffect} from 'react';
import Box from "@mui/material/Box";
import  Button  from '@mui/material/Button';
import AccountInformation from "./AccountInformation";
import { PractitionerData } from "../../data/PractitionerData";
import { useLoginServer } from "../../context/LoginServerContext";
import uuid from "react-uuid";
import SuccessOkDialog from "../dialogs/SuccessOkDialog";


const alertStates = {"info":false,"warning":false,"error":false,"success":false};

const AccountNew = ({id, handleSave, handleClose}) => {

    const [row, setRow] = useState(PractitionerData);
    const [errorFlag, setErrorFlag] = useState(false);
    const [savedFlag, setSavedFlag] = useState(false);
    const [warningFlag, setWarningFlag] = useState(false);
    const [message, setMessage] = useState("");
    const [showDialog, setShowDialog] = useState({error:false, success:false, message:""});
    const loginServerURL = useLoginServer();

console.log("Account New Row: ", row);



    useEffect (() => {
        setRow({...row, clinicId:id})
    },[])
    
      const handleFieldUpdate = (event, newValue, id) => {
        //console.log("Case: ",id,  newValue)
        setErrorFlag(false);
        setSavedFlag(false);
        const updatedDate = Date.UTC;
        if (id ==='name') {
            setRow({...row, name: newValue })
        } else if (id ==='username') {
            setRow({...row, username: newValue, updatedAt: updatedDate });
        } else if (id ==='name') {
            setRow({...row, name: newValue, updatedAt: updatedDate });
        } else if (id ==='middleName') {
            setRow({...row, middleName: newValue, updatedAt: updatedDate });
        } else if (id ==='surname') {
            setRow({...row, surname: newValue, updatedAt: updatedDate });
        } else if (id ==='email') {
            setRow({...row, email: newValue, updatedAt: updatedDate })
        } else if (id ==='app') {
            setRow({...row, app: newValue, updatedAt: updatedDate })
        } else if (id ==='isAdministrator') {
            setRow({...row, isAdministrator: newValue, updatedAt: updatedDate })
        } else if (id ==='isOperator') {
            setRow({...row, isOperator: newValue, updatedAt: updatedDate })
        } else if (id ==='isActive') {
            setRow({...row, isActive: newValue, updatedAt: updatedDate })
        } else {
            console.log("Missing: ",id)
        }
      }
      
      const accountValidation= () => {
        setWarningFlag(false);
        setMessage("")
        var flag = false;
        if (row.username === "" || row.username === null ){
            setMessage("Enter Username");
            setWarningFlag(true);
        } else if (row.email === "" || row.email === null){
            setMessage("Enter Username");
            setWarningFlag(true);
        } if (row.email.includes('@')){
            setMessage("Invalid Email address");
            setWarningFlag(true);
        }
          
        return !flag;
      }
      const handleAdd = (event) =>{
        setErrorFlag(false);
        setSavedFlag(false);
        event.preventDefault();
        if (accountValidation()) {

            console.log("Save new account: ",row)

            const pswd = "zcvbm" + uuid().substring(0, 8) + "piyrw";


            const registrationRequest = {
                username: row.username,
                password: pswd,
                version: "",
                practitioner: row
            }

            try { 
                fetch(loginServerURL+"/api/KinetisenseRegistration", {
                    method: 'POST',
                    body: JSON.stringify(registrationRequest),
                    headers:{"Content-type":"application/json"
                    }
                })//.then ((response) => response.json())
                .then ((data) => {
                    console.log("Accounts: ",data);
                    if(data.status >= 200 && data.status < 300) {
                        setSavedFlag(true);
                        setShowDialog({...showDialog, success:true, message:"Your password is " + pswd  + ". Password copied to your clipboard."});
                        navigator.clipboard.writeText(pswd);
                        handleSave();
                    } else {

                    }
                }).catch ((error) => {
                    console.log(error);
                    setErrorFlag(true);
                });  
            } catch(err) {
                console.log(err);
                setErrorFlag(true);
            }

        }
    }

    const handleCloseDialogs = () => {
        setShowDialog({...showDialog, error:false, success:false, message:""})
    }


    return (
        <>
            <SuccessOkDialog open={showDialog.success} message={showDialog.message} handleClose={handleCloseDialogs}/>
          <form onSubmit={handleAdd}>
            <div className="formInput" style={{height:"500px"}}>
                <Box>
                    <AccountInformation 
                    data={row} 
                    title={"Account Information"} 
                    onFieldChange={(event,value, id) => handleFieldUpdate(event, value, id)}
                    />
                </Box>
                <div className="formButtons" >
                    <div className="left">
                        {errorFlag && <div className="error">An error occured</div>}
                        {savedFlag && <div className="message">Account Saved</div>}
                    </div>
                    <div className = "right">
                    <Button  
                            type="subuttonbmit"
                            variant="contained"
                            className="button"
                            onClick={handleClose}
                            >
                            Cancel
                        </Button>
                        <Button  
                            type="submit"
                            variant="contained"
                            className="button"
                            >
                            Save
                        </Button>
                    </div>
                </div>
            </div>
        </form>
    </>
            
    );
}

export default AccountNew;