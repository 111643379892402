import "./Dialog.scss";

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import FlexLicenceTemplateEdit from "../flexLicenceTemplate/FlexLicenceTemplateEdit";

const EditFlexLicenceTemplateDialog = ({open, id, handleClose, handleUpdateRows, list}) => {
   
    return (

        <Dialog 
        PaperProps={{
          sx: {
            minHeight: 750
          }
        }}
        className="editdialog" 
        open={open} 
        fullWidth={true}
        maxWidth={"sm"}
        >
        <DialogTitle className="dialogtitle">
          <div className="left">
          Flex Licence Template
          </div>
            <IconButton onClick={handleClose} className="right">
              <CloseIcon />
            </IconButton>
        </DialogTitle>
        <DialogContent  className="dialogcontent">
          <FlexLicenceTemplateEdit id ={id} handleUpdateRows={handleUpdateRows} handleClose={handleClose} list={list} />
        </DialogContent>
      </Dialog>
    );

}

export default EditFlexLicenceTemplateDialog;