import "./style/dark.scss";
import Home from "./pages/home/Home";
import Login from "./pages/login/Login";
import List from "./pages/list/List";
import Single from "./pages/single/Single";
import New from "./pages/new/New";
import LicenceList from "./pages/list/LicenceList";
import LicenceNew from "./pages/licence/LicenceNew";
import OrganizationList from "./pages/organization/OrganizationList";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { productInputs, userInputs } from "./formSource";
import { useContext } from "react";
import { DarkModeContext } from "./context/darkModeContext";
import { AuthContext } from "./context/AuthContext";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { LoginServerProvider} from './context/LoginServerContext';
import { SessionProvider } from "./context/SessionContext";
import S2SSyncList from "./pages/list/S2SSyncList";
import ExerciseList from "./pages/list/ExerciseList";
import EquipmentList from "./pages/list/EquipmentList";
import CorrectiveList from "./pages/list/CorrectiveList";
import CustomFieldsMetaDataList from "./pages/list/CustomFieldsMetaDataList";
import FlexLicenceTemplateList from "./pages/list/FlexLicenceTemplateList";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import AzureSAMLCallback from "./pages/login/AzureSAMLCallback";


const theme = createTheme({
  zIndex: { tooltip: 99999 },
});


function App() {
  const { darkMode } = useContext(DarkModeContext);
  const {currentToken} = useContext(AuthContext)

  const RequireAuth = ({ children }) => {
    console.log("current token", currentToken)
    return currentToken ? children : <Navigate to="/login" />;
  };

  return (
    <div className={darkMode ? "app dark" : "app"}>
      <ThemeProvider theme={theme}>
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'en'}>
         <LoginServerProvider>
            <SessionProvider>
                <BrowserRouter>
                  <Routes>
                      <Route exact path="/">
                        <Route index element={<RequireAuth> <Home /></RequireAuth>} />
                      </Route>
                      
                        <Route path="/login" element={<Login />} />
                        <Route path="/SAMLAuth" element={ <AzureSAMLCallback />} />
                        <Route path="/users">
                          <Route index element={<RequireAuth><List /></RequireAuth>} />
                          <Route path=":userId" element={<RequireAuth> <Single /> </RequireAuth>}/>
                          <Route path="new" element={<RequireAuth><New inputs={userInputs} title="Add New User" /></RequireAuth>} />
                        </Route>
                        <Route path="/organizations">
                          <Route index element={<RequireAuth> <OrganizationList /> </RequireAuth>}/>
                          <Route path=":productId" element={<RequireAuth><Single /></RequireAuth>}/>
                          <Route path="new" element={ <RequireAuth> <New inputs={productInputs} title="Add New Product" /></RequireAuth>} />
                        </Route>
                        <Route path="/licences">
                          <Route index element={<RequireAuth> <LicenceList /> </RequireAuth>}/>
                          <Route path="new" element={ <RequireAuth> <LicenceNew /></RequireAuth>} />
                        </Route>
                        <Route exact path="/licences/:serverURL/:queryData" element={<RequireAuth><LicenceList/></RequireAuth>}/>
                        <Route path="/systemHealth">
                          <Route index element={<RequireAuth> <LicenceList /> </RequireAuth>}/>
                          <Route path="new" element={ <RequireAuth> <LicenceNew /></RequireAuth>} />
                        </Route>
                        <Route path="/Correctives/correctiveList" element={<CorrectiveList />} />
                        <Route path="/Correctives/exerciseList" element={<ExerciseList />} />
                        <Route path="/Correctives/equipmentList" element={<EquipmentList />} />
                        <Route path="/utility/s2sSync" element={<S2SSyncList/>} />
                        <Route path="/customFields/MetaData" element={<CustomFieldsMetaDataList/>} />
                        <Route path="/FlexLicenceTemplate" element={<FlexLicenceTemplateList/>} />

                  </Routes>
                </BrowserRouter>
              </SessionProvider>
          </LoginServerProvider>
      </LocalizationProvider>
      </ThemeProvider>
    </div>
  );
}

export default App;
