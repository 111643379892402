import * as React from 'react';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import {Array2CSVString} from "../../Utilities/Utilities"
import { useState, useEffect } from 'react';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default function CheckboxesTags({selectedList, baseList, title, id, onSelectionChange}) {

  const [value, setValue] = useState(selectedList);

  useEffect (() => {
     setValue(selectedList);
  },[selectedList])

  //console.log("selected :", props.selectedList)

    const handleListUpdate= (event, newValue) => {
        console.log(Array2CSVString(newValue));
        setValue(newValue);
        onSelectionChange(event, newValue, id);
    }

    return (
      <>
    
          <Autocomplete
              className='combobox'
              //sx={{ m: 1, width: 500 }}
              id={id}
              multiple
              options={baseList}
              getOptionLabel={(option) => option}
              disableCloseOnSelect
              value = {value}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option}
                </li>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label={title}
                  placeholder="Filter"
                />
              )}
              onChange = {(event,newValue) => handleListUpdate(event, newValue)}
        />
    </>
  );
}
