import './LicenceAccountTableView.scss';
import "./licenceDataTable.scss";
import {useState, useEffect, useContext} from 'react';
import { DataGrid } from "@mui/x-data-grid";
import { AccountColumns } from "../../datatablesource";
import LicenceData from '../../data/LicenceData';
import LicenceDetail from "../Licences/LicenceDetail";
import { useLoginServer } from "../../context/LoginServerContext";
import {useAuthContext} from '../../context/AuthContext'; 
import Box from '@mui/material/Box';
import { useSession } from '../../context/SessionContext';
import EditPractitionerAccountDialog from '../dialogs/EditPractitionerAccountDialog';
import NewAccountDialog from '../dialogs/NewAccountDialog';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined'; 

const LicenceAccountTableView = (props) => {

    const [rows, setRows] = useState([]);
    const [row, setRow] = useState(LicenceData);
    const [edit, setEdit] = useState(false);
    const [id, setId] = useState();
    const[create, setCreate] = useState(false);
    const [displayNewButton, setDisplayNewButton] = useState(false);
    const [refreshList, setRefreshList] = useState(false);
    const [showDialog, setShowDialog] = useState({error:false, success:false, message:""});
    

    const loginServerURL = useLoginServer();
    const {currentToken} = useAuthContext();
    const session = useSession();
    // console.log("LoginServerURL: ",loginServerURL);
    // console.log("Row: ",row);
    // console.log("Props: ",props);
    useEffect (() => {
        //console.log("useEffect");
        try { 
            const query = session.subscriptionServer + "/tables/subscription/" + props.id;
        fetch(query, {
            method: 'GET',
            headers:{"Content-type":"application/json"}
        }).then ((response) => response.json())
        .then ((data) => {
            setRow(data.queryable[0]);
            console.log("Queryable: ",data.queryable[0]);
        }).catch ((error) => {
            console.log(error);
        });  
    } catch(err) {
        console.log(err);
    }
    },[props.id]);

    useEffect (() => {
        
    try { 
        fetch(loginServerURL+"/api/PractitionersForClinic?id="+row.clinicId, {
            method: 'GET',
            headers:{
                "X-ZUMO-AUTH": currentToken,
                "Content-type":"application/json"
            }
        }).then ((response) => response.json())
        .then ((data) => {
            console.log("Accounts: ",data);
            setRows(data);
        }).catch ((error) => {
            console.log(error);
        });  
    } catch(err) {
        console.log(err);
    }
    },[row, refreshList]);

    useEffect (() => {
        if (row.amount > rows.length ) {
            setDisplayNewButton(true);
        } else {
            setDisplayNewButton(false);
        }
    },[rows]);

   

    const handleUpdateRows = (r) =>{
        //console.log("handleUpdateRows: ", r)
          let i =0;
          let j = 0;
          rows.forEach((el) => {
              if (el.id === r.id) {
                  j=i;
              }
              i++;
          })
          const newList = [...rows];
          newList[j]=r;
          setRows(newList);
      }

      const actionColumn = [
        {
          field: "action",
          headerName: "Action",
          width: 75,
          renderCell: (params) => {
            return (
              <div className="cellAction">
                  <ModeEditOutlineOutlinedIcon style={{color:"DodgerBlue", cursor: "pointer"}} onClick={() => handleEdit(params.row.id)}>Edit</ModeEditOutlineOutlinedIcon>
              </div>
            );
          },
        },
      ];
    
    const handleCloseEditDialog = () => {
        setEdit(false);
    }


    const handleEdit = async (id) =>{
        setEdit(true);
        setId(id);
    }

    const handleCloseNewAccountDialog= () => {
        setCreate(false);
    }
    const handleOpenNewAccountDialog = () => {
        setCreate(true);
    }

    const handleCloseDialogs = () => {
        setShowDialog({...showDialog, error:false, success:false, message:""})
    }

    const handleSaveNewAccount = () =>{
        console.log("HandleSave")
        setRefreshList(!refreshList);
    }


    return (
        <>
            <EditPractitionerAccountDialog open={edit} handleClose={handleCloseEditDialog} id={id} />
            <NewAccountDialog open={create} handleClose={handleCloseNewAccountDialog} id={row.uniqueMatchingId} handleSave={handleSaveNewAccount} />
            <LicenceDetail 
                data = {row}
            />
            <div className="datatable">
                <div className="datatableTitle">
                Accounts Detail
                {displayNewButton && <AddCircleOutlineOutlinedIcon style={{color:"green", cursor: "pointer"}} onClick={handleOpenNewAccountDialog}>New Account</AddCircleOutlineOutlinedIcon>}
                </div>
                <Box sx={{ height: 300, width: '100%' }}>
                    <DataGrid
                        className="datagrid"
                        rows={rows}
                        columns={AccountColumns.concat(actionColumn)}
                        pageSize={5}
                        rowsPerPageOptions={[5]}
                        density='compact'
                    />
                </Box>
            </div>
        </>
    
    
    )
}

export default LicenceAccountTableView;