import "./datatable.scss";
import "./licenceDataTable.scss";

import { DataGrid } from "@mui/x-data-grid";
import { SyncConfigurationColumns } from "../../datatablesource";
import { useEffect, useState } from "react";
import { useSession } from "../../context/SessionContext";
import { RotatingLines } from "react-loader-spinner";
import { useLoginServer } from "../../context/LoginServerContext";
import { useAuthContext } from "../../context/AuthContext";
import  Button  from '@mui/material/Button';
import { Search } from "@mui/icons-material";


const S2SSyncDataTable = (props) => {

    const [rows, setRows] = useState([]);
    const [id, setId] = useState();
    const session = useSession();
    const [isLoading, setLoading] = useState(false);
    const [refresh, setRefresh] = useState(false)

    const loginServerURL = useLoginServer();
    const {currentToken} = useAuthContext();
    
    // console.log("ROWS: ", rows)
    // console.log("Id: ",id);
    // console.log("Search Flag: ", search)
    useEffect (() => {
        try { 
            const sqlCommand = loginServerURL + "/api/SyncConfiguration";
            console.log("sqlCommand",sqlCommand);
            fetch(sqlCommand, {
                method: 'GET',
                headers:{
                    "X-ZUMO-AUTH": currentToken,
                    "Content-type":"application/json"
                }
            }).then ((response) => {
              if(response.status>= 200 && response.status < 300){
                return response.json();
              } else {
                throw new Error(response.status);
              }
            })
            .then ((data) => {
                console.log("SyncConfiguration: ",data);
                setRows(data);
            }).catch ((error) => {
                console.log(error);
            });  
        } catch(err) {
            console.log(err);
        }
    },[refresh]);

   const RunSync = () => {
        try { 
            const sqlCommand = loginServerURL + "/api/ServerToServerSync";
            console.log(sqlCommand);
            fetch(sqlCommand, {
                method: 'POST',
                headers:{
                    "X-ZUMO-AUTH": currentToken,
                    "Content-type":"application/json"
                }
            }).then ((response) => {
              if(response.status>= 200 && response.status < 300){
                return response;
              } else {
                throw new Error(response.status);
              }
            })
            .then ((data) => {
                //console.log("Server2ServerUpdate: ",data);
                setRefresh(!refresh);
                setLoading(false);
            }).catch ((error) => {
                console.log(error);
            });  
        } catch(err) {
            console.log(err);
        }
   }
    const handleOnSubmit = (event) => {
      event.preventDefault()
      setRows([]);
      setLoading(true);
      RunSync();
      

    }

  return (
    <>
        <form onSubmit={handleOnSubmit} >
     
                { isLoading &&
                <center style={{paddingTop:100}}>
                    <RotatingLines  strokeColor="grey"  strokeWidth="2"  animationDuration="1.5"  width="150"  visible={isLoading} />
                </center>
                }
                {!isLoading &&
                    <div className="datatable" style={{height:400}}>
                    <div className="datatableTitle">
                    Sync Configurations
                    
                    </div>
                    <DataGrid
                        className="datagrid"
                        rows={rows}
                        columns={SyncConfigurationColumns}
                        pageSize={15}
                        rowsPerPageOptions={[15]}
                        //checkboxSelection
                        density='compact'
                    />
                    </div>
                }
                {!isLoading &&
                    <div style={{marginTop:'50px', marginLeft:'50px'}} >
                        <Button  
                            type="submit"
                            variant="contained"
                            >
                            Sync Master Tables to Regional Servers
                        </Button>
                    </div>
                }
        </form>
    </>
  );
};




export default S2SSyncDataTable;
