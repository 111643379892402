
const OrganizationData = {
    "name": "",
    "notes": "",
    "organizationKey":"",
    "theme":"",
    "id": "",
    "version": null,
    "createdAt": "",
    "updatedAt": "",
    "isDeleted": false,
    "deleted": false,
    "organizationId": null,
    "clinics":[],
    "organizationUser": [],
    "ssoProvider":"None",
    "ssoProviderSettingsBlob":JSON.stringify("")
}

export default OrganizationData;