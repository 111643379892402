import "./Dialog.scss";
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import AccountEdit from '../Practitioners/AccountEdit';
const EditPractitionerAccountDialog = ({open, handleClose, id}) => {

    return (
        <Dialog 
            PaperProps={{
            sx: {
                minHeight: 600
            }
            }}
            className="editdialog" 
            open={open} 
            // onClose={handleCloseEditDialog}
            fullWidth={true}
            maxWidth={"sm"}
        >
        <DialogTitle className="dialogtitle">
        <div className="left">
            Account Information
        </div>
            <IconButton onClick={handleClose} className="right">
            <CloseIcon />
            </IconButton>
        </DialogTitle>
        <DialogContent  className="dialogcontent">
            <AccountEdit id ={id}/>
        </DialogContent>
    </Dialog>
    );
}

export default EditPractitionerAccountDialog;