import React from "react";
import {useState, useEffect} from 'react';
import Box from "@mui/material/Box";
import  Button  from '@mui/material/Button';
import { PractitionerData } from "../../data/PractitionerData";
import { useLoginServer } from "../../context/LoginServerContext";
import uuid from "react-uuid";
import SuccessOkDialog from "../dialogs/SuccessOkDialog";
import OrganizationInformation from "./OrganizationInformation";
import { useAuthContext } from "../../context/AuthContext";
import OrganizationData from "../../data/OrganizationData";


const alertStates = {"info":false,"warning":false,"error":false,"success":false};

const OrganizationNew = ({id, handleClose, organizations}) => {

    const [row, setRow] = useState(OrganizationData);
    const [errorFlag, setErrorFlag] = useState(false);
    const [savedFlag, setSavedFlag] = useState(false);
    const [warningFlag, setWarningFlag] = useState(false);
    const [message, setMessage] = useState("");
    const [showDialog, setShowDialog] = useState({error:false, success:false, message:""});
    const loginServerURL = useLoginServer();
    const {currentToken} = useAuthContext();

    //console.log("Organization: ", row);

      const handleFieldUpdate = (event, newValue, id) => {
        console.log("Case: ",id,  newValue)
        setErrorFlag(false);
        setSavedFlag(false);
        const updatedDate = Date.UTC;
        if (id ==='name') {
            setRow({...row, name: newValue })
        } else if (id ==='organizationKey') {
            setRow({...row, organizationKey: newValue, updatedAt: updatedDate })
        } else if (id ==='organizationId') {
            setRow({...row, organizationId: newValue, updatedAt: updatedDate })
        } else if (id ==='theme') {
            setRow({...row, theme: newValue, updatedAt: updatedDate })
        } else if (id ==='notes') {
            setRow({...row, notes: newValue, updatedAt: updatedDate })
        }  else {
            console.log("Missing: ",id)
        }
      }
      
      const validation= () => {
        setWarningFlag(false);
        setMessage("")
        var flag = false;
        if (row.name === "" || row.username === null ){
            setMessage("Enter Organization Name");
            setWarningFlag(true);
        } else if (row.theme === "" || row.email === null){
            setMessage("Select a Theme");
            setWarningFlag(true);
        } 
          
        return !flag;
      }

      const handleAdd = (event) =>{
        setErrorFlag(false);
        setSavedFlag(false);
        event.preventDefault();
        if (validation()) {

            console.log("Save new Organization: ",row)
           
            try { 
                fetch(loginServerURL + "/tables/organization", {
                    method: 'POST',
                    body: JSON.stringify(row),
                    headers:{
                      "X-ZUMO-AUTH": currentToken,
                      "Content-type":"application/json"
                  }
                }).then ((data) => {
                    console.log("Organization: ",data);
                    if(data.status >= 200 && data.status < 300) {
                        setSavedFlag(true);
                        setErrorFlag(false);
                        // handleAddRow(row);
                        handleClose();
                    } else {
                        setErrorFlag(true);
                        setSavedFlag(false);
                    }
                }).catch ((error) => {
                    console.log(error);
                    setErrorFlag(true);
                    setSavedFlag(false);
                });  
            } catch(err) {
                console.log(err);
                setErrorFlag(true);
            }

        }
    }

    const handleCloseDialogs = () => {
        setShowDialog({...showDialog, error:false, success:false, message:""})
    }


    return (
        <>
            <SuccessOkDialog open={showDialog.success} message={showDialog.message} handleClose={handleCloseDialogs}/>
          <form onSubmit={handleAdd}>
            <div className="formInput" >
                <Box>
                    <OrganizationInformation 
                    data={row} 
                    title={"Organization Information"} 
                    onFieldChange={(event,value, id) => handleFieldUpdate(event, value, id)}
                    organizations={organizations}
                    />
                </Box>
                <div className="formButtons" >
                    <div className="left">
                        {errorFlag && <div className="error">An error occured</div>}
                        {savedFlag && <div className="message">Account Saved</div>}
                        {warningFlag && <div className="warning">{message}</div>}
                    </div>
                    <div className = "right">
                    <Button  
                            type="button"
                            variant="contained"
                            className="button"
                            onClick={handleClose}
                            >
                            Cancel
                        </Button>
                        <Button  
                            type="submit"
                            variant="contained"
                            className="button"
                            >
                            Save
                        </Button>
                    </div>
                </div>
            </div>
        </form>
    </>
            
    );
}

export default OrganizationNew;