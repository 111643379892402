import React from "react";
import {useState, useEffect} from 'react';
import Box from "@mui/material/Box";
import  Button  from '@mui/material/Button';
import { useLoginServer } from "../../context/LoginServerContext";
import { useAuthContext } from "../../context/AuthContext";
import {CSVString2Array} from "../../Utilities/Utilities";
import { Array2CSVString } from "../../Utilities/Utilities";
import CorrectiveInformation from "../correctives/CorrectiveInformation";
import { CorrectiveData } from "../../data/CorrectiveData";
import ExerciseTextData from "../../data/ExerciseTextData";
import  ExerciseData  from "../../data/ExerciseData";
import { set } from "date-fns";
import uuid from "react-uuid";


const CorrectiveEdit = ({id, handleClose, handleUpdateRows, list}) => {

    const [row, setRow] = useState(CorrectiveData);
    const [exercise, setExercise] = useState(ExerciseData);
    const[exerciseText, setExerciseText] = useState(ExerciseTextData);
    const [errorMessage, setErrorMessage] = useState(false);
    const [savedMessage, setSavedMessage] = useState(false);
    const loginServerURL = useLoginServer();
    const {currentToken} = useAuthContext();
    const [warningFlag, setWarningFlag] = useState(false);
    const [message, setMessage] = useState("");
    const [equipmentList, setEquipmentList] = useState([]);

    // console.log("Corrective Row: ",row);
    // console.log("Corrective Id: ",id);
    // console.log("Exercise: ",exercise);
    // console.log("ExerciseText: ",exerciseText);
    console.log("---- Rows: ",list);
    
    useEffect (() => {
           console.log("Corrective Edit: ",id);
            loadEquipmentList();
            if (id !=="new") {
                try { 
                    const sqlCommand = loginServerURL + "/tables/corrective/"+id;
                    console.log(sqlCommand);
                    fetch(sqlCommand, {
                        method: 'GET',
                        headers:{
                            "X-ZUMO-AUTH": currentToken,
                            "Content-type":"application/json"
                        }
                    }).then ((response) => {
                    if(response.status>= 200 && response.status < 300){
                        return response.json();
                    } else {
                        throw new Error(response.status);
                    }
                    })
                    .then ((data) => {
                        console.log("Corrective fetched: ",data.queryable[0]);
                        const iCorrective = data.queryable[0];
                        setRow(iCorrective);
                        const iExercise =iCorrective.exercise;
                        setExercise(iExercise);
                        const iExerciseText = iExercise.exerciseTexts.filter((row) => row.languageCode === iExercise.primaryLanguage);
                        setExerciseText(iExerciseText[0]);

                    }).catch ((error) => {
                        console.log(error);
                    });  
                } catch(err) {
                    console.log(err);
                }
            }
    },[]);

   
    const loadEquipmentList = () => {
        console.log("Reading equipment list");
        try { 
            const sqlCommand = loginServerURL + "/tables/equipment";
            console.log(sqlCommand);
            fetch(sqlCommand, {
                method: 'GET',
                headers:{
                    "X-ZUMO-AUTH": currentToken,
                    "Content-type":"application/json"
                }
            }).then ((response) => {
              if(response.status>= 200 && response.status < 300){
                return response.json();
              } else {
                console.log("Response.status",response.status)
                throw new Error(response.status);

              }
            })
            .then ((data) => {
                console.log("Equipment fetched: ",data);
                setEquipmentList(data)
            }).catch ((error) => {
                console.log(error);
            });  
        } catch(err) {
            console.log(err);
        }
    }
    
      const handleUpdate = async (e) => {
        e.preventDefault();
        console.log("Save");
        var nrow = null;
        var nexercise = null;
        var nexerciseText = null;
        if (id !=="new") {
            if (isValid()){
                try{
                    UpdateExercise("PATCH");
                    UpdateExerciseText("PATCH");
                    UpdateCorrective("PATCH");
                    setSavedMessage(true);
                    nrow = row;
                    nexercise = exercise;
                    nexerciseText = exerciseText;
                    nexercise.exerciseTexts = [nexerciseText];
                    nrow.exercise = nexercise;
                      handleUpdateRows(nrow);
                } catch (err) {
                    console.log(err);
                }
            }
            else {
                setWarningFlag(true);
            }
        }
        else{
            if (isValid()){
                try{
                    //UpdateExercise("POST");
                    UpdateCorrective("POST");
                    setSavedMessage(true);
                    nrow = row;
                    nexercise = exercise;
                    nexerciseText = exerciseText;
                    nexercise.exerciseTexts = [nexerciseText];
                    nrow.exercise = nexercise;
                    handleUpdateRows(nrow);
                    handleClose();
                } catch (err) {
                    console.log(err);
                }
            } else {
                setWarningFlag(true);
            }

        }
       
      };

      const isValid = () => {
        var flag = false;
        // should include something that checks if the name is empty
        if (exerciseText.name !== null && exerciseText.name !== undefined && exerciseText.name !== "") {    
            flag = true;
        } else {
            setMessage("Name is required");
            setWarningFlag(true);
            return false;
        }
        if (row.level !== null && row.level !== undefined && row.level !== "") {    
            flag = true;
        } else {
            setMessage("Level is required");
            setWarningFlag(true);
            return false;
        }
        if (row.joint !== null && row.joint !== undefined && row.joint !== "") {    
            flag = true;
        } else {
            setMessage("Joint is required");
            setWarningFlag(true);
            return false;
        }
        if (exercise.market !== null && exercise.market !== undefined && exercise.market !== "") {    
            flag = true;
        } else {
            setMessage("Market is required");
            setWarningFlag(true);
            return false;
        }
        if (exercise.group !== null && exercise.group !== undefined && exercise.group !== "") {    
            flag = true;
        } else {
            setMessage("Group is required");
            setWarningFlag(true);
            return false;
        }
        if (exercise.primaryLanguage !== null && exercise.primaryLanguage !== undefined && exercise.primaryLanguage !== "") {    
            flag = true;
        } else {
            setMessage("Primary Language is required");
            setWarningFlag(true);
            return false;
        }
        if (exercise.videoURL !== null && exercise.videoURL !== undefined && exercise.videoURL !== "") {    
            flag = true;
        } else {
            setMessage("Video URL is required");
            setWarningFlag(true);
            return false;
        }
        if (exerciseText.instructions !== null && exerciseText.instructions !== undefined && exerciseText.instructions !== "") {    
            flag = true;
        } else {
            setMessage("Instructions is required");
            setWarningFlag(true);
            return false;
        }
        // if (id === "new"){
        //     //check server if exercise exists
        //     if (exercise.id !== null && exercise.id !== undefined && exercise.id !== "") {

        //     }
        // }
        return flag;
      }

      const findNumberOfCorrectivesForExercise = () => {
        var count = 0;
        list.forEach((row) => {
            if (row.exercise.name === exercise.name && row.exercise.group === exercise.group && row.exercise.market === exercise.market) {
                count++;
            }
        });
        return count;
        }

      const UpdateCorrective = async (method) =>{
        var newRow = row;
        var url = "";
        newRow.exercise = null;
        if (method === "POST") {
            url = loginServerURL + "/tables/Corrective";
            newRow.id = uuid().replaceAll("-","");
            if (newRow.exerciseId === null) {
                newRow.exerciseId = exercise.id;
                var nexercise = exercise;
                nexercise.id = uuid().replaceAll("-","");
                var nexerciseText = exerciseText;
                nexerciseText.id = uuid().replaceAll("-","");
                nexerciseText.exerciseId = nexercise.id;
                nexercise.exerciseTexts = [nexerciseText];
                newRow.exercise = nexercise;
            } else {
                // don't include the rest since it already exists
            }
        } else {
            url = loginServerURL + "/tables/Corrective/" + newRow.id;
        }
        console.log("CORR URL: ",method, url, newRow);
         try { 
          fetch(url, {
              method: method,
              body: JSON.stringify(newRow),
              headers:{
                "X-ZUMO-AUTH": currentToken,
                "Content-type":"application/json"
            }
          }).then ((response) => {
            if (response.status>=200 && response.status <300) {
            return response.json();
            } else {
                throw new Error(response.status)
            }
          })
          .then ((data) => {
              setRow(data);
            //   handleUpdateRows(data);
              console.log("Updated: ",data);
          }).catch ((err) => {
              console.log(err);
              setErrorMessage(true);
              throw err;
          });  
        } catch(err) {
          console.log(err);
          throw err;
        }
      }

      const UpdateExerciseText = async (method) =>{
        var newRow = exerciseText;
        var url = "";
        if (method === "POST") {
            url = loginServerURL + "/tables/exerciseText";
            newRow.id = uuid().replaceAll("-","");
            newRow.primaryLanguage = exercise.primaryLanguage;
            newRow.exerciseId = exercise.id;
        } else {
            url = loginServerURL + "/tables/exerciseText/" + newRow.id;
        }
        console.log("EXTXT URL: ",method, url, newRow);
         try { 
          fetch(url, {
              method: method,
              body: JSON.stringify(newRow),
              headers:{
                "X-ZUMO-AUTH": currentToken,
                "Content-type":"application/json"
            }
          }).then ((response) => {
            if (response.status>=200 && response.status <300) {
            return response.json();
            } else {
                throw new Error(response.status)
            }
          })
          .then ((data) => {
              setExerciseText(data);
              console.log("Updated ExerciseText: ",data);
          }).catch ((err) => {
              console.log(err);
              setErrorMessage(true);
              throw err;
          });  
        } catch(err) {
          console.log(err);
          throw err;
        }
      }

      const UpdateExercise = async (method) =>{
        var newRow = exercise;
        newRow.exerciseTexts = [];
        var url = "";
        if (method === "POST") {
            url = loginServerURL + "/tables/exercise";
            newRow.id = uuid().replaceAll("-","");
            var newLanguageInfo = exerciseText;
            newLanguageInfo.id = uuid().replaceAll("-","");
            newLanguageInfo.exerciseId = newRow.id;
            newLanguageInfo.languageCode = newRow.primaryLanguage;
            newRow.exerciseTexts = [newLanguageInfo];
        } else {
            url = loginServerURL + "/tables/exercise/" + newRow.id;
        }
        console.log("EX URL: ",method, url, newRow);
         try { 
          fetch(url, {
              method: method,
              body: JSON.stringify(newRow),
              headers:{
                "X-ZUMO-AUTH": currentToken,
                "Content-type":"application/json"
              }
          }).then ((response) => {
            if (response.status>=200 && response.status <300) {
            return response.json();
            } else {
                throw new Error(response.status)
            }
          })
          .then ((data) => {
              setExercise(data);
              console.log("Updated Exercise: ",data);
          }).catch ((err) => {
              console.log(err);
              setErrorMessage(true);
              throw err;
          });  
        } catch(err) {
          console.log(err);
          throw err;
        }
      }

      
    
      const handleFieldUpdate = (event, newValue, id) => {
        console.log("Case: ",id,  newValue)
        setErrorMessage(false);
        setSavedMessage(false);
        const updatedDate = Date.UTC;
        if (id ==='level') {
            setRow({...row, level: newValue })
        } else if (id ==='joint') {
            setRow({...row, joint:newValue})
        } else if (id ==='isMobility') {
            setRow({...row, isMobility:newValue})
        } else if (id ==='isStability') {
            setRow({...row, isStability:newValue})
        } else if (id ==='equipmentNeeded') {
            setExercise({...exercise, equipmentNeeded: Array2CSVString(newValue), updatedAt: updatedDate })
        }else if (id ==='videoURL') {
            setExercise({...exercise, videoURL: newValue, updatedAt: updatedDate })
        }else if (id ==='primaryLanguage') {
            setExercise({...exercise, primaryLanguage: newValue, updatedAt: updatedDate })
            setExerciseText({...exerciseText, languageCode: newValue, updatedAt: updatedDate })
        } else if (id ==='market') {
            setExercise({...exercise, market: newValue, updatedAt: updatedDate })
        } else if (id ==='group') {
            setExercise({...exercise, group: newValue, updatedAt: updatedDate })
        } else if (id ==='primaryLanguage') {
            setExercise({...exercise, primaryLanguage: newValue, updatedAt: updatedDate })
        } else if (id ==='name') {
            setExerciseText({...exerciseText, name: newValue, updatedAt: updatedDate })
        } else if (id ==='instructions') {
            setExerciseText({...exerciseText, instructions: newValue, updatedAt: updatedDate })
        } else if (id ==='exercise') {
            if (newValue !== null && newValue !== undefined) {
                setRow({...row, exerciseId: newValue.exercise.id, updatedAt: updatedDate })
                setExercise(newValue.exercise);
                setExerciseText(newValue.exercise.exerciseTexts.filter((row) => row.languageCode === newValue.exercise.primaryLanguage)[0]);
            }
        }  else {
            console.log("Missing: ",id)
        }
      }

    return (
        
         <form > 
            {/* </form>onSubmit={handleAdd}> */}
            <div className="formInput"  style={{margin:"10px"}}>
                <Box>
                    <CorrectiveInformation 
                        data={row} 
                        title={"Corrective Information"} 
                        handleClose={handleClose}
                        handleFieldUpdate={(event, newValue, id) => handleFieldUpdate(event, newValue, id)}
                        exercise={exercise}
                        exerciseText={exerciseText}
                        equipmentList ={equipmentList} 
                        equipmentNeeded={CSVString2Array((exercise.equipmentNeeded || ""))}
                        id = {id}
                        list={list}
                    />
                </Box>
                <div className="formButtons" >
                    <div className="left">
                        {errorMessage && <div className="error">An error occurred</div>}
                        {savedMessage && <div className="message">Corrective Saved</div>}
                        {warningFlag && <div className="warning">{message}</div>}
                    </div>
                    <div className="right">
                        <Button  
                            type="submit"
                            variant="contained"
                            className="button"
                            onClick={handleUpdate}
                        >
                            Save
                        </Button>
                    </div>
                </div>
            </div>
        </form>
            
    );
}

export default CorrectiveEdit;