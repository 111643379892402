import "./datatable.scss";
import "./licenceDataTable.scss";

import { DataGrid } from "@mui/x-data-grid";
import { Box } from "@mui/material";
import { ExerciseTextColumns } from "../../datatablesource";
import { useEffect, useState } from "react";
import EditExerciseTextDialog from "../dialogs/EditExerciseTextDialog";
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import NewExerciseTextDialog from "../dialogs/NewExerciseTextDialog";
import PlayCircleFilledWhiteOutlinedIcon from '@mui/icons-material/PlayCircleFilledWhiteOutlined';



const ExerciseTextDataTable = ({data, languageList, handleTextUpdate, exerciseId}) => {

    const [rows, setRows] = useState(data);
    const [id, setId] = useState();
    const [edit, setEdit] = useState(false);
    const [add, setAdd] = useState(false);
    const [languages, setLanguages] = useState([]);

  console.log("ExerciseTextDataTable: ", data);
  // console.log("ExerciseTextDataTable exerciseid: ", exerciseId);
  // console.log("Languages: ", languages );
  // console.log("LanguageList: ", languageList );

  useEffect (() => {
    setRows(data);
    
    setLanguages(getUnusedLanguages());

  },[data]);

    const handleCloseEditDialog = () => {
        setEdit(false);
    }
 
    const handleEdit = async (id) =>{
        setEdit(true);
        setId(id);
    }
  
    const actionColumn = [
  {
      field: "action",
      headerName: "Action",
      width: 200,
      renderCell: (params) => {
        return (
          <div className="cellAction">
            
              <ModeEditOutlineOutlinedIcon style={{color:"dodgerblue", cursor: "pointer"}} onClick={() => handleEdit(params.row.id)}>Edit</ModeEditOutlineOutlinedIcon>
          </div>
        );
      },
    },
  ];

 
  const handleUpdateRows = (r) =>{
    console.log("handleUpdateRows: ", r)
      let i =0;
      let j = 0;
      rows.forEach((el) => {
        console.log("EL",el);
          if (el.id === r.id) {
              j=i;
              console.log("J",j);
          }
          i++;
      })
      const newList = [...rows];
      newList[j]=r;
      setRows(newList);
      handleTextUpdate(newList);

      // need to update the data as well
  }

  const handleCloseNewDialog = () => {
    setAdd(false);
    }

    const handleOpenNewTextDialog = () => {
        setAdd(true);
    }

    const handleAddNewRow = (row) =>{
        console.log("handleAddNewRow: ", row)
        console.log("handleAddNewRows: ", rows)
        const newList = [...data, row];
        setRows(newList);
        handleTextUpdate(newList);

    }

    const getUnusedLanguages = () => {
      var l = data.map((item) => item.languageCode);
      const newList = languageList.filter((item) =>  !l.includes(item.id));
      return newList;
    }

    const handlePlayVideo = () => {
      console.log("Play Video");
      var url = "https://res.cloudinary.com/dkfzgerhr/video/upload/ac_none,c_fill,f_mp4,h_1000,q_auto,vc_h264:baseline,w_1000/Correctives/"+data.group+"/"+data.videoURL;
      window.open(url, '_Video',"resizable=no, toolbar=no, scrollbars=no, menubar=no, status=no, directories=no, width=400, height=400");
  }

  return (
    <>
      <EditExerciseTextDialog open={edit} id={id} handleClose = {handleCloseEditDialog} handleUpdateRows={handleUpdateRows} languageList={languageList}/>
     <NewExerciseTextDialog open={add} id={'new'} handleClose = {handleCloseNewDialog} handleUpdateRows={handleAddNewRow} languageList={getUnusedLanguages()} exerciseId={exerciseId}/> 
   
      <div className="datatable">
                <div className="datatableTitle">
                    Linguistic Information
                    <AddCircleOutlineOutlinedIcon style={{color:"green", cursor: "pointer"}} onClick={handleOpenNewTextDialog}>Add Language</AddCircleOutlineOutlinedIcon>
                </div>
                <Box sx={{ height: 220, width: '100%' }}>
                    <DataGrid
                        className="datagrid"
                        rows={data}
                        columns={ExerciseTextColumns.concat(actionColumn)}
                        pageSize={3}
                        rowsPerPageOptions={[3]}
                        density='compact'
                    />
                </Box>
        </div>
    </>
  );
};

export default ExerciseTextDataTable;
