import "./licencelist.scss"
import Sidebar from "../../components/sidebar/Sidebar"
import Navbar from "../../components/navbar/Navbar"
import { useParams } from "react-router-dom";
import CorrectiveDataTable from "../../components/datatable/CorrectiveDataTable";
const CorrectivesList = (props) => {
  const {serverURL, queryData} = useParams();
   
  return (
    <div className="home">
      <Sidebar/>
      <div className="homeContainer">
        <Navbar/>
        <CorrectiveDataTable />
      </div>
    </div>
  )
}

export default CorrectivesList