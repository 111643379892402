import React, { useEffect } from 'react';

const AzureSAMLCallback = (req) => {
    useEffect(() => {
        const url = new URL(window.location.href);
        const samlResponse = url.searchParams.get('SAMLResponse');
        console.log('SAML Response:', samlResponse);
    }, []);

    return (
        <div>
            <h1>SAML Callback</h1>
            <p>Processing SAML response...</p>
        </div>
    );
};

export default AzureSAMLCallback;