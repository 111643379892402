//import "./licencelist.scss"
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import OrganizationDataTable from "../../components/datatable/OrganizationDataTable";
const OrganizationList = (props) => {
  return (
    <div className="home">
      <Sidebar/>
      <div className="homeContainer">
        <Navbar/>
        <OrganizationDataTable  />
      </div>
    </div>
  )
}

export default OrganizationList