import { useState } from "react";
import Button from "@mui/material/Button";
import ErrorOkDialog from "../dialogs/ErrorOkDialog";

const CustomFieldDelete = ({row, handleClose, handleDeleteRow}) => {

    const [displayError, setDisplayError] = useState(false);
    const [message, setMessage] = useState();

     const handleDelete = () => {
        setDisplayError(false);
        handleClose();
        handleDeleteRow(row);
     }

     const handleErrorDialogClose = () =>{
        setDisplayError(false)
        handleClose();
     }

    return (
    <>
        <ErrorOkDialog open={displayError} message={message} handleClose={handleErrorDialogClose} />
        <div>
            <div className="forminput">
                <strong>Do you want to delete the Custom Fields : {row.name}?</strong>
            </div>
            <div className="formButtons" >
                <div className="right">
                <Button onClick={handleDelete}>Yes</Button>
                <Button onClick={handleClose}>No</Button>
                </div>
            </div>
        </div>
    </>
    )
}

export default CustomFieldDelete;