const GoogleOAuthData = {
    "name": "Kinetisense 360",
    "clientId": "",
    "clientSecret": "",
    "authorizedOrigin1":"http://localhost",
    "authorizedOrigin2":"",
    "redirect1":"http://localhost",
    "redirect2":"{RegionalServerURL}/app/callback",
    "tokenCallback":"{RegionalServerURL}google/TokenCallback",
    "googleLoginEndpoint":"https://accounts.google.com/o/oauth2/v2/auth",
    "googleAPIEndpoint":"https://oauth2.googleapis.com"
}

export default GoogleOAuthData;