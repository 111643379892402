import { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import OrganizationData from "../../data/OrganizationData";
import ErrorOkDialog from "../dialogs/ErrorOkDialog";
import { useLoginServer } from "../../context/LoginServerContext";
import { useAuthContext } from "../../context/AuthContext";

const CustomFieldsMetadataDelete = ({id, handleClose, handleDeleteRow}) => {

    const [row,setRow] = useState(OrganizationData);
    const [displayError, setDisplayError] = useState(false);
    const [message, setMessage] = useState();
    const loginServerURL = useLoginServer();
    const {currentToken} = useAuthContext();

        console.log("Delete Organization row: ", row);
   useEffect (() => {
        
    try { 
        const sqlCommand = loginServerURL + "/tables/CustomFieldsMetaData/"+id;
        console.log(sqlCommand);
        fetch(sqlCommand, {
            method: 'GET',
            headers:{
                "X-ZUMO-AUTH": currentToken,
                "Content-type":"application/json"
            }
        }).then ((response) => {
          if(response.status>= 200 && response.status < 300){
            return response.json();
          } else {
            throw new Error(response.status);
          }
        })
        .then ((data) => {
            console.log("Custom FIelds Metadata fetched: ",data.queryable[0]);
            setRow(data.queryable[0])
        }).catch ((error) => {
            console.log(error);
        });  
    } catch(err) {
        console.log(err);
    }
     },[]);

     const handleDelete = () => {
        console.log("Delete CustomField Metadata");
        try { 
            fetch(loginServerURL + "/tables/CustomFieldsMetaData/" + row.id, {
                method: 'DELETE',
                headers:{
                  "X-ZUMO-AUTH": currentToken,
                  "Content-type":"application/json"
              }})
            .then ((data) => {
                handleDeleteRow(id);
                handleClose();
            }).catch ((error) => {
                console.log(error);
                setMessage(error.message);
                setDisplayError(true);
            });  
          } catch(err) {
            console.log(err);
          }
     }

     const handleErrorDialogClose = () =>{
        setDisplayError(false)
     }
    return (
    <>
        <ErrorOkDialog open={displayError} message={message} handleClose={handleErrorDialogClose} />
        <div>
            <div className="forminput">
                <strong>Do you want to delete the Custom Fields Feature: {row.featureName}?</strong>
            </div>
            <div className="formButtons" >
                <div className="right">
                <Button onClick={handleDelete}>Yes</Button>
                <Button onClick={handleClose}>No</Button>
                </div>
            </div>
        </div>
    </>
    )
}

export default CustomFieldsMetadataDelete;