import "./Dialog.scss";

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import CorrectiveEdit from "../correctives/CorrectiveEdit";

const NewCorrectiveDialog = ({open, handleClose, handleUpdateRows, id, list}) => {
    return (
        <Dialog 
        PaperProps={{
          sx: {
            minHeight: 920
          }
        }}
        className="editdialog" 
        open={open} 
        fullWidth={true}
        maxWidth={"sm"}
        >
        <DialogTitle className="dialogtitle">
          <div className="left">
          Corrective
          </div>
            <IconButton onClick={handleClose} className="right">
              <CloseIcon />
            </IconButton>
        </DialogTitle>
        <DialogContent  className="dialogcontent" >
            <div style={{padding:"10px"}}>
          <CorrectiveEdit handleClose={handleClose} handleUpdateRows={handleUpdateRows} id={id} list={list}/>
          </div>
        </DialogContent>
      </Dialog>
    );

}

export default NewCorrectiveDialog;