import "./datatable.scss";
import "./licenceDataTable.scss";

import { DataGrid } from "@mui/x-data-grid";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { RotatingLines } from "react-loader-spinner";
import { useLoginServer } from "../../context/LoginServerContext";
import { useAuthContext } from "../../context/AuthContext";
import { CustomFieldsMetaDataListColumns } from "../../datatablesource";
import CustomFieldsMetadataDialog from "../dialogs/CustomFieldsMetadataDialog"
import CustomFieldsMetadata from "../../data/CustomFieldsMetadata";
import DeleteCustomFieldsMetadataDialog from "../dialogs/DeleteCustomFieldsMetadataDialog";
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
const CustomFieldsMetaListDataTable = (props) => {

    const [rows, setRows] = useState([]);
    const [id, setId] = useState();
    const [edit, setEdit] = useState(false);
    const [view, setView] = useState(false);
    const [del, setDelete] = useState(false);
    const [add,setAdd] = useState(false);
    const [searchString, setSearchString] = useState("");
    const [skip, setSkip] = useState(0);
    const [isLoading, setLoading] = useState(true);
    const[refresh, setRefresh] = useState(false);

    const loginServerURL = useLoginServer();
    const {currentToken} = useAuthContext();
    
    //console.log("ROWS: ", rows)
    // console.log("Id: ",id);
    // console.log("Search Flag: ", search)
    useEffect (() => {
            let queryString = `?$filter=((id ne null) and (updatedAt ge 1970-01-01T20:00:00Z))&$orderby=updatedAt&$skip=${skip}&top=1000`
            console.log("queryData: ",queryString)
          try { 
            fetch(loginServerURL + "/tables/CustomFieldsMetaData" + queryString, {
                method: 'GET',
                headers:{
                    "X-ZUMO-AUTH": currentToken,
                    "Content-type":"application/json"
                }
            }).then ((response) => response.json())
            .then ((data) => {
              console.log("Metadata data: ", data)
              setRows(rows.concat(data.filter((r) => r.isDeleted === false)));
              console.log("concat")
              setSkip(skip + data.length);
              console.log("SKIP");
              if (data.length === 0) {
                console.log("DATA>LENGTH")
                setLoading(false);
              }
            }).catch ((error) => {
                console.log(error);
            });  
          } catch(err) {
            console.log(err);
          }
    },[refresh, skip]);

   
    const handleOnSubmit = (event) => {
      event.preventDefault()
      setRows([]);
      setSkip(0);
      setRefresh(!refresh);
      setLoading(true);
    }

  const handleCloseEditDialog = () => {
    setEdit(false);
  }
  const handleCloseViewDialog = () => {
    setView(false);
  }
  const handleCloseDeleteDialog = () => {
    setDelete(false);
  }

  const handleEdit = async (id) =>{
    setEdit(true);
    setId(id);
    
  }
  

  const handleDelete = (id) => {
    setDelete(true);
    setId(id);
  }

  const actionColumn = [
    {
      field: "action",
      headerName: "Action",
      width: 100,
      renderCell: (params) => {
        return (
          <div className="cellAction">
            {/* <div className="viewButton" onClick={() => handleView(params.row.id)}>View</div> */}
              <ModeEditOutlineOutlinedIcon style={{color:"DodgerBlue", cursor: "pointer"}} onClick={() => handleEdit(params.row.id)}>Edit</ModeEditOutlineOutlinedIcon>
            <DeleteOutlineOutlinedIcon style={{color:"red", cursor: "pointer"}} onClick={() => handleDelete(params.row.id)}>Delete</DeleteOutlineOutlinedIcon>
          </div>
        );
      },
    },
  ];

  const handleRemoveFromRows = (id) =>{
    const newList = rows.filter((r) => r.id !== id );
    setRows(newList);
  }

  const handleUpdateRows = (r) =>{
    console.log("handleUpdateRows: ", r)
      let i =0;
      let j = 0;
      rows.forEach((el) => {
          if (el.id === r.id) {
              j=i;
          }
          i++;
      })
      const newList = [...rows];
      newList[j]=r;
      setRows(newList);
  }

    const filterRows = (rows, sString) => {
        if (sString === '') return rows;
        if (sString === null) return rows;
        if (sString === undefined) return rows;
        if(rows.length === 0) return rows;

        // you need a decoder ring to get the following right (filter a list of objects using the inner objects)
        var list = []; 
        rows.forEach((r) => {
            r.exercise.exerciseTexts.forEach((t) => {
                if ( t.languageCode === r.exercise.primaryLanguage && t.name.toLowerCase().includes(sString.toLowerCase())) {
                    list.push(r);
                }
            });
        });
        return list;
    }

    const handleAddMetadata = () => {
      setAdd(true);
    
    }

    const handleCloseAddDialog = () => {
      setAdd(false);
    }

    const handleSave = (r) =>{
     setRefresh(!refresh);
    }


  return (
    <>
      <CustomFieldsMetadataDialog handleClose = {handleCloseEditDialog} open={edit} handleSave={handleSave} id={id}/>
      <DeleteCustomFieldsMetadataDialog id={id} handleClose = {handleCloseDeleteDialog} open={del} handleDeleteRow={handleRemoveFromRows} />
      <CustomFieldsMetadataDialog handleClose = {handleCloseAddDialog} open={add} handleSave={handleSave} id={"new"} />
      
      
    { isLoading &&
      <center style={{paddingTop:100}}>
          <RotatingLines  strokeColor="grey"  strokeWidth="2"  animationDuration="1.5"  width="150"  visible={isLoading} />
      </center>
    }
    {!isLoading &&
        <div className="datatable" style={{height:660}}>
          <div className="datatableTitle">
          Custom Fields Feature
            {/* <Link to="" onClick={handleAddMetadata} className="link">
              New Metadata
            </Link> */}
            <AddCircleOutlineOutlinedIcon style={{color:"green", cursor: "pointer" }} onClick={handleAddMetadata}>Add</AddCircleOutlineOutlinedIcon>
          </div>
            <DataGrid
                className="datagrid"
                rows={rows.map((r) => r.isDeleted === false ? r : null).filter((r) => r !== null)}
                columns={CustomFieldsMetaDataListColumns.concat(actionColumn)}
                pageSize={15}
                rowsPerPageOptions={[15]}
                density='compact'
            />
        </div>
    }
    </>
  );
};




export default CustomFieldsMetaListDataTable;
