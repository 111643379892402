import * as React from 'react';
import TextField from '@mui/material/TextField';
import  Button  from '@mui/material/Button';
import Autocomplete from '@mui/material/Autocomplete';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import Checkbox from '@mui/material/Checkbox';

import { ExerciseTextColumns } from '../../datatablesource';
import { useState } from 'react';
import ExerciseTextDataTable from '../datatable/ExerciseTextDataTable';
import { languageList } from '../../data/ControlledLists';
import PlayCircleFilledWhiteOutlinedIcon from '@mui/icons-material/PlayCircleFilledWhiteOutlined';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

 const  ExerciseInformation = ({data, handleFieldUpdate, equipmentList, equipmentNeeded}) => {    

    console.log("data:",data);
    console.log("languageList:",languageList);

    const handleListUpdate= (event, newValue) => {
        handleFieldUpdate(event, newValue, "equipmentNeeded");
    }

    const handleTextUpdate = (newvalue) => {
        console.log("Text update");
        handleFieldUpdate(null, newvalue, "exerciseTexts");
    }

    const handlePlayVideo = () => {
        console.log("Play Video");
        var url = "https://res.cloudinary.com/dkfzgerhr/video/upload/ac_none,c_fill,f_mp4,h_1000,q_auto,vc_h264:baseline,w_1000/Correctives/"+ data.group +"/"+data.videoURL;
        window.open(url, '_Video', "resizable=no, toolbar=no, scrollbars=no, menubar=no, status=no, directories=no, width=400, height=400");
    }


    if (data.market !== undefined) {
    return (
        <>
        <div>
            <div>
                <Autocomplete
                    className="combobox"
                    multiple
                    id={data.id}
                    options={equipmentList.map((item) => {return item.id})}
                    disableCloseOnSelect
                    getOptionLabel={(option) => option}
                    value = {equipmentNeeded}
                    renderOption={(props, option, { selected }) => (
                        <li {...props}>
                        <Checkbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 8 }}
                            checked={selected}
                        />
                        {option}
                        </li>
                    )}
                    fullWidth
                    renderInput={(params) => (
                        <TextField {...params} label={"Equipment"} />
                    )}
                    onChange = {(event,newValue) => handleListUpdate(event, newValue)}
                    onInputChange={(event,newValue) => handleListUpdate(event, newValue)}
                />
               
               
            </div>
            <div>
                <TextField
                    style={{width:"90%"}}
                    id="videoURL" 
                    label="videoURL" 
                    variant="outlined"
                    className="TextField"
                    value={data.videoURL}
                    onChange={(event, value) => handleFieldUpdate(event, event.target.value, event.target.id)}
                    InputLabelProps={{
                        shrink: true,
                      }}
                />
                <PlayCircleFilledWhiteOutlinedIcon style={{marginTop:"15px", marginLeft:"10px", color:"green", cursor: "pointer"}}  onClick={() => handlePlayVideo()}/>
            </div>
            </div>
            <div>
                <TextField
                    fullWidth
                    id="market" 
                    label="market" 
                    variant="outlined"
                    className="TextField"
                    value={data.market}
                    onChange={(event, value) => handleFieldUpdate(event, event.target.value, event.target.id)}
                    InputLabelProps={{
                        shrink: true,
                      }}
                />
            </div>
            <div>
                <TextField
                    fullWidth
                    id="group" 
                    label="Group" 
                    variant="outlined"
                    className="TextField"
                    value={data.group}
                    onChange={(event, value) => handleFieldUpdate(event, event.target.value, event.target.id)}
                    InputLabelProps={{
                        shrink: true,
                      }}
                />
             </div>
             <div>
                <FormControl fullWidth className='TextField'>
                    <InputLabel id="Primary-Language-label">Primary Language</InputLabel>
                    <Select
                        labelId="primaryLanguage"
                        id="primaryLanguage"
                        value={data.primaryLanguage}
                        label="Primary Language"
                        // size="small"
                        onChange={(event, value) => handleFieldUpdate(event, event.target.value, "primaryLanguage")}
                    >
                        <MenuItem value={""}>""</MenuItem>
                        {languageList.map(row => {return (<MenuItem value={row.id}>{row.name}</MenuItem>)} )}
                        
                    </Select>
                </FormControl>
             </div>
        
             <ExerciseTextDataTable data={data.exerciseTexts} title={"Linguistic Information"} handleTextUpdate={handleTextUpdate} languageList={languageList} exerciseId={data.id} />

        </>
    );
    } else {
        return (<label>Loading...</label>)
    }
}

export default ExerciseInformation;