import * as React from 'react';
import TextField from '@mui/material/TextField';
import  Button  from '@mui/material/Button';
import Autocomplete from '@mui/material/Autocomplete';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import Checkbox from '@mui/material/Checkbox';

import { ExerciseTextColumns } from '../../datatablesource';
import { useState } from 'react';
import ExerciseTextDataTable from '../datatable/ExerciseTextDataTable';


const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;


 const  ExerciseTextInformation = ({data, handleFieldUpdate, languageList}) => {    

    console.log("data:",data);

    if (data.instructions !== undefined) {
    return (
        <div>
            <div>
                <FormControl fullWidth className='TextField'>
                    <InputLabel id="Primary-Language-label">LanguageCode</InputLabel>
                    <Select
                        labelId="LanguageCode"
                        id="languageCode"
                        value={data.languageCode}
                        label="Language Code"
                        size="small"
                        onChange={(event, value) => handleFieldUpdate(event, event.target.value, "languageCode")}
                    >
                        <MenuItem value={""}>""</MenuItem>
                        {languageList.map(row => {return (<MenuItem value={row.id}>{row.name}</MenuItem>)} )}
                        
                    </Select>
                </FormControl>
            </div>
           
            <div>
                <TextField
                    fullWidth
                    id="name" 
                    label="Name" 
                    variant="outlined"
                    className="TextField"
                    value={data.name}
                    onChange={(event, value) => handleFieldUpdate(event, event.target.value, event.target.id)}
                    InputLabelProps={{
                        shrink: true,
                      }}
                />
            </div>
            <div>
                <TextField
                    fullWidth
                    id="instructions" 
                    label="Instructions" 
                    variant="outlined"
                    className="TextField"
                    multiline
                    rows={10}
                    value={data.instructions}
                    onChange={(event, value) => handleFieldUpdate(event, event.target.value, event.target.id)}
                    InputLabelProps={{
                        shrink: true,
                      }}
                />
            </div>

        </div>
    );
    } else {
        return (<label>Loading...</label>)
    }
}

export default ExerciseTextInformation;