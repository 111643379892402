import "./licencelist.scss"
import Sidebar from "../../components/sidebar/Sidebar"
import Navbar from "../../components/navbar/Navbar"
import { useParams } from "react-router-dom";
import FlexLicenceTemplateDataTable from "../../components/datatable/FlexLicenceTemplateDataTable";

const FlexLicenceTemplateList = (props) => {
   
  return (
    <div className="home">
      <Sidebar/>
      <div className="homeContainer">
        <Navbar/>
        <FlexLicenceTemplateDataTable />
      </div>
    </div>
  )
}

export default FlexLicenceTemplateList