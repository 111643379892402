import React from "react";
import {useState, useEffect} from 'react';
import { Box } from "@mui/material";
import  Button  from '@mui/material/Button';
import AccountInformation from "./AccountInformation";
import { PractitionerData } from "../../data/PractitionerData";
import { useLoginServer } from "../../context/LoginServerContext";
import { useAuthContext } from "../../context/AuthContext";
import SuccessOkDialog from '../../components/dialogs/SuccessOkDialog'
import WarningOkDialog from '../../components/dialogs/WarningOkDialog'
import InfoOkDialog from '../../components/dialogs/InfoOkDialog'
import ErrorOkDialog from '../../components/dialogs/ErrorOkDialog'
import WarningYesNoDialog from '../dialogs/WarningYesNoDialog'


const alertStates = {"info":false,"warning":false,"error":false,"success":false};

const AccountEdit = (props) => {

    const loginServerURL = useLoginServer();
    const [row, setRow] = useState(PractitionerData);
    const [errorMessage, setErrorMessage] = useState(false);
    const [savedMessage, setSavedMessage] = useState(false);
    const {currentToken} = useAuthContext();
    const [message, setMessage] = useState("");
    const [displayResetPassword, setDisplayResetPassword] = useState(false);
    const[alertState, setAlertState] = useState(alertStates);
    const [account, setAccount] = useState();
    const [usernameUpdatedFlag, setUsernameUpdatedFlag] = useState(false);

    //console.log("Main Row: ",row);
    useEffect (() => {
        
      try { 
        fetch(loginServerURL+"/tables/practitioner/"+ props.id, {
            method: 'GET',
            headers:{
                "X-ZUMO-AUTH": currentToken,
                "Content-type":"application/json"
            }
        }).then ((response) => response.json())
          .then ((data) => {
                setRow(data.queryable[0]);

                // Get the account information for the username
                fetch(loginServerURL+"/api/Accounts/username?username="+ data.queryable[0].username, {
                    method: 'GET',
                    headers:{
                        "X-ZUMO-AUTH": currentToken,
                        "Content-type":"application/json"
                    }
                }).then ((response) => response.json())
                .then ((account) => {
                    setAccount(account);
                    console.log("Account: ",account);
                }).catch ((error) => {
                    console.log(error);
                });  



        }).catch ((error) => {
            console.log(error);
        });  
      } catch(err) {
        console.log(err);
      }
    },[]);
   
    const handleEdit = async (e) => {
        e.preventDefault();
        console.log("Save");
        console.log("Record to save: ",row);
        console.log("Account flag: ", usernameUpdatedFlag)
        try { 
            fetch(loginServerURL+"/api/practitioner?id="+row.id, {
                method: 'PATCH',
                body: JSON.stringify(row),
                headers:{
                    "X-ZUMO-AUTH": currentToken,
                    "Content-type":"application/json"
                }
            }).then ((response) => {
                if (!response.ok) {
                    throw new Error (response.status)
                } else if (response.status <200 || response.status > 300) {
                    throw new Error(response.status)
                } else {
                    return response.json()}
                }
            ).then ((data) => {
                    console.log("Practitioner: ",data)
                 if(data.errorMessage === null) {
                     console.log("------------------> 1");
                    setSavedMessage(true);
                    setErrorMessage(false);
                    // change the username in the account table that contains the password
                    if (usernameUpdatedFlag) {
                        fetch(loginServerURL+"/api/Accounts/username?id="+account.id, {
                            method: 'PATCH',
                            body: JSON.stringify(account),
                            headers:{
                                "X-ZUMO-AUTH": currentToken,
                                "Content-type":"application/json"
                            }
                        }).then ((response) => {
                            if (!response.ok) {
                                throw new Error (response.status)
                            } else if (response.status <200 || response.status > 300) {
                                throw new Error(response.status)
                            } else {
                                return response.json()}
                            }
                        ).then ((data) => {
                              console.log("------------------> 2");
                            //console.log(data.status);
                            console.log("Account:",data);

                            // if(data.errorMessage === null) {
                                console.log("------------------> 3");
                                setSavedMessage(true);
                                setErrorMessage(false);
                                setUsernameUpdatedFlag(false);
                            // } else {
                            //     console.log("------------------> 4");
                            //     setSavedMessage(false);
                            //     setErrorMessage(true);
                            // }
                        }).catch ((error) => {
                            console.log("save account: ",error);
                            setErrorMessage(true);
                            setSavedMessage(false);
                        });  
                        
                    }
                } else {
                    setErrorMessage(true);
                    setSavedMessage(false);
                    console.log("------------------> 5");
                    console.log(data.errorMessage);
                }
                
            }).catch ((error) => {
                console.log(error);
                setErrorMessage(true);
                setSavedMessage(false);
                console.log("------------------> 6");
            });  
          } catch(err) {
            console.log(err);
          }

      };
    
      const handleFieldUpdate = (event, newValue, id) => {
        //console.log("Case: ",id,  newValue)
        setErrorMessage(false);
        setSavedMessage(false);
        const updatedDate = Date.UTC;
        if (id ==='name') {
            setRow({...row, name: newValue })
        } else if (id ==='middleName') {
            setRow({...row, middleName: newValue, updatedAt: updatedDate })
        } else if (id ==='surname') {
            setRow({...row, surname: newValue, updatedAt: updatedDate })
        } else if (id ==='username') {
            setRow({...row, username: newValue, updatedAt: updatedDate });
            setAccount({...account, username: newValue, updatedAt: updatedDate });
            setUsernameUpdatedFlag(true);
        } else if (id ==='email') {
            setRow({...row, email: newValue, updatedAt: updatedDate })
        } else if (id ==='app') {
            setRow({...row, app: newValue, updatedAt: updatedDate })
        } else if (id ==='isAdministrator') {
            setRow({...row, isAdministrator: newValue, updatedAt: updatedDate })
        } else if (id ==='isOperator') {
            setRow({...row, isOperator: newValue, updatedAt: updatedDate })
        } else if (id ==='isActive') {
            setRow({...row, isActive: newValue, updatedAt: updatedDate })
        } else {
            console.log("Missing: ",id)
        }
      }

    const handleDisplayResetPassword = () => {
            setDisplayResetPassword(true);
            setMessage("Do you want to send an Email to the user to reset his password?");
    }
    
    const handleCloseResetPassword = () => {
        setDisplayResetPassword(false)
    }
    
    const handleResetPassword = () => {
        setDisplayResetPassword(false);
        try { 
            fetch(loginServerURL+'/api/KinetisenseForgetPassword?username='+row.username, {
                method: 'POST',
                headers:{
                    "X-ZUMO-AUTH": currentToken,
                    "Content-type":"application/json"
                }
            }).then ((data) => {
                if (data.status >= 200 && data.status < 300) {
                    setAlertState({"info":false,"warning":false,"error":false,"success":true});
                    setMessage("The Email is on its way");
                } else {
                    setAlertState({"info":false,"warning":false,"error":true,"success":false});
                    setMessage("Could not send the email. Something went wrong!");
                }
                console.log("message: ",data);
            }).catch ((error) => {
                console.log(error);
                setMessage(error.message);
                setAlertState({"info":false,"warning":false,"error":true,"success":false});
            });  
          } catch(err) {
            console.log(err);
          }
    }

    const handleCloseDisplayMessage = () => {
        setAlertState({"info":false,"warning":false,"error":false,"success":false});
    }


    return (
        <>
            <InfoOkDialog open={alertState.info} message={message} handleClose={handleCloseDisplayMessage}/>
            <SuccessOkDialog open={alertState.success} message={message} handleClose={handleCloseDisplayMessage}/>
            <ErrorOkDialog open={alertState.error} message={message} handleClose={handleCloseDisplayMessage}/>
            <WarningOkDialog open={alertState.warning} message={message} handleClose={handleCloseDisplayMessage}/>
            <WarningYesNoDialog open={displayResetPassword} message={message} handleNo={handleCloseResetPassword} handleYes={handleResetPassword} />

          <form onSubmit={handleEdit}>
                <div className="formInput" style={{height:"500px"}}>
                    <Box>
                        <AccountInformation 
                        data={row} 
                        title={"Account Information"} 
                        onFieldChange={(event,value, id) => handleFieldUpdate(event, value, id)}
                        />
                    </Box>
                    <div className="formButtons" >
                        <div className="left">
                            {errorMessage && <div className="error">An error occured</div>}
                            {savedMessage && <div className="message">Account Saved</div>}
                        </div>
                        <div className = "right">
                            <Button  
                                variant="contained"
                                className="button"
                                onClick={handleDisplayResetPassword}
                                >
                                Reset Password
                            </Button>
                            <Button  
                                type="submit"
                                variant="contained"
                                className="button"
                                >
                                Save
                            </Button>
                        </div>
                    </div>
                </div>
          </form>
        </>
            
    );
}

export default AccountEdit;