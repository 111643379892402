import "./sidebar.scss";
import DashboardIcon from "@mui/icons-material/Dashboard";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import SettingsApplicationsIcon from "@mui/icons-material/SettingsApplications";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import SettingsSystemDaydreamOutlinedIcon from "@mui/icons-material/SettingsSystemDaydreamOutlined";
import PsychologyOutlinedIcon from "@mui/icons-material/PsychologyOutlined";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import DashboardCustomizeOutlinedIcon from '@mui/icons-material/DashboardCustomizeOutlined';
import SyncAltOutlinedIcon from '@mui/icons-material/SyncAltOutlined';
import FitnessCenterOutlinedIcon from '@mui/icons-material/FitnessCenterOutlined';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import SportsGymnasticsOutlinedIcon from '@mui/icons-material/SportsGymnasticsOutlined';
import SearchIcon from '@mui/icons-material/Search';
import { Link } from "react-router-dom";
import { DarkModeContext } from "../../context/darkModeContext";
import { useContext, useEffect } from "react";
import logo from "../../images/kinetisense-logo-black.png";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import InfoYesNoDialog from "../dialogs/InfoYesNoDialog";
import SubscriptionsOutlinedIcon from '@mui/icons-material/SubscriptionsOutlined';
import PlumbingOutlinedIcon from '@mui/icons-material/PlumbingOutlined';
import BuildOutlinedIcon from '@mui/icons-material/BuildOutlined';
import CarpenterOutlinedIcon from '@mui/icons-material/CarpenterOutlined';
import HomeRepairServiceOutlinedIcon from '@mui/icons-material/HomeRepairServiceOutlined';

const Sidebar = (props) => {
  const { dispatch } = useContext(DarkModeContext);
  const [displayLogout, setDisplayLogout] = useState(false);
  const navigate = useNavigate();

  useEffect (() =>{
   setDisplayLogout(false);
  const a=1;
  },[props, setDisplayLogout]);

    const handleLogout = () => {
      setDisplayLogout(false);
      localStorage.removeItem("token");
      localStorage.removeItem("LoginInfo");
      
      navigate("/login");
    }

    const handleCloseLogout = () => {
      setDisplayLogout(false);
    }

    const handleOpenLogoutDialog = () => {
      setDisplayLogout(true);
      console.log("open Dialog");
    }

  return (
    <>
        <InfoYesNoDialog open={displayLogout} handleClose = {handleCloseLogout} handleAccept={handleLogout} message="Do you want to logout?" />
        <div className="sidebar">
          <div className="top">
            <Link to="/" style={{ textDecoration: "none" }}>
            <img className="img" src={logo} alt="logo" />
            </Link>
          </div>
          <hr />
          <div className="center">
            <ul>
              <p className="title">MAIN</p>
              <Link to="/" style={{ textDecoration: "none" }}>
                <li>
                  <DashboardIcon className="icon" />
                  <span>Dashboard</span>
                </li>
              </Link>
              <p className="title">ADMINISTRATION</p>
              {/* <Link to="/users" style={{ textDecoration: "none" }}>
                <li>
                  <PersonOutlineIcon className="icon" />
                  <span>Users</span>
                </li>
              </Link> */}
              <Link to="/organizations" style={{ textDecoration: "none" }}>
                <li>
                  <AccountTreeIcon className="icon" />
                  <span>Organization</span>
                </li>
              </Link>
              <Link to="/customFields/MetaData" style={{ textDecoration: "none" }}>
                <li>
                  <DashboardCustomizeOutlinedIcon className="icon" />
                  <span>Custom Fields Features</span>
                </li>
              </Link>
              <Link to="/FlexLicenceTemplate" style={{ textDecoration: "none" }}>
                <li>
                  <HomeRepairServiceOutlinedIcon className="icon" />
                  <span>Flex Licence Templates</span>
                </li>
              </Link>
              
              <p className="title">LICENCE TOOL</p>
              <Link to="/licences" style={{ textDecoration: "none" }}>
                <li>
                  <SearchIcon className="icon" />
                  <span>Search</span>
                </li>
              </Link>
              <Link to="/licences/new" style={{ textDecoration: "none" }}>
                <li>
                  <SubscriptionsOutlinedIcon className="icon" />
                  <span>New</span>
                </li>
              </Link>
              
              <p className="title">CORRECTIVES</p>
              <Link to="/correctives/correctiveList" style={{ textDecoration: "none" }}>
                  <li>
                    <CheckBoxOutlinedIcon className="icon" />
                    <span>Correctives</span>
                  </li>
              </Link>
              <Link to="/correctives/exerciseList" style={{ textDecoration: "none" }}>
                  <li>
                    <SportsGymnasticsOutlinedIcon className="icon" />
                    <span>Exercise</span>
                  </li>
              </Link>
              <Link to="/correctives/equipmentList" style={{ textDecoration: "none" }}>
                  <li>
                    <FitnessCenterOutlinedIcon className="icon" />
                    <span>Equipment</span>
                  </li>
              </Link>
              <p className="title">TOOLS</p>
              <Link to="/utility/s2sSync" style={{ textDecoration: "none" }}>
                <li>
                  <SyncAltOutlinedIcon className="icon" />
                  <span>Server 2 Server Sync</span>
                </li>
              </Link>
             
             
              <p className="title">USER</p>
              <li>
                <AccountCircleOutlinedIcon className="icon" />
                <span>Profile</span>
              </li>
                  <li onClick={handleOpenLogoutDialog}>
                    <ExitToAppIcon className="icon" />
                    <span>Logout</span>
                  </li>
            </ul>
          </div>
          <div className="bottom">
            <div
              className="colorOption"
              onClick={() => dispatch({ type: "LIGHT" })}
            ></div>
            <div
              className="colorOption"
              onClick={() => dispatch({ type: "DARK" })}
            ></div>
          </div>
        </div>
    </>
  );
};

export default Sidebar;
