import "./Dialog.scss";

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';
import ExerciseDelete from '../Exercises/ExerciseDelete';


const DeleteExerciseDialog = ({open, id, handleClose, handleDeleteRow}) =>{

    
    return ( <Dialog 
                className="editdialog" 
                
                open={open} 
                fullWidth={true}
                maxWidth={"sm"}
                >
            <div id="warning">
                <DialogTitle className="dialogtitle" >
                    <div className="left">
                        <div className="dialogContentHeader"> <WarningAmberOutlinedIcon className="icon" fontSize="large" style={{marginTop:"8px"}}/><div fontSize="large">Warning</div>  </div>
                        
                    </div>
                    <IconButton onClick={handleClose} className="right">
                    <CloseIcon />
                    </IconButton>
                </DialogTitle>
            </div>
                <DialogContent id="warning" >
                    <ExerciseDelete id={id} handleClose={handleClose} handleDeleteRow={handleDeleteRow}/>
                </DialogContent>
      </Dialog>
      );
}

export default DeleteExerciseDialog;