import "./home.scss";
import {useState, useEffect} from 'react';
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import LicenceRenewalsChart from "../../components/chart/LicenceRenewalsChart";
import PastDueLicenceChart from "../../components/chart/PastDueLicenceChart";
import MonthlyRenewalsChart from "../../components/chart/MonthlyRenewalsChart";
import LicencePerFeatureChart from "../../components/chart/LicencePerFeatureChart"
import LicencePerModuleChart from "../../components/chart/LicencePerModuleChart"
import Number from "../../components/widget/Number";
import EditLicenceDialog from "../../components/dialogs/EditLicenceDialog";
import DeleteLicenceDialog from "../../components/dialogs/DeleteLicenceDialog";
import ViewLicenceDialog from "../../components/dialogs/ViewLicencesDialog";
import { useSession } from "../../context/SessionContext";
import DisplayListDialog from "../../components/dialogs/DisplayListDialog";
import SystemHealthWidget from "../../components/widget/SystemHealthWidget";
import LicencePerTypeChart from "../../components/chart/LicencePerTypeChart";
import formatRFC3339 from "date-fns/formatRFC3339";
import { RotatingLines } from "react-loader-spinner";

const Home = () => {
  const [rows, setRows] = useState([]);
  const [list, setList] = useState([]);
  const [id, setId] = useState();
  const [edit, setEdit] = useState(false);
  const [view, setView] = useState(false);
  const [del, setDelete] = useState(false);
  const [skip,setSkip] = useState(0);
  const [displayList, setDisplayList] = useState(false)
  const [title, setTitle] = useState("");
  const session = useSession();
  const [isLoading, setLoading] = useState(true);
console.log("a. Skip: ",skip)
  useEffect (() => {
        let queryString = `?$filter=((id ne null) and (updatedAt ge 1970-01-01T20:00:00Z))&$orderby=updatedAt&$skip=${skip}&top=1000&search=*`
        // console.log("queryData: ",queryString)
      try { 
            fetch(session.subscriptionServer + "/tables/subscription/" + queryString, {
                method: 'GET',
                headers:{"Content-type":"application/json"}
            }).then ((response) => response.json())
            .then ((data) => {
              // console.log("data: ",data)
              if (data.length > 0){
                  setRows(rows.concat(data.filter(row => row.isTestLicence === false)));
                  // setRows(rows.concat(data));
                  setSkip(skip + data.length);
                  
                  // console.log("Fetch again");
              } else {
                    setLoading(false);
              }
            }).catch ((error) => {
                console.log(error);
            });  
      } catch(err) {
        console.log(err);
      }
     
  },[skip]);

  const handleCloseEditDialog = () => {
    setEdit(false);
  }
  const handleCloseViewDialog = () => {
    setView(false);
  }
  const handleCloseDeleteDialog = () => {
    setDelete(false);
  }

  const handleEdit = async (id) =>{
    setEdit(true);
    setId(id);
    
  }
  const handleView = (id) => {
    setView(true);
    setId(id);
  }

  const handleDelete = (id) => {
    setDelete(true);
    setId(id);
  }

  const actionColumn = [
    {
      field: "action",
      headerName: "Action",
      width: 200,
      renderCell: (params) => {
        return (
          <div className="cellAction">
            <div className="viewButton" onClick={() => handleView(params.row.id)}>View</div>
              <div className="editButton" onClick={() => handleEdit(params.row.id)}>Edit</div>
            <div className="deleteButton" onClick={() => handleDelete(params.row.id)}>Delete</div>
          </div>
        );
      },
    },
  ];

  const handleRemoveFromRows = (id) =>{
    const newList = rows.filter((r) => r.id !== id );
    setRows(newList);
  }

  const handleUpdateRows = (r) =>{
    console.log("handleUpdateRows: ", r)
      let i =0;
      let j = 0;
      rows.forEach((el) => {
          if (el.id === r.id) {
              j=i;
          }
          i++;
      })
      const newList = [...rows];
      newList[j]=r;
      setRows(newList);
  }

  const handleOnGraphClick = (list, listTitle) =>{
      setList(list);
      setTitle(listTitle);
      setDisplayList(true);
      console.log("GraphOnClick: ", list);
  }

  const handleDisplayListClose = () => {
    setDisplayList(false);
  }


  return (
    <>
      <EditLicenceDialog id={id} handleClose = {handleCloseEditDialog} open={edit} handleUpdateRows={handleUpdateRows}/>
      <ViewLicenceDialog id={id} handleClose = {handleCloseViewDialog} open={view} />
      <DeleteLicenceDialog id={id} handleClose = {handleCloseDeleteDialog} open={del} handleDeleteRow={handleRemoveFromRows} />
      <DisplayListDialog list={list} handleClose = {handleDisplayListClose} open={displayList} title={title} />
          <div className="home">
              <Sidebar />
              <div className="homeContainer">
                <Navbar />
                { isLoading &&
                <center style={{paddingTop:100}}>
                    <RotatingLines  strokeColor="grey"  strokeWidth="2"  animationDuration="1.5"  width="150"  visible={isLoading} />
                </center>
                }
                {!isLoading &&
                  <>
                      <div className="widgets">
                        <PastDueLicenceChart rows={rows} handleOnGraphClick={handleOnGraphClick} />
                        <LicenceRenewalsChart rows={rows} handleOnGraphClick={handleOnGraphClick} />
                        <Number title="Active Licences" amount={rows.filter(row => row.expirationDate >= formatRFC3339(new Date())).length} />
                        <SystemHealthWidget />
                      </div>
                      <div className="charts">
                        <LicencePerTypeChart rows={rows} handleOnGraphClick={handleOnGraphClick} />
                        <LicencePerModuleChart rows={rows} handleOnGraphClick={handleOnGraphClick} />
                        <LicencePerFeatureChart rows={rows} handleOnGraphClick={handleOnGraphClick} />
                      </div>
                      <div className="charts">
                        <MonthlyRenewalsChart rows={rows} handleOnGraphClick={handleOnGraphClick}/>
                      </div>
                 </>
                }
              </div>
          </div>
    </>
  );
};

export default Home;
