import "./Dialog.scss";

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import LicenceAccountTableView from '../datatable/LicenceAccountTableView';


const ViewLicenceDialog = (props) => {
    return ( <Dialog 
        PaperProps={{
          sx: {
            minHeight: 900
          }
        }}
          className="editdialog" 
          open={props.open} 
          // onClose={handleCloseViewDialog}
          fullWidth={true}
          maxWidth={"md"}
          >
          <DialogTitle className="dialogtitle">
            <div className="left">
            Licence Detail
            </div>
              <IconButton onClick={props.handleClose} className="right">
                <CloseIcon />
              </IconButton>
          </DialogTitle>
          <DialogContent  className="dialogcontent">
            <LicenceAccountTableView id ={props.id}/>
          </DialogContent>
        </Dialog>
    )

}

export default ViewLicenceDialog;