
import "./Chart";
import "../widget/Widget";
import { modules } from "../../data/ControlledLists";
import { useEffect, useState } from "react";
import { LicenceTypes } from "../../data/ControlledLists";
import { formatRFC3339 } from "date-fns";

import { BarChart, CartesianGrid, XAxis, YAxis,Tooltip,Legend, Bar} from "recharts";
const LicencePerTypeChart = ({rows, handleOnGraphClick}) =>{
    const [data, setData] = useState([]);

    useEffect (() => {
        var arr = [];
        for (var i = 0; i < LicenceTypes.length; i++) {
           var filtered = rows.filter(row =>  (row.name.includes(LicenceTypes[i]) && row.expirationDate > formatRFC3339(new Date()) ));
           arr.push({
                        module:LicenceTypes[i],
                        amount: filtered.length
           })
        }
        setData(arr);
    },[rows])
      
    const handleOnClick = (e) => {
        const filtered = rows.filter(row =>  (row.name.includes(e.activeLabel) && row.expirationDate > formatRFC3339(new Date()) ));
        handleOnGraphClick(filtered,"for type: "+e.activeLabel);

    }

    return( 
        <div className="chart">
            <div className="left" >
            <center className="title">Licence Per Type</center>
                <BarChart width={350} height={500} data={data} layout="vertical" margin={{ top: 5, right: 0, left: 100, bottom: 5  }} onClick={bob => handleOnClick(bob)}>
                    <CartesianGrid strokeDasharray="1 1" horizontal={false} stroke="gray" />
                    <XAxis  type="number"/>
                    <YAxis type="category" dataKey="module" interval={0}/>
                    <Tooltip />
                    <Legend  />
                    <Bar dataKey="amount" fill= "#009ce0"/>
                </BarChart>
            </div>
      </div>
    )
};

export default LicencePerTypeChart;