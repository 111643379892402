import "./Dialog.scss";

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Button from "@mui/material/Button";
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
const ErrorOkDialog = ({open, message, handleClose}) => {

    return (
        <Dialog 
            open={open} 
            fullWidth={true}
            maxWidth={"sm"}
            className="editdialog"
            >
            <DialogContent id="error">
                <DialogContentText >
                <div className="dialogContentHeader"> <ErrorOutlineOutlinedIcon className="icon"/><h3>Error</h3>    </div>
                    <strong>{message}</strong>
                </DialogContentText>
            </DialogContent>
            <DialogActions id="error">
                <Button onClick={handleClose} autoFocus>
                    OK
                </Button>
            </DialogActions>
        </Dialog>
    )
}
export default ErrorOkDialog