import React from "react";
import {useState, useEffect} from 'react';
import Box from "@mui/material/Box";
import  Button  from '@mui/material/Button';
import ExerciseInformation from "./ExerciseInformation";
import { useLoginServer } from "../../context/LoginServerContext";
import { useAuthContext } from "../../context/AuthContext";
import {CSVString2Array} from "../../Utilities/Utilities";
import ExerciseData from "../../data/ExerciseData";
import { Array2CSVString } from "../../Utilities/Utilities";

const ExerciseEdit = ({id, handleClose, handleUpdateRows}) => {

    const [value, setValue] = useState('1');
    const [row, setRow] = useState(ExerciseData);
    const [errorMessage, setErrorMessage] = useState(false);
    const [savedMessage, setSavedMessage] = useState(false);
    const loginServerURL = useLoginServer();
    const {currentToken} = useAuthContext();
    const [warningFlag, setWarningFlag] = useState(false);
    const [message, setMessage] = useState("");
    const [equipmentList, setEquipmentList] = useState([]);

    console.log("Main Row: ",row);
    console.log("Equipment List",equipmentList)
    
    useEffect (() => {
            loadEquipmentList();
           
                try { 
                    const sqlCommand = loginServerURL + "/tables/exercise/"+id;
                    console.log(sqlCommand);
                    fetch(sqlCommand, {
                        method: 'GET',
                        headers:{
                            "X-ZUMO-AUTH": currentToken,
                            "Content-type":"application/json"
                        }
                    }).then ((response) => {
                    if(response.status>= 200 && response.status < 300){
                        return response.json();
                    } else {
                        throw new Error(response.status);
                    }
                    })
                    .then ((data) => {
                        console.log("Exercise fetched: ",data.queryable[0]);
                        setRow(data.queryable[0])
                    }).catch ((error) => {
                        console.log(error);
                    });  
                } catch(err) {
                    console.log(err);
                }
    },[]);

    const loadEquipmentList = () => {
        console.log("Reading equipment list");
        try { 
            const sqlCommand = loginServerURL + "/tables/equipment";
            console.log(sqlCommand);
            fetch(sqlCommand, {
                method: 'GET',
                headers:{
                    "X-ZUMO-AUTH": currentToken,
                    "Content-type":"application/json"
                }
            }).then ((response) => {
              if(response.status>= 200 && response.status < 300){
                return response.json();
              } else {
                console.log("Response.status",response.status)
                throw new Error(response.status);

              }
            })
            .then ((data) => {
                console.log("Equipment fetched: ",data);
                setEquipmentList(data)
            }).catch ((error) => {
                console.log(error);
            });  
        } catch(err) {
            console.log(err);
        }
    }
  
    
      const handleAdd = async (e) => {
        e.preventDefault();
        console.log("Save");
        var newRow = row;
        newRow.exerciseTexts = null;
         try { 
          fetch(loginServerURL + "/tables/exercise/" + row.id, {
              method: 'PATCH',
              body: JSON.stringify(newRow),
              headers:{
                "X-ZUMO-AUTH": currentToken,
                "Content-type":"application/json"
            }
          }).then ((response) => {
            if (response.status>=200 && response.status <300) {
            return response.json();
            } else {
                throw new Error(response.status)
            }
          })
          .then ((data) => {
              setRow(data);
              handleUpdateRows(data);
              console.log("Updated: ",data);
              setSavedMessage(true);
          }).catch ((error) => {
              console.log(error);
              setErrorMessage(true);
          });  
        } catch(err) {
          console.log(err);
        }

      };

      const validation= () => {
        setWarningFlag(false);
        setMessage("")
        var flag = false;
        if (row.name === "" ){
            setMessage("Enter exercise Name");
            setWarningFlag(true);
        } 
          
        return !flag;
      }
    
      const handleFieldUpdate = (event, newValue, id) => {
        console.log("Case: ",id,  newValue)
        setErrorMessage(false);
        setSavedMessage(false);
        const updatedDate = Date.UTC;
        if (id ==='name') {
            setRow({...row, name: newValue })
        } else if (id ==='equipmentNeeded') {
            setRow({...row, equipmentNeeded: Array2CSVString(newValue), updatedAt: updatedDate })
        }else if (id ==='videoURL') {
            setRow({...row, videoURL: newValue, updatedAt: updatedDate })
        } else if (id ==='market') {
            setRow({...row, market: newValue, updatedAt: updatedDate })
        } else if (id ==='group') {
            setRow({...row, group: newValue, updatedAt: updatedDate })
        } else if (id ==='primaryLanguage') {
            setRow({...row, primaryLanguage: newValue, updatedAt: updatedDate })
        }  else if (id ==='exerciseTexts') {
            setRow({...row, exerciseTexts: newValue, updatedAt: updatedDate })
        }  else {
            console.log("Missing: ",id)
        }
      }

    return (
        
         <form > 
            {/* </form>onSubmit={handleAdd}> */}
            <div className="formInput"  style={{margin:"10px"}}>
                <Box>
                    <ExerciseInformation 
                        data={row} 
                        title={"Exercise Information"} 
                        handleClose={handleClose}
                        handleFieldUpdate={(event, newValue, id) => handleFieldUpdate(event, newValue, id)}
                        equipmentList ={equipmentList} 
                        equipmentNeeded={CSVString2Array(row.equipmentNeeded)}
                    />
                </Box>
                <div className="formButtons" >
                    <div className="left">
                        {errorMessage && <div className="error">An error occurred</div>}
                        {savedMessage && <div className="message">Exercise Saved</div>}
                        {warningFlag && <div className="warning">{message}</div>}
                    </div>
                    <div className="right">
                        <Button  
                            type="submit"
                            variant="contained"
                            className="button"
                            onClick={handleAdd}
                        >
                            Save
                        </Button>
                    </div>
                </div>
            </div>
        </form>
            
    );
}

export default ExerciseEdit;