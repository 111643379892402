
import "./Chart";
import "../widget/Widget";

import { BarChart, CartesianGrid, XAxis, YAxis,Tooltip,Legend, Bar } from "recharts";
import { formatRFC3339, subMonths } from "date-fns";

const PastDueLicenceWidget = ({rows, handleOnGraphClick}) =>{
   

    const licence30 =  rows.filter(row => {
        return row.expirationDate <= formatRFC3339(new Date()) && row.expirationDate > formatRFC3339(subMonths(new Date(),1));
    });

    const licence60 = rows.filter(row => {
        return row.expirationDate <= formatRFC3339(subMonths(new Date(),1)) && row.expirationDate > formatRFC3339(subMonths(new Date(),2));
    });

    const licence90 = rows.filter(row => {
        return row.expirationDate <= formatRFC3339(subMonths(new Date(),2)) && row.expirationDate > formatRFC3339(subMonths(new Date(),3));
    });

    // console.log("30: ",licence30);
    // console.log("60: ",licence60);
    // console.log("90: ",licence90);
   

    const data = [
        { name: "0-30", licences: licence30.length},
        { name: "30-60", licences: licence60.length},
        { name: "60-90", licences: licence90.length}
      ];

      const handleOnClick = (e) => {
        if (e.activeLabel === "0-30") {
            handleOnGraphClick(rows.filter(row => {
                return row.expirationDate <= formatRFC3339(new Date()) && row.expirationDate > formatRFC3339(subMonths(new Date(),1));
            }),"for past due period: " + e.activeLabel);
        } else if (e.activeLabel === "30-60") {
            handleOnGraphClick(rows.filter(row => {
                return row.expirationDate <= formatRFC3339(subMonths(new Date(),1)) && row.expirationDate > formatRFC3339(subMonths(new Date(),2));
            }),"for past due period: " + e.activeLabel);
        } else if (e.activeLabel === "60-90") {
            handleOnGraphClick(rows.filter(row => {
                return row.expirationDate <= formatRFC3339(subMonths(new Date(),2)) && row.expirationDate > formatRFC3339(subMonths(new Date(),3));
            }),"for past due period: " + e.activeLabel);
        }
      }

    return( 
        <div className="widget">
            <div className="left" >
            <center className="title">Licence Overdue</center>
            <center>
                <BarChart width={300} height={180} data={data} onClick={b => handleOnClick(b)}>
                    <CartesianGrid strokeDasharray="1 1" vertical={false} stroke="gray" />
                    <XAxis dataKey="name" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Bar dataKey="licences" fill="red" />
                </BarChart>
                </center>
            </div>
      </div>
    )
};

export default PastDueLicenceWidget;