import "../datatable/datatable.scss";
import "../datatable/licenceDataTable.scss";

import { DataGrid } from "@mui/x-data-grid";
import { LicenceColumns, LicenceRows } from "../../datatablesource";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import ViewLicenceDialog from "../dialogs/ViewLicencesDialog";
import EditLicenceDialog from "../dialogs/EditLicenceDialog";
import DeleteLicenceDialog from "../dialogs/DeleteLicenceDialog";
import LicenceUsageDialog from "../dialogs/LicenceUsageDialog";
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import DashboardCustomizeOutlinedIcon from '@mui/icons-material/DashboardCustomizeOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';


const  CustomToolbar = () => {
  return (
    <GridToolbarContainer>
      <GridToolbarExport />
    </GridToolbarContainer>
  );
}

const LicenceList = ({list, handleClose}) => {

    const [id, setId] = useState();
    const [edit, setEdit] = useState(false);
    const [view, setView] = useState(false);
    const [del, setDelete] = useState(false);
    const [rows, setRows] = useState(list);
    const [licenceUsage, setLicenceUsage] = useState(false);

    useEffect(() => {
        var a=1;
    },[]);

    const handleCloseEditDialog = () => {
        setEdit(false);
    }
    const handleCloseViewDialog = () => {
        setView(false);
    }
    const handleCloseDeleteDialog = () => {
        setDelete(false);
    }
    const handleCloseLicenceUsageDialog = () => {
        setLicenceUsage(false);
      }
    

    const handleEdit = async (id) =>{
        setEdit(true);
        setId(id);
        
    }
    const handleView = (id) => {
        setView(true);
        setId(id);
    }

    const handleDelete = (id) => {
        setDelete(true);
        setId(id);
    }
    const handleLicenceUsage = (id) => {
        setLicenceUsage(true);
        setId(id);
      }

    const actionColumn = [
        {
        field: "action",
        headerName: "Action",
        width: 200,
        renderCell: (params) => {
            return (
            <div className="cellAction">
               <DashboardCustomizeOutlinedIcon style={{color:"gray", cursor: "pointer"}} onClick={() => handleLicenceUsage(params.row.id)}>Dashboard</DashboardCustomizeOutlinedIcon>
            <RemoveRedEyeOutlinedIcon style={{color:"green", cursor: "pointer"}} onClick={() => handleView(params.row.id)}>View</RemoveRedEyeOutlinedIcon>
              <ModeEditOutlineOutlinedIcon style={{color:"DodgerBlue", cursor: "pointer"}} onClick={() => handleEdit(params.row.id)}>Edit</ModeEditOutlineOutlinedIcon>
            <DeleteOutlineOutlinedIcon style={{color:"red", cursor: "pointer"}} onClick={() => handleDelete(params.row.id)}>Delete</DeleteOutlineOutlinedIcon>
            </div>
            );
        },
        },
    ];

    const handleRemoveFromRows = (id) =>{
        const newList = rows.filter((r) => r.id !== id );
        setRows(newList);
    }

  const handleUpdateRows = (r) =>{
    console.log("handleUpdateRows: ", r)
      let i =0;
      let j = 0;
      rows.forEach((el) => {
          if (el.id === r.id) {
              j=i;
          }
          i++;
      })
      const newList = [...rows];
      newList[j]=r;
      setRows(newList);
  }


  return (
    <>
      <EditLicenceDialog id={id} handleClose = {handleCloseEditDialog} open={edit} handleUpdateRows={handleUpdateRows}/>
      <ViewLicenceDialog id={id} handleClose = {handleCloseViewDialog} open={view} />
      <DeleteLicenceDialog id={id} handleClose = {handleCloseDeleteDialog} open={del} handleDeleteRow={handleRemoveFromRows} />
      <EditLicenceDialog id={id} handleClose = {handleCloseEditDialog} open={edit} handleUpdateRows={handleUpdateRows}/>
      <LicenceUsageDialog id={id} handleClose = {handleCloseLicenceUsageDialog} open={licenceUsage} /> 
    <div className="datatable" style={{height:660}}>
      <DataGrid
        className="datagrid"
        rows={rows}
        columns={LicenceColumns.concat(actionColumn)}
        pageSize={15}
        rowsPerPageOptions={[15]}
        //checkboxSelection
        density='compact'
        slots={{
          toolbar: CustomToolbar,
        }}
      />
    </div>
    </>
  );
};

export default LicenceList;
