import * as React from 'react';
import TextField from '@mui/material/TextField';
import  Button  from '@mui/material/Button';
import Autocomplete from '@mui/material/Autocomplete';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import Checkbox from '@mui/material/Checkbox';
import { CorrectivesGroups, CorrectivesLevels, CorrectivesMarkets, CorrectivesJoints } from '../../data/ControlledLists';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { languageList } from '../../data/ControlledLists';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import ExerciseLookupListDialog from '../dialogs/ExerciseLookupListDialog';
import PlayCircleFilledWhiteOutlinedIcon from '@mui/icons-material/PlayCircleFilledWhiteOutlined';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

 const  CorrectiveInformation = ({data, handleFieldUpdate, exercise, exerciseText, equipmentList, equipmentNeeded, id, list}) => {    

    const [open, setOpen] = React.useState(false);
    const [readOnly, setReadOnly] = React.useState(false);

    console.log("data:",data);
    console.log("exercise:",exercise);
    console.log("exerciseText:",exerciseText);
    console.log("List:",list);

    const handleListUpdate= (event, newValue) => {
        handleFieldUpdate(event, newValue, "equipmentNeeded");
    }

    const handleTextUpdate = (newvalue) => {
        console.log("Text update");
        handleFieldUpdate(null, newvalue, "exerciseTexts");
    }

    const handleShowLookupDialog = () => {
        setOpen(true);

    }

    const handleCloseLookupDialog = () => {
        setOpen(false);
    }

    const handleExerciseSelected = (newRow) => {
        if (newRow !== null) {
            handleFieldUpdate(null, newRow, "exercise");
            console.log("New Row: ", newRow);
            setReadOnly(true);
        }
    }

    const handlePlayVideo = () => {
        console.log("Play Video");
        var url = "https://res.cloudinary.com/dkfzgerhr/video/upload/ac_none,c_fill,f_mp4,h_1000,q_auto,vc_h264:baseline,w_1000/Correctives/"+exercise.group+"/"+exercise.videoURL;
        window.open(url, '_Video',"resizable=no, toolbar=no, scrollbars=no, menubar=no, status=no, directories=no, width=400, height=400");
    }

   
    if (exercise !== undefined && exerciseText !== undefined) {
    return (
        <>
        <ExerciseLookupListDialog id={id} handleClose = {handleCloseLookupDialog} open={open} handleExerciseSelected={(row) => handleExerciseSelected(row)} list={list} market={exercise.market} group={exercise.group}/>
        <div>
            <div>
                <FormControl fullWidth className='TextField' required>
                    <InputLabel id="Primary-Language-label">Joint</InputLabel>
                    <Select
                        labelId="Joint"
                        required
                        id="Joint"
                        value={data.joint}
                        label="Joint"
                        mandatory="true"
                        // size="small"
                        onChange={(event, value) => handleFieldUpdate(event, event.target.value, "joint")}
                    >
                        <MenuItem value={""}>""</MenuItem>
                        {CorrectivesJoints.map(row => {return (<MenuItem value={row}>{row}</MenuItem>)} )}
                        
                    </Select>
                </FormControl>
             </div>

             <FormGroup>
                <div>
                <FormControlLabel control={<Checkbox
                        id='isMobility'
                        checked={data.isMobility}
                        onChange={(event, value) => handleFieldUpdate(event, event.target.checked, event.target.id)}
                        inputProps={{ 'aria-label': 'controlled' }}
                    />} label="Mobility" />
                    
                {/* </div>
                <div> */}
                <FormControlLabel control={<Checkbox
                        id='isStability'
                        checked={data.isStability}
                        onChange={(event, value) => handleFieldUpdate(event, event.target.checked, event.target.id)}
                        inputProps={{ 'aria-label': 'controlled' }}
                    />} label="Stability" />
                    
                </div>
            
            </FormGroup>
            <div>
                <FormControl fullWidth className='TextField' required>
                    <InputLabel id="Primary-Language-label">Level</InputLabel>
                    <Select
                    fullWidth
                        required
                        labelId="Level"
                        id="level"
                        value={data.level}
                        label="Level"
                        // size="small"
                        onChange={(event, value) => handleFieldUpdate(event, event.target.value, "level")}
                    >
                        <MenuItem value={""}>""</MenuItem>
                        {CorrectivesLevels.map(row => {return (<MenuItem value={row}>{row}</MenuItem>)} )}
                        
                    </Select>
                </FormControl>
             </div>
           
             <div>
                <FormControl fullWidth className='TextField' required disabled={readOnly}>
                    <InputLabel id="Primary-Language-label">Market</InputLabel>
                    <Select
                        required
                        labelId="Market"
                        id="market"
                        value={exercise.market}
                        label="Market"
                        // size="small"
                        onChange={(event, value) => handleFieldUpdate(event, event.target.value, "market")}
                    >
                        <MenuItem value={""}>""</MenuItem>
                        {CorrectivesMarkets.map(row => {return (<MenuItem value={row}>{row}</MenuItem>)} )}
                        
                    </Select>
                </FormControl>
             </div>
             <div>
                <FormControl fullWidth className='TextField' required disabled={readOnly}>
                    <InputLabel id="Primary-Language-label">Group</InputLabel>
                    <Select
                        required
                        labelId="Group"
                        id="group"
                        value={exercise.group}
                        label="Group"
                        // size="small"
                        onChange={(event, value) => handleFieldUpdate(event, event.target.value, "group")}
                    >
                        <MenuItem value={""}>""</MenuItem>
                        {CorrectivesGroups.map(row => {return (<MenuItem value={row}>{row}</MenuItem>)} )}
                        
                    </Select>
                </FormControl>
             </div>
             {id === "new" && 
             <div>
                <FormControl fullWidth className='TextField' required disabled={readOnly}>
                    <InputLabel id="Primary-Language-label">Primary Language</InputLabel>
                    <Select
                        required
                        labelId="primaryLanguage"
                        id="primaryLanguage"
                        value={exercise.primaryLanguage}
                        label="Primary Language"
                        // size="small"
                        onChange={(event, value) => handleFieldUpdate(event, event.target.value, "primaryLanguage")}
                    >
                        <MenuItem value={""}>""</MenuItem>
                        {languageList.map(row => {return (<MenuItem value={row.id}>{row.name}</MenuItem>)} )}
                        
                    </Select>
                </FormControl>
             </div>
            }
             <div>
                <TextField
                    style={{width: "90%"}}
                    required
                    id="name" 
                    label="Exercise Name" 
                    variant="outlined"
                    className="TextField"
                    value={exerciseText.name}
                    onChange={(event, value) => handleFieldUpdate(event, event.target.value, event.target.id)}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    InputProps={{
                        
                        disabled: readOnly
                    }}
                />
                {id ==="new" && <SearchOutlinedIcon style={{marginTop:"15px", marginLeft:"10px", color:"green", cursor: "pointer"}}  onClick={() => handleShowLookupDialog()}/>}
            </div>
            <div>
                <Autocomplete
                    className="combobox"
                    
                    multiple
                    id={data.id}
                    options={equipmentList.map((item) => {return item.id})}
                    disableCloseOnSelect
                    getOptionLabel={(option) => option}
                    value = {equipmentNeeded}
                    disabled={readOnly}
                    renderOption={(props, option, { selected }) => (
                        <li {...props}>
                        <Checkbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 8 }}
                            checked={selected}
                        />
                        {option}
                        </li>
                    )}
                    // style={{width: "90%"}}
                    renderInput={(params) => (
                        <TextField {...params} label={"Equipment"} placeholder={"Filter"}/>
                    )}
                    onChange = {(event,newValue) => handleListUpdate(event, newValue)}
                    onInputChange={(event,newValue) => handleListUpdate(event, newValue)}
                />
               
               
            </div>
            
             <div>
                <TextField
                style={{width: "90%"}}
                    fullWidth
                    required
                    id="videoURL" 
                    label="videoURL" 
                    variant="outlined"
                    className="TextField"
                    value={exercise.videoURL}
                    onChange={(event, value) => handleFieldUpdate(event, event.target.value, event.target.id)}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    InputProps={{
                        
                        disabled: readOnly
                    }}
                />
                <PlayCircleFilledWhiteOutlinedIcon style={{marginTop:"15px", marginLeft:"10px", color:"green", cursor: "pointer"}}  onClick={() => handlePlayVideo()}/>
            </div>
            <div>
                <TextField
                    fullWidth
                    required
                    id="instructions" 
                    label="Instructions" 
                    variant="outlined"
                    className="TextField"
                    multiline = {true}
                    rows={5}
                    value={exerciseText.instructions}
                    onChange={(event, value) => handleFieldUpdate(event, event.target.value, event.target.id)}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    InputProps={{
                        
                        disabled: readOnly
                    }}
                />
            </div> 
        </div>
    </>
    );
    } else {
        return (<label>Loading...</label>)
    }
}

export default CorrectiveInformation;