import "./Dialog.scss";

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import LicenceAccountTableView from '../datatable/LicenceAccountTableView';
import CollapsibleTable from "../organization/CollapsibleTable";

const ViewExerciseDialog = ({open, id, handleClose, title}) => {
    return ( <Dialog 
        PaperProps={{
          sx: {
            minHeight: 1020
          }
        }}
          className="editdialog" 
          open={open} 
          // onClose={handleCloseViewDialog}
          fullWidth={true}
          maxWidth={"md"}
          >
          <DialogTitle className="dialogtitle">
            <div className="left">
            {title}
            </div>
              <IconButton onClick={handleClose} className="right">
                <CloseIcon />
              </IconButton>
          </DialogTitle>
          <DialogContent  className="dialogcontent">
            <CollapsibleTable id ={id}/>
          </DialogContent>
        </Dialog>
    )
}

export default ViewExerciseDialog;