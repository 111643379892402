import { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import ErrorOkDialog from "../dialogs/ErrorOkDialog";
import { useLoginServer } from "../../context/LoginServerContext";
import { useAuthContext } from "../../context/AuthContext";
import { CorrectiveData } from "../../data/CorrectiveData";
import ExerciseTextData from "../../data/ExerciseTextData";
import { th } from "date-fns/locale";

const CorrectiveDelete = ({id, handleClose, handleDeleteRow, rows}) => {
    
    const [row,setRow] = useState(CorrectiveData);
    const [exerciseText, setExerciseText] = useState(ExerciseTextData);
    const [displayError, setDisplayError] = useState(false);
    const [message, setMessage] = useState();
    const loginServerURL = useLoginServer();
    const {currentToken} = useAuthContext();

        console.log("Delete Corrective row: ", row);
        console.log("ID: ",id);
   useEffect (() => {
        
    try { 
        const sqlCommand = loginServerURL + "/tables/corrective/"+id;
        console.log(sqlCommand);
        fetch(sqlCommand, {
            method: 'GET',
            headers:{
                "X-ZUMO-AUTH": currentToken,
                "Content-type":"application/json"
            }
        }).then ((response) => {
          if(response.status>= 200 && response.status < 300){
            return response.json();
          } else {
            throw new Error(response.status);
          }
        })
        .then ((data) => {
            console.log("Corrective fetched: ",data.queryable[0]);
            setRow(data.queryable[0])
            setExerciseText(data.queryable[0].exercise.exerciseTexts[0]);
        }).catch ((error) => {
            console.log(error);
        });  
    } catch(err) {
        console.log(err);
    }
     },[]);

     const handleDelete = () => {
        console.log("Delete Exercise");
        console.log("Corrective Count: ", checkCorrectiveCountForExercise());
        try {
            deleteCorrective();
            if (checkCorrectiveCountForExercise() === 1) {
                    deleteExercise(row.exerciseId);
                    row.exercise.exerciseTexts.forEach((text) => {
                        deleteExerciseText(text.id);
                    });
                }
                handleDeleteRow(id);
                handleClose();
        } catch (err) {
            console.log(err);  
            setMessage(err.message);
            setDisplayError(true); 
        }
       
     }

     const deleteCorrective = () => {
        fetch(loginServerURL + "/tables/corrective/" + row.id, {
            method: 'DELETE',
            headers:{
                "X-ZUMO-AUTH": currentToken,
                "Content-type":"application/json"
            }
        }).then ((data) => {
        }).catch ((err) => {
            console.log(err);
            throw new Error(err);
        });  
     }

     const deleteExercise = (lid) => {
         fetch(loginServerURL + "/tables/exercise/" + lid, {
              method: 'DELETE',
              headers:{
                "X-ZUMO-AUTH": currentToken,
                "Content-type":"application/json"
              }
          }).then ((data) => {
          }).catch ((err) => {
              console.log(err);
             throw new Error(err);
          });  
    }

    const deleteExerciseText = (lid) => {
        fetch(loginServerURL + "/tables/exerciseText/" + lid, {
            method: 'DELETE',
            headers:{
            "X-ZUMO-AUTH": currentToken,
            "Content-type":"application/json"
            }
          }).then ((data) => {
          }).catch ((err) => {
              console.log(err);
              throw new Error(err);
          });  
    }


     const checkCorrectiveCountForExercise = () => {
        return rows.filter((line) => line.exerciseId === row.exerciseId && line.isDeleted === false).length;
     }

     const handleErrorDialogClose = () =>{
        setDisplayError(false)
     }
    return (
    <>
        <ErrorOkDialog open={displayError} message={message} handleClose={handleErrorDialogClose} />
        <div>
            <div className="forminput">
                <strong>Do you want to delete the Corrective: {(exerciseText.name || "Not Found")}?</strong>
            </div>
            <div className="formButtons" >
                <div className="right">
                <Button onClick={handleDelete}>Yes</Button>
                <Button onClick={handleClose}>No</Button>
                </div>
            </div>
        </div>
    </>
    )
}

export default CorrectiveDelete;

