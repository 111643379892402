export const PractitionerData = {
    "bio": "",
    "specialty": "",
    "isAdministrator": false,
    "isOperator": false,
    "isActive": true,
    "clinicId": "",
    "username": "",
    "app": 0,
    "name": "",
    "middleName": null,
    "surname": "",
    "email": "",
    "isMale": true,
    "birthday": "",
    "imagePath": "",
    "healthcareNumber": "",
    "height": 0.0,
    "weight": 0.0,
    "isDeleted": false,
    "isArchived": false,
    "errorCode": null,
    "errorMessage": null,
    "id": "",
    "version": "",
    "createdAt": "",
    "updatedAt": "",
    "deleted": false
};
