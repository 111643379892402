import React from "react";
import {useState, useEffect} from 'react';
import Box from "@mui/material/Box";
import  Button  from '@mui/material/Button';
import ExerciseTextInformation from "./ExerciseTextInformation";
import { useLoginServer } from "../../context/LoginServerContext";
import { useAuthContext } from "../../context/AuthContext";
import {CSVString2Array} from "../../Utilities/Utilities";
import { Array2CSVString } from "../../Utilities/Utilities";
import ExerciseTextData from "../../data/ExerciseTextData";
import uuid from "react-uuid";

const ExerciseTextEdit = ({id, handleUpdateRows, handleClose, languageList, exerciseId}) => {

    const [value, setValue] = useState('1');
    const [row, setRow] = useState(ExerciseTextData);
    const [errorMessage, setErrorMessage] = useState(false);
    const [savedMessage, setSavedMessage] = useState(false);
    const loginServerURL = useLoginServer();
    const {currentToken} = useAuthContext();
    const [warningFlag, setWarningFlag] = useState(false);
    const [message, setMessage] = useState("");

    console.log("ExerciseTextEdit Row: ",row);
    console.log("ExerciseTextEdit ExerciseId: ",exerciseId)
    console.log("Language List: ",languageList);
    useEffect (() => {
        if (id ==='new') {
            
        }
        else {
                try { 

                const sqlCommand = loginServerURL + "/tables/exercisetext/"+id;
                console.log(sqlCommand);
                fetch(sqlCommand, {
                    method: 'GET',
                    headers:{
                        "X-ZUMO-AUTH": currentToken,
                        "Content-type":"application/json"
                    }
                }).then ((response) => {
                  if(response.status>= 200 && response.status < 300){
                    return response.json();
                  } else {
                    throw new Error(response.status);
                  }
                })
                .then ((data) => {
                    console.log("Exercise text fetched: ",data.queryable[0]);
                    setRow(data.queryable[0])
                }).catch ((error) => {
                    console.log(error);
                });  
            } catch(err) {
                console.log(err);
            }
        }
    },[]);

    
    
      const handleAdd = async (e) => {
        e.preventDefault();
        console.log("Save");
        if (id === 'new') { // add new row
            var newRow = row;
            newRow.id = uuid().replaceAll("-","");
            newRow.exerciseId = exerciseId;
            try { 
                fetch(loginServerURL + "/tables/exerciseText/", {
                    method: 'POST',
                    body: JSON.stringify(newRow),
                    headers:{
                        "X-ZUMO-AUTH": currentToken,
                        "Content-type":"application/json"
                    }
                }).then ((response) => {
                    if (response.status>=200 && response.status <300) {
                    return response.json();
                    } else {
                        throw new Error(response.status)
                    }
                })
                .then ((data) => {
                    setRow(data);
                    console.log("Updated: ",data);
                    setSavedMessage(true);
                    handleUpdateRows(row);
                    handleClose();
                }).catch ((error) => {
                    console.log(error);
                    setErrorMessage(true);
                });  
                } catch(err) {
                console.log(err);
                }
        } else {  // Update existing row
                try { 
                fetch(loginServerURL + "/tables/exerciseText/" + row.id, {
                    method: 'PATCH',
                    body: JSON.stringify(row),
                    headers:{
                        "X-ZUMO-AUTH": currentToken,
                        "Content-type":"application/json"
                    }
                }).then ((response) => {
                    if (response.status>=200 && response.status <300) {
                    return response.json();
                    } else {
                        throw new Error(response.status)
                    }
                })
                .then ((data) => {
                    setRow(data);
                    console.log("Updated: ",data);
                    setSavedMessage(true);
                    handleUpdateRows(row);
                    handleClose();
                }).catch ((error) => {
                    console.log(error);
                    setErrorMessage(true);
                });  
                } catch(err) {
                console.log(err);
                }
    }

      };

      const validation= () => {
        setWarningFlag(false);
        setMessage("")
        var flag = false;
        if (row.name === "" ){
            setMessage("Enter exercise Name");
            setWarningFlag(true);
        } 
          
        return !flag;
      }
    
      const handleFieldUpdate = (event, newValue, id) => {
        console.log("Case: ",id,  newValue)
        setErrorMessage(false);
        setSavedMessage(false);
        const updatedDate = Date.UTC;
        if (id ==='name') {
            setRow({...row, name: newValue })
        } else if (id ==='instructions') {
            setRow({...row, instructions: newValue, updatedAt: updatedDate })
        } else if (id ==='languageCode') {
            setRow({...row, languageCode: newValue, updatedAt: updatedDate })
        } else {
            console.log("Missing: ",id)
        }
      }

    return (
        
        <form onSubmit={handleAdd}>
            <div className="formInput"  style={{margin:"10px"}}>
                <Box>
                    <ExerciseTextInformation 
                    data={row} 
                    title={"Exercise Information"} 
                    handleFieldUpdate={(event,value, id) => handleFieldUpdate(event, value, id)}
                    languageList={languageList}
                    />
                </Box>
                <div className="formButtons" >
                    <div className="left">
                        {errorMessage && <div className="error">An error occurred</div>}
                        {savedMessage && <div className="message">Linguistic Information Saved</div>}
                        {warningFlag && <div className="warning">{message}</div>}
                    </div>
                    <div className="right">
                        <Button  
                            type="submit"
                            variant="contained"
                            className="button"
                        >
                            Save
                        </Button>
                    </div>
                </div>
            </div>
        </form>
            
    );
}

export default ExerciseTextEdit;