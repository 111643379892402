import * as React from 'react';
import { LineChart } from '@mui/x-charts/LineChart';
import zIndex from '@mui/material/styles/zIndex';



export default function LineChartLic({series, xLabels}) {
    console.log("LineChart Series",series);
    console.log("LineChart xLabels",xLabels);
  return (
    <LineChart
    
     colors={['#ff6900', '#fcb900', '#78dcb5', '#00d084', '#8ed1fc', '#abb8c3', '#eb144c', '#f78da7', '#9900ef', '#2196f3', 'teal', '#ffeb3d']} 
      width={1100}
      height={300}
      series={series}
      xAxis={[{ scaleType: 'point', data: xLabels }]}
    />
  );
}