import { createContext, useContext, useState } from "react";
import { LoginInfoData } from "../data/LoginInfoData";

export const LoginServerContext = createContext();
export const UpdateLoginSeverContext = createContext()

export const InitialServerData = () =>{ 
  if (localStorage.getItem("LoginInfo") === null){
      localStorage.setItem("LoginInfo", JSON.stringify(LoginInfoData));
      return LoginInfoData
  } else {
    return JSON.parse(localStorage.getItem("LoginInfo"));
  }
}

export const useLoginServer = () => {
    return useContext(LoginServerContext);
}

export const useUpdateLoginServer = () => {
    return useContext(UpdateLoginSeverContext);
}
export const LoginServerProvider = ({ children }) => {
    const[loginInfo, setLoginInfo] = useState(InitialServerData());
   
    const updateLoginServer = (username, serverURL, token) => {
      console.log("SetLoginServerURL: ",serverURL)
      const object = {username:username,loginServerURL:serverURL,token:token};
      setLoginInfo(object);
      localStorage.setItem("LoginInfo",JSON.stringify(object))
    }

  return (
    <LoginServerContext.Provider value={loginInfo.loginServerURL}>
        <UpdateLoginSeverContext.Provider value={updateLoginServer}>
            {children}
        </UpdateLoginSeverContext.Provider>
    </LoginServerContext.Provider>
  );
};
