import "../table/table.scss"
import "../datatable/Datatable";
import "../datatable/LicenceDataTable";

import { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import TabPanel from '@mui/lab/TabPanel';
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import TabContext from "@mui/lab/TabContext";
import { DataGrid } from '@mui/x-data-grid';
import { LicenceColumns } from "../../datatablesource";
import { useLoginServer } from "../../context/LoginServerContext";
import { useAuthContext } from "../../context/AuthContext";
import { OrganizationUserColumns } from "../../datatablesource";
import { useSession } from "../../context/SessionContext";
import { darken, lighten } from '@mui/material/styles';
import { formatRFC3339, addMonths } from "date-fns";
import EditLicenceDialog from "../dialogs/EditLicenceDialog";
import ViewLicenceDialog from "../dialogs/ViewLicencesDialog";
import DeleteLicenceDialog from "../dialogs/DeleteLicenceDialog";
import EditOrganizationUserDialog from "../dialogs/EditOrganizationUserDialog";
import NewOrganizationUserDialog from "../dialogs/NewOrganizationUserDialog";
import DashboardCustomizeOutlinedIcon from '@mui/icons-material/DashboardCustomizeOutlined';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import LicenceUsageDialog from "../dialogs/LicenceUsageDialog";
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import { GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import OrganizationSettings from "./OrganizationSettings";
import OrganizationData from "../../data/OrganizationData";

const getBackgroundColor = (color, mode) =>
  mode === 'dark' ? darken(color, 0.6) : lighten(color, 0.9);

const getHoverBackgroundColor = (color, mode) =>
  mode === 'dark' ? darken(color, 0.5) : lighten(color, 0.7);

const getRowFormat = (row) =>{
  var d = new Date();
  var d1 = addMonths(d,1);
  if (row.expirationDate >= formatRFC3339(d) && row.expirationDate < formatRFC3339(d1)) {
    return "super-app-theme--Renew";
  } else if (row.expirationDate < formatRFC3339(d)) {
    return "super-app-theme--Expired";
  } else if (row.uniqueMatchingId === null) {
    return "super-app-theme--Available";
  } else {
    return "super-app-theme--Assigned"
  }
}

const CustomToolbar= () => {
  return (
    <GridToolbarContainer>
      <GridToolbarExport />
    </GridToolbarContainer>
  );
}


function Row(props) {
  const { row } = props;
  const [open, setOpen] = useState(false);

  return (
    <>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">{row.id}</TableCell>
        <TableCell >{row.name}</TableCell>
        <TableCell >{row.location}</TableCell>
        {/* <TableCell>{row.addresses.length > 0 ? row.addresses[0].value : ""} </TableCell> */}
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">
                Subscriptions
              </Typography>
                  <Box
                  sx={{
                    
                    width: '100%',
                    '& .super-app-theme--Available': {
                      bgcolor: (theme) =>
                        getBackgroundColor(theme.palette.info.light, theme.palette.mode),
                      '&:hover': {
                        bgcolor: (theme) =>
                          getHoverBackgroundColor(theme.palette.info.light, theme.palette.mode),
                      },
                    },
                    '& .super-app-theme--Assigned': {
                      bgcolor: (theme) =>
                        getBackgroundColor(theme.palette.success.light, theme.palette.mode),
                      '&:hover': {
                        bgcolor: (theme) =>
                          getHoverBackgroundColor(
                            theme.palette.success.main,
                            theme.palette.mode,
                          ),
                      },
                    },
                    '& .super-app-theme--Renew': {
                      bgcolor: (theme) =>
                        getBackgroundColor(theme.palette.warning.light, theme.palette.mode),
                      '&:hover': {
                        bgcolor: (theme) =>
                          getHoverBackgroundColor(
                            theme.palette.warning.light,
                            theme.palette.mode,
                          ),
                      },
                    },
                    '& .super-app-theme--Expired': {
                      bgcolor: (theme) =>
                        getBackgroundColor(theme.palette.error.light, theme.palette.mode),
                      '&:hover': {
                        bgcolor: (theme) =>
                          getHoverBackgroundColor(theme.palette.error.light, theme.palette.mode),
                      },
                    },
                  }}
                  >
              <Table size="small" aria-label="subscriptions">
                <TableHead>
                  <TableRow style={{backgroundColor:"lightgrey"}}>
                   
                    <TableCell>Licence Key</TableCell>
                    <TableCell>Expiration Date</TableCell>
                    <TableCell>Licence Type</TableCell>
                    <TableCell>Template Name</TableCell>
                    <TableCell># of Seats</TableCell>
                    <TableCell># of Devices</TableCell>
                    <TableCell >Modules</TableCell>
                    <TableCell >Features</TableCell>
                    
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.subscriptions.map((sub) => (
                    <TableRow key={sub.licenseKey} className={getRowFormat(sub)} >
                      <TableCell component="th" scope="row">
                        {sub.licenseKey}
                      </TableCell>
                      <TableCell>{sub.expirationDate}</TableCell>
                      <TableCell>{sub.name}</TableCell>
                      <TableCell>{sub.displayName}</TableCell>
                      <TableCell>{sub.amount}</TableCell>
                      <TableCell>{sub.maxNumberOfDevices}</TableCell>
                      <TableCell >{sub.modules}</TableCell>
                      <TableCell >{sub.features}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
                  </Box>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

Row.propTypes = {};
//   row: PropTypes.shape({
//     name: PropTypes.string.isRequired,
//     Location: PropTypes.string,
//     subscriptions: PropTypes.arrayOf(
//       PropTypes.shape({
//         licenseKey: PropTypes.string.isRequired,
//         expirationDate: PropTypes.string,
//         modules: PropTypes.string,
//         features: PropTypes.string,
//       }),
//     ).isRequired,
//   }).isRequired,
// };

export default function CollapsibleTable({title, id}) {
    const [value, setValue] = useState('1');
    const [subscriptions, setSubscriptions] = useState([]);
    const [clinics, setClinics] = useState([]);
    const loginServerURL = useLoginServer();
    const {currentToken} = useAuthContext();
    const [organizationUsers, setOrganizationUsers] = useState([]);
    const [organization, setOrganization] = useState(OrganizationData);
    const [rows, setRows] = useState([]);
    const [skip, setSkip] = useState(0);
    const session = useSession();
    const [edit, setEdit] = useState(false)
    const [view, setView] = useState(false)
    const [del, setDel] = useState(false)
    const [sid, setSid] = useState("");
    const [newUser, setNewUser] = useState(false);
    const [editUser, setEditUser] = useState(false);
    const [uid, setUid] = useState("");
    const [refresh, setRefresh] = useState(false);
    const [licenceUsage, setLicenceUsage] = useState(false);
    const [rowId, setRowId] = useState("");

    // console.log("clinics: ",clinics);
    // console.log("subscriptions: ", subscriptions);
    // console.log("Rows: ", rows);
console.log("Organization CalTable: ",organization);
    useEffect (() => {
      try { 
        const sqlCommand = loginServerURL + "/tables/organization/"+id;
        console.log(sqlCommand);
        fetch(sqlCommand, {
            method: 'GET',
            headers:{
                "X-ZUMO-AUTH": currentToken,
                "Content-type":"application/json"
            }
        }).then ((response) => {
          if(response.status>= 200 && response.status < 300){
            return response.json();
          } else {
            throw new Error(response.status);
          }
        })
        .then ((data) => {
            console.log("clinics fetched: ",data.queryable[0]);
            setClinics(data.queryable[0].clinics);
            getClinicData(data.queryable[0].clinics);
            setOrganizationUsers(data.queryable[0].organizationUsers)
            setOrganization(data.queryable[0]);
            console.log(data.queryable[0].clinics);
        }).catch ((error) => {
            console.log(error);
        });  
    } catch(err) {
        console.log(err);
    }
  },[refresh]);

    useEffect (() => {
          console.log("UseEffect Session: ",session);
          let queryString = `?$filter=((id ne null) and (updatedAt ge 1970-01-01T20:00:00Z))&$orderby=updatedAt&$skip=${skip}&top=1000&search=${"*"}`
          console.log("queryData: ",queryString)
        try { 
          fetch(session.subscriptionServer + "/tables/subscription/" + queryString, {
              method: 'GET',
              headers:{"Content-type":"application/json"}
          }).then ((response) => response.json())
          .then ((data) => {
            const newRows = data.filter(row => row.organizationId === id);
            console.log("NewRows: ", newRows)
            setSubscriptions(subscriptions.concat(newRows));
            setSkip(skip + data.length);
          }).catch ((error) => {
              console.log(error);
          });  
        } catch(err) {
          console.log(err);
        }
    },[skip]);

  const getClinicData = (data) => {
    setRows(data.map(el =>{ 
      if (el.addresses[0] === undefined) {
        return({id:el.id, name:el.name, location:"", subscriptions:el.subscriptions})
      } else {
        return({id:el.id, name:el.name, location:el.addresses[0].value, subscriptions:el.subscriptions})
      }
     }));
    }
 
    const handleSaveSSOSettings = (value, id) => {
      console.log("handleSaveOrganizationInformation: ", value, id)
      switch(id) {
        case "ssoProvider":
            let r = {...organization, ssoProvider: value};
            setOrganization(r);
            break;
        case "ssoProviderSettingsBlob":
            let s = {...organization, ssoProviderSettingsBlob: JSON.stringify(value)};
            setOrganization(s);
            break;
            default:
                console.log("None: ",id, value);
                break;
      }
    }

    const handleOnSave = () => {
      console.log("handleOnSave: ", organization)
      var org = organization;
      org.clinics = [];

      try {
        fetch(loginServerURL + "/tables/organization/" + org.id, { 
          method: 'PATCH',
          body: JSON.stringify(org),
          headers:{
            "X-ZUMO-AUTH": currentToken,
            "Content-type":"application/json"
          }
        }).then ((response) => {

          if (response.status>=200 && response.status <300) {
            return response.json();
          } else {
            throw new Error(response.status)
          }
        })
        .then ((data) => {
          setOrganization(data);
          console.log("Updated: ",data);
        }).catch ((error) => {
          console.log(error);
          throw new Error(error);
        });
      } catch(err) {
        console.log(err);
        throw new Error(err);
      }
    }

  const actionColumn = [
    {
      field: "action",
      headerName: "Action",
      width: 200,
      renderCell: (params) => {
        return (
            <div className="cellAction">
              <DashboardCustomizeOutlinedIcon style={{color:"gray", cursor: "pointer"}} onClick={() => handleLicenceUsage(params.row.id)}>Dashboard</DashboardCustomizeOutlinedIcon>
              <RemoveRedEyeOutlinedIcon style={{color:"green", cursor: "pointer"}} onClick={() => handleView(params.row.id)}>View</RemoveRedEyeOutlinedIcon>
              <ModeEditOutlineOutlinedIcon style={{color:"DodgerBlue", cursor: "pointer"}} onClick={() => handleEdit(params.row.id)}>Edit</ModeEditOutlineOutlinedIcon>
              <DeleteOutlineOutlinedIcon style={{color:"red", cursor: "pointer"}} onClick={() => handleDelete(params.row.id)}>Delete</DeleteOutlineOutlinedIcon>
            </div>
          );
        },
      },
    ];
    
    const userActionColumn = [
      {
        field: "action",
        headerName: "Action",
        width: 100,
        renderCell: (params) => {
          return (
            <div className="cellAction">
                {/* <div className="editButton" onClick={() => handleUserEdit(params.row.id)}>Edit</div> */}
                <ModeEditOutlineOutlinedIcon style={{color:"DodgerBlue", cursor: "pointer"}} onClick={() => handleUserEdit(params.row.id)}>Edit</ModeEditOutlineOutlinedIcon>
                
            </div>
          );
        },
      },
    ];
    
    const handleLicenceUsage = (id) => {
      setLicenceUsage(true);
      setRowId(id);
    }

    const handleChange = (e, newValue) => {
        setValue(newValue);
      }

    const handleEdit = async (id) =>{
      setEdit(true);
      setSid(id);
      
    }
    const handleUserEdit = async (id) =>{
      setEditUser(true);
      setUid(id);
      
    }
    const handleView = (id) => {
      setView(true);
      setSid(id);
    }
  
    const handleDelete = (id) => {
      setDel(true);
      setSid(id);
    }

    const handleCloseEditDialog = () =>{
      setEdit(false);
    }
    const handleCloseViewDialog = () =>{
      setView(false);
    }
    const handleCloseDeleteDialog = () =>{
      setDel(false);
    }

    const handleCloseLicenceUsageDialog = () => {
      setLicenceUsage(false);
    }

    const handleRemoveFromRows = (id) =>{
      const newList = subscriptions.filter((r) => r.id !== id );
      setSubscriptions(newList);
    }

    const handleOpenNewOrganizationUserDialog = () => {
      setNewUser(true);

    }

    const handleCloseNewOrganizationUserDialog = () => {
      setNewUser(false);
    }

    const handleSaveOrganizationUser = (row) =>{
      setRefresh(!refresh);

    }
    const handleUpdateOrganizationUser = (r) =>{
      console.log("handleUpdateRows: ", r)
      let i =0;
      let j = 0;
      organizationUsers.forEach((el) => {
          if (el.id === r.id) {
              j=i;
          }
          i++;
      })
      const newList = [...organizationUsers];
      newList[j]=r;
      setOrganizationUsers(newList);
    }

    const handleCloseOrganizationEditUserDialog = () => {
      setEditUser(false);
    }

    const handleUpdateRows = (r) =>{
      console.log("handleUpdateRows: ", r)
        let i =0;
        let j = 0;
        subscriptions.forEach((el) => {
            if (el.id === r.id) {
                j=i;
            }
            i++;
        })
        const newList = [...subscriptions];
        newList[j]=r;
        setSubscriptions(newList);
    }

  return (
    <box>
      <EditLicenceDialog id={sid} handleClose = {handleCloseEditDialog} open={edit} handleUpdateRows={handleUpdateRows}/>
      <ViewLicenceDialog id={sid} handleClose = {handleCloseViewDialog} open={view} />
      <DeleteLicenceDialog id={sid} handleClose = {handleCloseDeleteDialog} open={del} handleDeleteRow={handleRemoveFromRows} />
      <NewOrganizationUserDialog handleClose={handleCloseNewOrganizationUserDialog} open={newUser} handleSave={handleSaveOrganizationUser} organizationId={id}/>
      <EditOrganizationUserDialog id ={uid} handleClose={handleCloseOrganizationEditUserDialog} open={editUser} handleUpdate={handleUpdateOrganizationUser} organizationId={id} />
      <LicenceUsageDialog id={rowId} handleClose = {handleCloseLicenceUsageDialog} open={licenceUsage} />      

            <Box sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'flex', height: 224 }}>
        <TabContext value={value} >
            {/* <Box sx={{ borderBottom: 1, borderColor: 'divider' }}> */}
    
                <Tabs 
                    value={value} 
                    fullWidth
                    orientation="vertical"
                    variant="scrollable"
                    scrollButtons={false}
                    onChange={handleChange} 
                    sx={{ borderRight: 1, borderColor: 'divider' }}
                 >
                <Tab label="Clinics" value="1" />
                <Tab label="Subscriptions" value="2" />
                <Tab label="Organization Users" value="3" />
                <Tab label="SSO Settings" value="4" />
                </Tabs>
            {/* </Box> */}
            <TabPanel value='1'>
                    <TableContainer component={Paper} >
                    <Table aria-label="collapsible table" size="small" >
                        <TableHead >
                        <TableRow style={{backgroundColor:"lightgrey"}}>
                            <TableCell />
                            <TableCell><Typography variant="h6">Clinic Id</Typography></TableCell>
                            <TableCell><Typography variant="h6">Clinic Name</Typography></TableCell>
                            <TableCell><Typography variant="h6">Location</Typography></TableCell>
                        </TableRow>
                        </TableHead>
                        <TableBody>
                       
                        {rows.map((row) => (
                            <Row key={row.id} row={row} />
                        ))}
                        
                        </TableBody>
                    </Table>
                    </TableContainer>
            </TabPanel>
            <TabPanel value='2'>
            <div className="datatable" style={{height:790, width:980, marginLeft:-30, marginRight:-50}}>
                <Box
                    sx={{
                      height: 760,
                      '& .super-app-theme--Available': {
                        bgcolor: (theme) =>
                          getBackgroundColor(theme.palette.info.light, theme.palette.mode),
                        '&:hover': {
                          bgcolor: (theme) =>
                            getHoverBackgroundColor(theme.palette.info.light, theme.palette.mode),
                        },
                      },
                      '& .super-app-theme--Assigned': {
                        bgcolor: (theme) =>
                          getBackgroundColor(theme.palette.success.light, theme.palette.mode),
                        '&:hover': {
                          bgcolor: (theme) =>
                            getHoverBackgroundColor(
                              theme.palette.success.main,
                              theme.palette.mode,
                            ),
                        },
                      },
                      '& .super-app-theme--Renew': {
                        bgcolor: (theme) =>
                          getBackgroundColor(theme.palette.warning.light, theme.palette.mode),
                        '&:hover': {
                          bgcolor: (theme) =>
                            getHoverBackgroundColor(
                              theme.palette.warning.light,
                              theme.palette.mode,
                            ),
                        },
                      },
                      '& .super-app-theme--Expired': {
                        bgcolor: (theme) =>
                          getBackgroundColor(theme.palette.error.light, theme.palette.mode),
                        '&:hover': {
                          bgcolor: (theme) =>
                            getHoverBackgroundColor(theme.palette.error.light, theme.palette.mode),
                        },
                      },
                    }}
                  >

                  <DataGrid
                      className="datagrid"
                      rows={subscriptions}
                      columns={LicenceColumns.concat(actionColumn)}
                      pageSize={18}
                      rowsPerPageOptions={[18]}
                      density='compact'
                      getRowClassName={params => getRowFormat(params.row)}
                      slots={{
                        toolbar: CustomToolbar,
                      }}
                  />
                </Box>

              </div>
            </TabPanel>
            <TabPanel value='3'>
              <div className="datatable" >
                  <div className="datatableTitle">
                    Organization User List
                      {/* <div className='link' onClick={handleOpenNewOrganizationUserDialog}>New User</div> */}
                      <AddCircleOutlineOutlinedIcon style={{color:"green", cursor: "pointer"}} onClick={handleOpenNewOrganizationUserDialog}>Add Organization User</AddCircleOutlineOutlinedIcon>
                  </div>
                  <div className="datatable" style={{height:660, width:980,marginLeft:-50, marginRight:-50}}>
                        <DataGrid
                            className="datagrid"
                            rows={organizationUsers}
                            columns={OrganizationUserColumns.concat(userActionColumn)}
                            pageSize={20}
                            rowsPerPageOptions={[20]}
                            density='compact'
                        />
                    </div>
                </div>
            </TabPanel>
            <TabPanel value='4'>
              <div>
                   
                  <OrganizationSettings
                    data = {(organization !== undefined ? organization : OrganizationData)}
                    onFieldChange={(value, id) => handleSaveSSOSettings(value, id)}
                    onSave={() => handleOnSave()}
                  />
                </div>
            </TabPanel>
        </TabContext>
        </Box>
        </box>
    // </>
  );
}
