import * as React from 'react';
import TextField from '@mui/material/TextField';
import ListSelection from "../Licences//ListSelection";
import { CSVString2Array } from '../../Utilities/Utilities';

 const  FlexLicenceTemplateInformation = ({data, onFieldChange, modules, features}) => {    

    if (data.name !== undefined) {
    return (
        <div>

           
            <div>
                <TextField
                    fullWidth
                    id="name" 
                    label="Flex Licence Template Name" 
                    variant="outlined"
                    className="TextField"
                    value={data.name}
                    required
                    onChange={(event, value) => onFieldChange(event, event.target.value, event.target.id)}
                    InputLabelProps={{
                        shrink: true,
                      }}
                />
            </div>
                   
            <div>
                <ListSelection 
                baseList={modules} 
                selectedList={CSVString2Array(data.modules).sort((a,b) => a > b ? 1 : -1)} 
                title={"Modules"} 
                id = "modules" 
                onSelectionChange={(event,value, id) => onFieldChange(event, value, id)} 
                />
            </div>
            <div>
                <ListSelection 
                baseList={features} 
                selectedList={CSVString2Array(data.features).sort((a,b) => a > b ? 1 : -1)} 
                title={"Features"} 
                id="features" 
                onSelectionChange={(event,value, id) => onFieldChange(event, value, id)} 
                />
            </div>
    
             
        </div>
    );
    } else {
        return (<label>Loading...</label>)
    }
}

export default FlexLicenceTemplateInformation;