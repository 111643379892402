
import "./Chart";
import "../widget/Widget";
import { formatRFC3339, subMonths } from "date-fns";
import { BarChart, CartesianGrid, XAxis, YAxis,Tooltip,Legend, Bar } from "recharts";
import { useEffect, useState } from "react";


const MonthlyRenewalsChart = ({rows, handleOnGraphClick}) =>{
    const [data, setData] = useState([]);

    
    useEffect (() => {
        
        var d = new Date();
        // console.log("Date d: ",formatRFC3339(d));
        var d0 = new Date(d.getFullYear(), (d.getMonth()), 1,12,0,0);
        var d1 = subMonths(d0,1);
        var d2 = subMonths(d0,2);
        var d3 = subMonths(d0,3);
        var d4 = subMonths(d0,4);
        var d5 = subMonths(d0,5);
        var d6 = subMonths(d0,6);
        var d7 = subMonths(d0,7);
        var d8 = subMonths(d0,8);
        var d9 = subMonths(d0,9);
        var d10 = subMonths(d0,10);
        var d11 = subMonths(d0,11);
        
        setData( [
            { month: d11.getFullYear()+"-"+ (d11.getMonth()+1), 
            amount: (rows.filter(row => {
                return row.updatedAt >= formatRFC3339(d11) && row.updatedAt < formatRFC3339(d10) ;
                })).length 
        },
            { month: d10.getFullYear()+"-"+ (d10.getMonth()+1), 
            amount: (rows.filter(row => {
                return row.updatedAt >= formatRFC3339(d10) && row.updatedAt < formatRFC3339(d9) ;
                })).length
        },
            { month: d9.getFullYear()+"-"+ (d9.getMonth()+1), 
            amount: (rows.filter(row => {
                return row.updatedAt >= formatRFC3339(d9) && row.updatedAt < formatRFC3339(d8) ;
                })).length
         },
            { month: d8.getFullYear()+"-"+ (d8.getMonth()+1), 
            amount: (rows.filter(row => {
                return row.updatedAt >= formatRFC3339(d8) && row.updatedAt < formatRFC3339(d7) ;
                })).length
        },
            { month: d7.getFullYear()+"-"+ (d7.getMonth()+1), 
            amount: (rows.filter(row => {
                return row.updatedAt >= formatRFC3339(d7) && row.updatedAt < formatRFC3339(d6) ;
                })).length 
        },
            { month: d6.getFullYear()+"-"+ (d6.getMonth()+1), 
            amount: (rows.filter(row => {
                return row.updatedAt >= formatRFC3339(d6) && row.updatedAt < formatRFC3339(d5) ;
                })).length
        },
            { month: d5.getFullYear()+"-"+ (d5.getMonth()+1), 
            amount: (rows.filter(row => {
                return row.updatedAt >= formatRFC3339(d5) && row.updatedAt < formatRFC3339(d4) ;
                })).length 
        },
            { month: d4.getFullYear()+"-"+ (d4.getMonth()+1), 
            amount: (rows.filter(row => {
                return row.updatedAt >= formatRFC3339(d4) && row.updatedAt < formatRFC3339(d3) ;
                })).length
        },
            { month: d3.getFullYear()+"-"+ (d3.getMonth()+1),
            amount: (rows.filter(row => {
                return row.updatedAt >= formatRFC3339(d3) && row.updatedAt < formatRFC3339(d2) ;
                })).length 
        },
            { month: d2.getFullYear()+"-"+ (d2.getMonth()+1), 
            amount: (rows.filter(row => {
                return row.updatedAt >= formatRFC3339(d2) && row.updatedAt < formatRFC3339(d1) ;
                })).length
        },
            { month: d1.getFullYear()+"-"+ (d1.getMonth()+1), 
            amount: (rows.filter(row => {
                return row.updatedAt >= formatRFC3339(d1) && row.updatedAt < formatRFC3339(d0) ;
                })).length
        },
            { month: d.getFullYear()+"-"+ (d.getMonth()+1), 
              amount: (rows.filter(row => {
                return row.updatedAt >= formatRFC3339(d0) && row.updatedAt < formatRFC3339(d) ;
                })).length
            }
        ]);
    },[rows])
    const handleOnClick = (e) =>{
        var darr = e.activeLabel.split('-');
        var end = Date.UTC;
        var start = new Date(parseInt(darr[0]), parseInt(darr[1])-1, 1,12,0,0);
        console.log(e.activeLabel);
        if (darr[1] === "12"){
            var end = new Date(parseInt(darr[0])+1, 0, 1,12,0,0);
        } else {
            var end = new Date(parseInt(darr[0]), parseInt(darr[1]), 1,12,0,0);
        }
        console.log([formatRFC3339(start), formatRFC3339(end)])
        handleOnGraphClick(
            rows.filter(row => {
                return row.updatedAt >= formatRFC3339(start) && row.updatedAt < formatRFC3339(end) ;
                })
                ,"for monthly activity period: "+e.activeLabel);
    }
        
    return( 
        <div className="chart">
            <div className="left" >
            <center className="title">Monthly Licence Activities</center>
                <BarChart width={1000} height={180} data={data} onClick={b => handleOnClick(b)}>
                    <CartesianGrid strokeDasharray="1 1"  vertical={false} stroke="gray"/>
                    <XAxis  dataKey="month" />
                    <YAxis  />
                    <Tooltip />
                    <Legend />
                    <Bar dataKey="amount" fill="#009ce0" />
                </BarChart>
            </div>
      </div>
    )
};

export default MonthlyRenewalsChart;