
import "./Chart";
import "../widget/Widget";
import { useState, useEffect } from "react";
import { BarChart, CartesianGrid, XAxis, YAxis,Tooltip,Legend, Bar } from "recharts";
import { features } from "../../data/ControlledLists";
import formatRFC3339 from "date-fns/formatRFC3339";

const LicencePerFeatureChart = ({rows, handleOnGraphClick}) =>{

    const [data, setData] = useState([]);
    const [mod, setMod] = useState(features);

    useEffect (() => {
        var arr = [];
        const currentLicences = rows.filter(row => row.expirationDate >= formatRFC3339(new Date()))
        for (var i = 0; i < mod.length; i++) {
           var featureName = mod[i];
           var filtered = currentLicences.filter(row => {return String(row.features).includes(featureName) ;});
           arr.push({
                        feature:featureName,
                        amount: filtered.length
           })
           
        }
        setData(arr);
    },[rows])

    const handleOnClick = (e) =>{
        const currentLicences = rows.filter(row => row.expirationDate >= formatRFC3339(new Date()))
        const filtered = currentLicences.filter(row => {return String(row.features).includes(e.activeLabel) ;});
        handleOnGraphClick(filtered,"for feature: "+e.activeLabel);
    }

    return( 
        <div className="chart" >
            <center className="title">Licence Per Feature</center>
                <BarChart width={350} height={500} data={data} layout="vertical" margin={{ top: 5, right: 0, left: 100, bottom: 5  }} onClick={bob => handleOnClick(bob)}>
                    <CartesianGrid strokeDasharray="1 1" horizontal={false} stroke="gray"/>
                    <XAxis  type="number"/>
                    <YAxis type="category" dataKey="feature"  interval={0}  />
                    <Tooltip />
                    <Legend  />
                    <Bar dataKey="amount" fill="#009ce0" />
                </BarChart>
      </div>
    )
};

export default LicencePerFeatureChart;