import CheckBoxOutlineBlankOutlinedIcon from '@mui/icons-material/CheckBoxOutlineBlankOutlined';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';

import {modules} from './data/ControlledLists';



const modulesList = () => {
  return ["All",...modules];
}

      
export const userColumns = [
  { field: "id", headerName: "ID", width: 70 },
  {
    field: "user",
    headerName: "User",
    width: 230,
    renderCell: (params) => {
      return (
        <div className="cellWithImg">
          <img className="cellImg" src={params.row.img} alt="avatar" />
          {params.row.username}
        </div>
      );
    },
  },
  {
    field: "email",
    headerName: "Email",
    width: 230,
  },

  {
    field: "address",
    headerName: "Address",
    width: 100,
  },
  {
    field: "status",
    headerName: "Status",
    width: 160,
    renderCell: (params) => {
      return (
        <div className={`cellWithStatus ${params.row.status}`}>
          {params.row.status}
        </div>
      );
    },
  },
];

export const LicenceColumns = [
  { field: "id", headerName: "ID", width: 300 },
  { field: "clinicId", headerName: "Clinic Id", width: 320, },
  { field: "licenseKey", headerName: "Licence Key", width: 320, },
  { field: "purchaseDate", headerName: "Purchase Date", width: 150,
    valueGetter: (value) => {
      var date = new Date(value);
      console.log(date);
      var day = date.getDate();
      var month = date.getMonth() + 1;
      var year = date.getFullYear();
      return year + "-" + month.toString().padStart(2,"0") + "-" + day.toString().padStart(2,"0");
      } 
  },
  { field: "expirationDate", headerName: "Expiration Date", width: 150, 
    valueGetter: (value) => { 
        var date = new Date(value);
        var day = date.getDate();
        var month = date.getMonth() + 1;
        var year = date.getFullYear();
        return year + "-" + month.toString().padStart(2,"0") + "-" + day.toString().padStart(2,"0");
      }
  },
  { field: "purchaseName", headerName: "Purchase Name", width: 200 },
  { field: "purchaseEmail", headerName: "Purchase Email", width: 200, },
  { field: "amount", headerName: "# Seat", width: 100, },
  { field: "application", headerName: "App", width: 100 },
  { field: "name", headerName: "Name", width: 200, },
  { field: "activationCount", headerName: "Activation Count", width: 100 },
  { field: "description", headerName: "Regional Server", width: 250 },
  { field: "salesPersonEmail", headerName: "Kinetisense Sales Contact Email", width: 250 },
  { field: "modules", headerName: "Modules", width: 250 },
  { field: "features", headerName: "Features", width: 250 },
  { 
    field: "isTestLicence", 
    headerName: "Test", 
    width: 50,
    renderCell: (params) => {
      return (
      <div className='cellCenter' >
          {!params.row.isTestLicence && <CheckBoxOutlineBlankOutlinedIcon/>}
          {params.row.isTestLicence && <CheckBoxOutlinedIcon/>}
      </div>
      );
  } },
  { field: "maxNumberOfDevices", headerName: "Max # dev", width: 100 },
  { field: "deviceIdList", headerName: "DeviceId List", width: 250 },
  { field: "readOnlyDevices", headerName: "Read-Only Devices", width: 250 },
  { field: "notes", headerName: "Notes", width: 300 },
];

export const ShortLicenceColumns = [
  { field: "licenseKey", headerName: "Licence Key", width: 320, },
  { field: "purchaseDate", headerName: "Purchase Date", width: 150, },
  { field: "expirationDate", headerName: "Expiration Date", width: 150 },
  { field: "amount", headerName: "# Seat", width: 100, },
  { field: "name", headerName: "Name", width: 200, },
  { field: "modules", headerName: "Modules", width: 250 },
  { field: "features", headerName: "Features", width: 250 },
  { field: "maxNumberOfDevices", headerName: "Max # dev", width: 100 },
];

export const AccountColumns = [
  // { field: "id", headerName: "ID", width: 0 },
  { field: "username", headerName: "UserName", width: 200 },
  { field: "email", headerName: "Email", width: 200 },
  { field: "name", headerName: "Name", width: 200 },
  { field: "app", headerName: "App", width: 60 },
  { field: "isAdministrator", 
    headerName: "IsAdministrator", 
    width: 100, 
    renderCell: (params) => {
        return (
        <div className='cellCenter'>
            {!params.row.isAdministrator && <CheckBoxOutlineBlankOutlinedIcon/>}
            {params.row.isAdministrator && <CheckBoxOutlinedIcon/>}
        </div>
        );
      }
  },
  { 
    field: "isOperator", 
    headerName: "IsOperator", 
    width: 100,
      renderCell: (params) => {
        return (
        <div className='cellCenter'>
            {!params.row.isOperator && <CheckBoxOutlineBlankOutlinedIcon/>}
            {params.row.isOperator && <CheckBoxOutlinedIcon/>}
        </div>
        );
      }
  },
  { 
    field: "isActive", 
    headerName: "IsActive", 
    width: 100,
    renderCell: (params) => {
      return (
      <div className='cellCenter' >
          {!params.row.isActive && <CheckBoxOutlineBlankOutlinedIcon/>}
          {params.row.isActive && <CheckBoxOutlinedIcon/>}
      </div>
      );
  } },
  { field: "updatedAt", headerName: "UpdatedAt", width: 250 }
]
export const OrganizationColumns = [
  // { field: "id", headerName: "ID", width: 0 },
  { field: "name", headerName: "Organization Name", width: 300 },
  { field: "theme", headerName: "Theme", width: 200 },
  { field: "notes", headerName: "Notes", width: 500 }
]
export const OrganizationUserColumns = [
  // { field: "id", headerName: "ID", width: 0 },
  { field: "username", headerName: "Username", width: 250 },
  { field: "name", headerName: "Name", width: 250 },
  { field: "MiddleName", headerName: "Middle", width: 50 },
  { field: "surname", headerName: "Surname", width: 250 },
  { field: "email", headerName: "email Address", width: 250 },
  { field: "phone", headerName: "Phone Number", width: 250 },
  { field: "roles", headerName: "Roles", width: 250 },
  { field: "notes", headerName: "Notes", width: 200 },
  { field: "isActive", headerName: "Active", width: 75 }
]

export const SyncConfigurationColumns = [
  { field: "tablename", headerName: "Tablename", width: 200 },
  { field: "serverURL", headerName: "Server", width: 300 },
  { field: "isSuccessful", headerName: "Is Successful", width: 250 },
  { field: "lastUpdate", headerName: "Last Update", width: 250 }
]

export const ExerciseColumns = [
  { field: 'execiseName',
    headerName: 'Exercise Name',
    width: 350,
    valueGetter: (value, row) => {
      try{
        // var exercise = row;
        if(row !== null && row !== undefined){
            return row.exerciseTexts.filter(element => element.languageCode === row.primaryLanguage)[0].name;
        }
        else {
          return "Unknown";
        }
      } catch (err) {
        return "Not found";
      }
    }
  },
  { field: "equipmentNeeded", headerName: "Equipment Needed", width: 200 },
  { field: "market", headerName: "Market", width: 100 },
  { field: "group", headerName: "Group", width: 100 },
]

export const ExerciseTextColumns = [
  
  { field: "languageCode", headerName: "LC", width: 70 },
  { field: "name", headerName: "Name", width: 300 },
  { field: "instructions", headerName: "Instructions", width: 350 },
]

export const EquipmentColumns = [
  { field: "id", headerName: "Code", width: 150 },
  { field: 'name',
    headerName: 'Name',
    width: 350,
    valueGetter: (value, row) => {
      try{
        return row.equipmentTexts.filter(element => element.languageCode === "EN-US")[0].text;
      } catch (err) {
        return "Not found";
      }
    }
  },
]

export const CorrectiveColumns = [
  { field: "joint", headerName: "Joint", width: 120 },
  { field: "isMobility", headerName: "Mobility", type:'boolean',width: 80 },
  { field: "isStability", headerName: "Stability", type:'boolean', width: 80 },
  { field: "level", headerName: "Level", width: 70 },
  { field: 'execiseName',
        headerName: 'Exercise Name',
        width: 350,
        valueGetter: (value, row) => {
         
          try{
                var exercise = row.exercise;
                if(exercise !== null && exercise !== undefined){
                    return row.exercise.exerciseTexts.filter(element => element.languageCode === row.exercise.primaryLanguage)[0].name;
                }
                else {
                  return "Unknown";
                }
              } catch (err) {
                return "Not found";
              }
          }
  },
  { field: 'market',
        headerName: 'Market',
        width: 100,
        valueGetter: (value, row) => {
          try {
            return row.exercise.market;
          } catch (err) {
            return "Not found";
          }
        } 
  },
  { field: 'group',
        headerName: 'Group',
        width: 100,
        valueGetter: (value, row) => {
          try {
            return row.exercise.group;
          } catch (err) {
            return "Not found";
          }
        }
  },
]

export const ExerciseLookupColumns = [
  { field: 'execiseName',
        headerName: 'Exercise Name',
        width: 350,
        valueGetter: (value, row) => {
          try{
            var exercise = row.exercise;
            if(exercise !== null && exercise !== undefined){
                return row.exercise.exerciseTexts.filter(element => element.languageCode === row.exercise.primaryLanguage)[0].name;
            }
            else {
              return "Unknown";
            }
          } catch (err) {
            return "Not found";
          }
          }
  },
  { field: 'market',
        headerName: 'Market',
        width: 100,
        valueGetter: (value, row) => {
          try {
            return row.exercise.market;
          } catch (err) {
            return "Not found";
          }
        } 
  },
  { field: 'group',
        headerName: 'Group',
        width: 100,
        valueGetter: (value, row) => {
          try {
            return row.exercise.group;
          } catch (err) {
            return "Not found";
          }
        }
  },
]

export const CustomFieldsMetaDataListColumns = [
  { field: "featureName", headerName: "Feature Name", width: 200 },
  { field: "metaData", headerName: "MetaData", width: 600 },
]

export const CustomFieldsMetaDataColumns = [
  { field: "type", 
    headerName: "Type", 
    minWidth: 100, 
    type:'singleSelect', 
    valueOptions: ['ControlledList','Text', 'Notes', 'Decimal', 'Integer', 'Boolean', 'Date'],
    editable: true
  },
  { field: "order", headerName: "Order", minWidth: 70, type:'number' ,editable: true},
  { field: "groupName", headerName: "Group Name", type:'string', minWidth: 180 ,editable: true},
  { field: "assessmentType", 
    headerName: "Assessment Type", 
    type:'singleSelect', 
    minWidth: 150 ,
    valueOptions: modulesList(),
    editable: true
  },
  { field: "name", headerName: "Field Name", type:'string', minWidth: 150, editable: true },
  { field: "label", headerName: "Label", type:'string', minWidth: 200, editable: true },
  { field: "toolTip", headerName: "ToolTip", minWidth: 200, type:'string', editable: true },
  { field: "min", headerName: "Min", type:'number', minWidth: 70, editable: true },
  { field: "max", headerName: "Max", type:'number', minWidth: 70,editable: true },
  { field: "nbOfLines", headerName: "NbLines", type:'number', minWidth: 80, editable: true },
  { field: "options", headerName: "OptionList", type:'string', minWidth: 350,editable: true },
  { field: "isValidated", headerName: "Validate", minWidth: 100, type:'boolean',editable: true },
  { field: "isMandatory", headerName: "Mandatory", minWidth: 100, type:'boolean',editable: true },
  { field: "isMultiSelect", headerName: "MultiSelect", type:'boolean', minWidth: 100,editable: true },
  { field: "dateFormat", headerName: "Date Format", minWidth: 120, type:'singleSelect', valueOptions: ['MM/dd/yyyy', 'yyyy/MM/dd','dd/MM/yyyy'],editable: true },
  { field: "isConditional", headerName: "Cond Field", type:'boolean', minWidth: 100,editable: true },
  { field: "conditionalField", 
    headerName: "Cond Fieldname", 
    type:'singleSelect',
    valueOptions: [],
    minWidth: 150,
    editable: true 
  },
  { field: "condition", 
    headerName: "Condition", 
    minWidth: 150, 
    type:'singleSelect', 
    valueOptions: [">", ">=", "<", "<=", "=", "!=", "Contains", "!Contains"],
    editable: true
  },
  { field: "conditionalValue", headerName: "Cond Value", type:'string', minWidth: 150,editable: true },
  { field: "conditionalStatus", 
    headerName: "Status", 
    minWidth: 100, 
    type:'singleSelect', 
    valueOptions: ["Disabled","Hidden"],
    editable: true
  },
]

export const FlexLicenceTemplateColumns = [
  { field: "name", headerName: "Name", width: 300, },
  { field: "modules", headerName: "Modules", width: 300, },
  { field: "features", headerName: "Features", width: 300 },
];

