//import "./licencelist.scss"
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import LicenceCreateTable from "../../components/datatable/LicenceCreateTable";


const LicenceNew = (props) => {
  return (
    <div className="home">
      <Sidebar/>
      <div className="homeContainer">
        <Navbar/>
        <LicenceCreateTable />
      </div>
    </div>
  )
}

export default LicenceNew