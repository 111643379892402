import "./Chart";
import "../widget/Widget";
import {addMonths, formatRFC3339} from "date-fns";

import { BarChart, CartesianGrid, XAxis, YAxis,Tooltip,Legend, Bar, ResponsiveContainer } from "recharts";

const LicenceRenewalsChart = ({rows, handleOnGraphClick}) =>{


    const licence30 =  rows.filter(row => {
        return row.expirationDate >= formatRFC3339(new Date()) && row.expirationDate < formatRFC3339(addMonths(new Date(),1));
    });

    const licence60 = rows.filter(row => {
        return row.expirationDate >= formatRFC3339(addMonths(new Date(),1)) && row.expirationDate < formatRFC3339(addMonths(new Date(),2));
    });

    const licence90 = rows.filter(row => {
        return row.expirationDate >= formatRFC3339(addMonths(new Date(),2)) && row.expirationDate < formatRFC3339(addMonths(new Date(),3));
    });

    const data = [
        { name: "0-30", licences: licence30.length },
        { name: "30-60", licences: licence60.length },
        { name: "60-90", licences: licence90.length },
      ];

    const handleOnClick = (e) => {
        if (e.activeLabel === "0-30") {
            handleOnGraphClick(rows.filter(row => {
                return row.expirationDate >= formatRFC3339(new Date()) && row.expirationDate < formatRFC3339(addMonths(new Date(),1));
            }),"for renewal period: " + e.activeLabel);
        } else if (e.activeLabel === "30-60") {
            handleOnGraphClick(rows.filter(row => {
                return row.expirationDate >= formatRFC3339(addMonths(new Date(),1)) && row.expirationDate < formatRFC3339(addMonths(new Date(),2));
            }),"for renewal period: " + e.activeLabel);
        } else if (e.activeLabel === "60-90") {
            handleOnGraphClick(rows.filter(row => {
                return row.expirationDate >= formatRFC3339(addMonths(new Date(),2)) && row.expirationDate < formatRFC3339(addMonths(new Date(),3));
            }),"for renewal period: " + e.activeLabel);
        }
        
    }      

    return( 
        <div className="widget">
            <div className="left" >
            <center className="title">Incoming Licence Renewals</center>
                <center>
                <BarChart width={300} height={180} data={data} onClick={b => handleOnClick(b)}>
                    <CartesianGrid strokeDasharray="1 1"  vertical={false} stroke="gray"/>
                    <XAxis dataKey="name" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Bar dataKey="licences" fill="green" />
                </BarChart>
                </center>
            </div>
      </div>
    )
};

export default LicenceRenewalsChart;