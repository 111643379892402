import "./datatable.scss";
import "./licenceDataTable.scss";

import { DataGrid } from "@mui/x-data-grid";
import { ExerciseColumns } from "../../datatablesource";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import ViewExerciseDialog  from "../dialogs/ViewExerciseDialog";
import EditExerciseDialog from "../dialogs/EditExerciseDialog";
import DeleteExerciseDialog from "../dialogs/DeleteExerciseDialog";
import { RotatingLines } from "react-loader-spinner";
import { useLoginServer } from "../../context/LoginServerContext";
import { useAuthContext } from "../../context/AuthContext";
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import NewExerciseDialog from "../dialogs/NewExerciseDialog";
import PlayCircleFilledWhiteOutlinedIcon from '@mui/icons-material/PlayCircleFilledWhiteOutlined';


const ExerciseDataTable = (props) => {

    const [rows, setRows] = useState([]);
    const [id, setId] = useState();
    const [edit, setEdit] = useState(false);
    const [view, setView] = useState(false);
    const [del, setDelete] = useState(false);
    const [add, setAdd] = useState(false);
    const [searchString, setSearchString] = useState("");
    const [skip, setSkip] = useState(0);
    const [isLoading, setLoading] = useState(true);
    const[refresh, setRefresh] = useState(false);

    const loginServerURL = useLoginServer();
    const {currentToken} = useAuthContext();

    const languageList = [{id:"EN", name:"English"}, {id:"FR", name:"French"}, {id:"ES", name:"Spanish"}, {id:"DE", name:"German"}, {id:"PT", name:"Portuguese"}, {id:"RU", name:"Russian"}, {id:"ZH", name:"Chinese"}, {id:"JA", name:"Japanese"}]
    
    // console.log("ROWS: ", rows)
    // console.log("Id: ",id);
    // console.log("Search Flag: ", search)
    useEffect (() => {
            let queryString = `?$filter=((id ne null) and (updatedAt ge 1970-01-01T20:00:00Z))&$orderby=updatedAt&$skip=${skip}&top=1000`
            console.log("queryData: ",queryString)
          try { 
            fetch(loginServerURL + "/tables/exercise" + queryString, {
                method: 'GET',
                headers:{
                    "X-ZUMO-AUTH": currentToken,
                    "Content-type":"application/json"
                }
            }).then ((response) => response.json())
            .then ((data) => {
              setRows(rows.concat(data.filter((r) => r.isDeleted === false)));
              setSkip(skip + data.length);
              if (data.length === 0) {
                setLoading(false);
              }
            }).catch ((error) => {
                console.log(error);
            });  
          } catch(err) {
            console.log(err);
          }
    },[refresh, skip]);

   
    const handleOnSubmit = (event) => {
      event.preventDefault()
      setRows([]);
    //   setSearch(!search);
      setSkip(0);
      setLoading(true);
    }

    const handleAdd = () => {
      setAdd(true);
    }

  const handleCloseEditDialog = () => {
    setEdit(false);
  }
  const handleCloseViewDialog = () => {
    setView(false);
  }
  const handleCloseDeleteDialog = () => {
    setDelete(false);
  }

  const handleCloseAddDialog = () => {
    setAdd(false);
  }

  const handleEdit = async (id) =>{
    setEdit(true);
    setId(id);
    
  }
  const handleView = (id) => {
    setView(true);
    setId(id);
  }

  const handleDelete = (id) => {
    setDelete(true);
    setId(id);
  }

  const actionColumn = [
    {
      field: "action",
      headerName: "Action",
      width: 100,
      renderCell: (params) => {
        return (
          <div className="cellAction">
              <PlayCircleFilledWhiteOutlinedIcon style={{color:"green", cursor: "pointer"}} onClick={() => handlePlayVideo(params.row.videoURL, params.row.group)}>Play</PlayCircleFilledWhiteOutlinedIcon>
              <ModeEditOutlineOutlinedIcon style={{color:"DodgerBlue", cursor: "pointer"}}  onClick={() => handleEdit(params.row.id)}>Edit</ModeEditOutlineOutlinedIcon>
            {/* <DeleteOutlineOutlinedIcon style={{color:"red", cursor: "pointer"}}  onClick={() => handleDelete(params.row.id)}>Delete</DeleteOutlineOutlinedIcon> */}
          </div>
        );
      },
    },
  ];

  const handleRemoveFromRows = (id) =>{
    const newList = rows.filter((r) => r.id !== id );
    setRows(newList);
  }

  const handleUpdateRows = (r) =>{
    console.log("handleUpdateRows: ", r)
      let i =0;
      let j = 0;
      rows.forEach((el) => {
          if (el.id === r.id) {
              j=i;
          }
          i++;
      })
      const newList = [...rows];
      newList[j]=r;
      setRows(newList);
  }

    const filterRows = (rows, sString) => {
        if (sString === '') return rows;
        if (sString === null) return rows;
        if (sString === undefined) return rows;
        if(rows.length === 0) return rows;

        // you need a decoder ring to get the following right (filter a list of objects using the inner objects)
        var list = []; 
        rows.forEach((r) => {
            r.exerciseTexts.forEach((t) => {
                if ( t.languageCode === r.primaryLanguage && t.name.toLowerCase().includes(sString.toLowerCase())) {
                    list.push(r);
                }
            });
        });
        return list;
    }

    const handlePlayVideo = (URL, group) => {
      console.log("Play Video");
      var url = "https://res.cloudinary.com/dkfzgerhr/video/upload/ac_none,c_fill,f_mp4,h_1000,q_auto,vc_h264:baseline,w_1000/Correctives/"+group+"/"+URL;
      window.open(url, '_Video',"resizable=no, toolbar=no, scrollbars=no, menubar=no, status=no, directories=no, width=400, height=400");
  }
   

  return (
    <>
      <EditExerciseDialog id={id} handleClose = {handleCloseEditDialog} open={edit} handleUpdateRows={handleUpdateRows} />
      <NewExerciseDialog handleClose = {handleCloseAddDialog} open={add} handleUpdateRows={handleUpdateRows} />
      <ViewExerciseDialog id={id} handleClose = {handleCloseViewDialog} open={view}  />
      <DeleteExerciseDialog id={id} handleClose = {handleCloseDeleteDialog} open={del} handleDeleteRow={handleRemoveFromRows} />
      
      <form onSubmit={handleOnSubmit} >
        <div className="search">
          <input type="text" 
              value={searchString}
              placeholder="Search..." 
              onChange ={event => setSearchString(event.target.value)}
          />
          {/* <div>
          <button type="button" onClick={handleClearSearchString} className="button">
            <HighlightOffOutlinedIcon />
          </button>
        </div>
        <div>
          <button type="submit" className="button">
            <SearchOutlinedIcon />
          </button>
  </div> */}
      </div> 
    </form>
    { isLoading &&
      <center style={{paddingTop:100}}>
          <RotatingLines  strokeColor="grey"  strokeWidth="2"  animationDuration="1.5"  width="150"  visible={isLoading} />
      </center>
    }
    {!isLoading &&
        <div className="datatable" style={{height:660}}>
          <div className="datatableTitle">
          Exercises
            {/* <AddCircleOutlineOutlinedIcon style={{color:"green", cursor: "pointer"}}  onClick={handleAdd}>
              New Exercise
            </AddCircleOutlineOutlinedIcon> */}
          </div>
            <DataGrid
                className="datagrid"
                rows={filterRows(rows, searchString)} 
                columns={ExerciseColumns.concat(actionColumn)}
                pageSize={15}
                rowsPerPageOptions={[15]}
                //checkboxSelection
                density='compact'
            />
        </div>
    }
    </>
  );
};




export default ExerciseDataTable;
