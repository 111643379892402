export const OrganizationUserData = {
    id:null,
    username: "",
    name: "",
    middleName:"",
    surname: "",
    email: "",
    phones:[],
    roles:"",
    notes:"",
    organizationId:null,
    isActive:true,
    isDeleted:false
}