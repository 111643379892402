import * as React from 'react';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

 const  GoogleOAuthSSO = ({data, onFieldChange}) => {    //  const [row, setRow] = useState(LicenceData);

    console.log("Google data", data)
    if (data.name !== undefined) {
    return (
        <div style={{width:800}}>
            <div>Step 1: In the Google Cloud Console, go to the <i>Credentials page</i>.</div>
            <div>Step 2: Click <b>Create credentials</b> and select <i>OAuth client ID</i>.</div>
            <div>Step 3: Select the <i>Web application application.</i></div>
            <div>Step 4: Enter the following information in the Google Console:</div>
            <div>
                <TextField
                    style={{marginTop: 20}}
                    fullWidth
                    id="name" 
                    label="Name" 
                    variant="outlined"
                    className="TextField"
                    value={data.name}
                    onChange={(event, value) => onFieldChange(event, event.target.value, event.target.id)}
                    InputLabelProps={{
                        shrink: true,
                      }}
                />
            </div>
            <div>Step 5: In the Google Console, add to <b>Authorized JavaScript origins</b> section</div>
            <p/>
            <div>
                <TextField
                    fullWidth
                    style={{marginTop: 20}}
                    id="authorizedOrigin1" 
                    label="Authorized JavaScript URIs 1" 
                    variant="outlined"
                    className="TextField"
                    value={data.authorizedOrigin1}
                    onChange={(event, value) => onFieldChange(event, event.target.value, event.target.id)}
                    InputLabelProps={{
                        shrink: true,
                      }}
                />
            </div>
            {/* <div>
                <TextField
                    fullWidth
                    id="authorizedOrigine2" 
                    label="URIs 2" 
                    variant="outlined"
                    className="TextField"
                    value={data.authorizedOrigin2}
                    onChange={(event, value) => onFieldChange(event, event.target.value, event.target.id)}
                    InputLabelProps={{
                        shrink: true,
                      }}
                />
            </div> */}
            <div>Step 6: In the Google Console, add to <b>Authorized redirect URIs</b> section</div>
              <div>
                <TextField
                    fullWidth
                    style={{marginTop: 20}}
                    id="redirect1" 
                    label="Authorized Redirect URIs 1" 
                    variant="outlined"
                    className="TextField"
                    value={data.redirect1}
                    onChange={(event, value) => onFieldChange(event, event.target.value, event.target.id)}
                    InputLabelProps={{
                        shrink: true,
                      }}
                />
            </div>
            <div>
                <TextField
                    fullWidth
                    id="redirect2" 
                    label="Authorized Redirect URIs 2" 
                    variant="outlined"
                    className="TextField"
                    value={data.redirect2}
                    onChange={(event, value) => onFieldChange(event, event.target.value, event.target.id)}
                    InputLabelProps={{
                        shrink: true,
                      }}
                />
            </div>
           
            <div>Step 7: In the Google Console, click <b>Create</b>. Your <i>Client ID</i> and <i>Client Secret</i> appear.</div>
            <div>Step 8: Enter the <i>Client ID</i> and the <i>Client Secret</i> from the Google Console in the corresponding fields below.</div>
            <p/>
            <div>
                <TextField
                    fullWidth
                    style={{marginTop: 20}}
                    id="clientId" 
                    label="Client ID" 
                    variant="outlined"
                    className="TextField"
                    value={data.clientId}
                    onChange={(event, value) => onFieldChange(event, event.target.value, event.target.id)}
                    InputLabelProps={{
                        shrink: true,
                      }}
                />
            </div>
            <div>
                <TextField
                    fullWidth
                    id="clientSecret" 
                    label="Client Secret" 
                    variant="outlined"
                    className="TextField"
                    value={data.clientSecret}
                    onChange={(event, value) => onFieldChange(event, event.target.value, event.target.id)}
                    InputLabelProps={{
                        shrink: true,
                      }}
                />
            </div>

            <div>Step 9: Enter the <i>Login Endpoint URL</i> to start login with Google</div>
            <p/>
            <div>
                <TextField
                    fullWidth
                    style={{marginTop: 20}}
                    id="googleLoginEndpoint" 
                    label="Google Login Endpoint URL" 
                    variant="outlined"
                    className="TextField"
                    value={data.googleLoginEndpoint}
                    onChange={(event, value) => onFieldChange(event, event.target.value, event.target.id)}
                    InputLabelProps={{
                        shrink: true,
                      }}
                />
            </div>
            
            <div>Step 10: Update the <i>Token Callback URL</i> to point to the proper regional server</div>
              <div>
                <TextField
                    fullWidth
                    style={{marginTop: 20}}
                    id="tokenCallback" 
                    label="Kinetisense Token Callback URL" 
                    variant="outlined"
                    className="TextField"
                    value={data.tokenCallback}
                    onChange={(event, value) => onFieldChange(event, event.target.value, event.target.id)}
                    InputLabelProps={{
                        shrink: true,
                      }}
                />
            </div>
            <div>Step 11: Update the <i>Google API Endpoint</i> from Google. The system uses the this endpoint to contact the google API.</div>
              <div>
                <TextField
                    fullWidth
                    style={{marginTop: 20}}
                    id="googleAPIEndpoint" 
                    label="Google API Endpoint URL" 
                    variant="outlined"
                    className="TextField"
                    value={data.googleAPIEndpoint}
                    onChange={(event, value) => onFieldChange(event, event.target.value, event.target.id)}
                    InputLabelProps={{
                        shrink: true,
                      }}
                />
            </div>
            <div>Step 12: Click <b>Save</b> (below) to save the information in your SSO information in Kinetisense.</div>
        </div>
    );
    } else {
        return (<label>Loading...</label>)
    }
}

export default GoogleOAuthSSO;