const AzureSAMLData = {
    
        "name": "Kinetisense 360",
        "enableUserCreation": false,
        "enableUserOverride": false,
        "basicConfigurationIdentifier": "com.kinetisense-360-practitioner-app.net",
        "basicConfigurationRedirect":"{RegionalServerURL}/app/callback",
        "microsoftEntraIdentifier":"",
        "loginUrl":"",
        "logoutUrl":"",
        "publicCertificate":"",
        "advanceAttributeEmail":"emailaddress",
        "advanceAttributeFirstName":"firstname",
        "advanceAttributeLastName":"lastname"
    }

export default AzureSAMLData;
