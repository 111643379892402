import "./datatable.scss";
import "./licenceDataTable.scss";

import { DataGrid } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { ExerciseLookupColumns } from "../../datatablesource";
import Button from '@mui/material/Button';
import PlayCircleFilledWhiteOutlinedIcon from '@mui/icons-material/PlayCircleFilledWhiteOutlined';

const ExerciseLookupDataTable = ({list, handleExerciseSelected, handleClose, market, group}) => {

    const [rows, setRows] = useState([]);
    const [searchString, setSearchString] = useState("");
    const [selected, setSelected] = useState();
    // console.log("ROWS: ", rows)
   console.log("Selected: ", selected);
   console.log("Market: ", market);
    console.log("Group: ", group);

    useEffect(() => {
      // console.log("USEEFFECT");
      var lista = []; 
      list.forEach((r) => {
        r.exercise.exerciseTexts.forEach((t) => {
          // console.log("market",r.exercise.market, "group", r.exercise.group);
            if ((market === null || market === "" ||  r.exercise.market === market) && (group === null || group === "" || r.exercise.group === group)) {
              if (lista.filter((e) => e.exercise.id === r.exercise.id).length === 0) {
              lista.push(r);
              }
            }
        });
    });
    // console.log("LISTA: ", lista);
        setRows(lista);
    },[] );
    const handleOnSubmit = (event) => {
     
    }

    const actionColumn = [
      {
        field: "action",
        headerName: "Action",
        width: 120,
        renderCell: (params) => {
          return (
            <div className="cellAction">
                <PlayCircleFilledWhiteOutlinedIcon style={{color:"green", cursor: "pointer"}} onClick={() => handlePlayVideo(params.row.exercise.videoURL, params.row.exercise.group)}>Video</PlayCircleFilledWhiteOutlinedIcon>
            </div>
          );
        },
      },
    ];
  
    const handlePlayVideo = (URL, group) => {
      console.log("Play Video");
      var url = "https://res.cloudinary.com/dkfzgerhr/video/upload/ac_none,c_fill,f_mp4,h_1000,q_auto,vc_h264:baseline,w_1000/Correctives/"+group+"/"+URL;
      window.open(url, '_Video',"resizable=no, toolbar=no, scrollbars=no, menubar=no, status=no, directories=no, width=400, height=400");
  }

    const handleOnSelectedClick = (event) => {
      console.log("handleOnSelectedClick");
      console.log("Selected: ", selected);
      handleExerciseSelected(selected);
      handleClose();
    }

    const filterRows = (rows, sString) => {
        if (sString === '') return rows;
        if (sString === null) return rows;
        if (sString === undefined) return rows;
        if(rows.length === 0) return rows;

        // you need a decoder ring to get the following right (filter a list of objects using the inner objects)
        var lista = []; 
        rows.forEach((r) => {
            r.exercise.exerciseTexts.forEach((t) => {
                if ( t.languageCode === r.exercise.primaryLanguage && t.name.toLowerCase().includes(sString.toLowerCase())) {
                  if ((market === null || market === "" ||  r.exercise.market === market) && (group === null || group === "" || r.exercise.group === group)) {
                    lista.push(r);
                  }
                }
            });
        });
        return lista;
    }

  return (
    <>
      <form  >
        <div className="search">
          <input type="text" 
              value={searchString}
              placeholder="Search..." 
              onChange ={event => setSearchString(event.target.value)}
          />
         
        </div> 
      </form>
        <div className="datatable" style={{height:460}}>
            <div className="datatableTitle">
            Exercise 
              
            </div>
              <DataGrid
                  className="datagrid"
                  rows={filterRows(rows, searchString)} 
                  columns={ExerciseLookupColumns.concat(actionColumn)}
                  pageSize={10}
                  rowsPerPageOptions={[10]}
                  density='compact'
                  onRowClick={(row) => {setSelected(row.row)}}
              />
          </div>
          <div className="formButtons" >
              <div className="left">
              </div>
              <div className="right">
                    <Button  
                    style={{marginTop:10}}
                        type="submit"
                        variant="contained"
                        className="button"
                        onClick={(event) => handleOnSelectedClick(event)}
                    >
                        Select
                    </Button>
              </div>
          </div>
    </>
  );
};




export default ExerciseLookupDataTable;
