import {useState, useEffect} from 'react';
import { useSession } from "../../context/SessionContext";
import LicenceData from "../../data/LicenceData";
import LicenceInformation from "./LicenceInformation";
import ListSelection from "./ListSelection";
import  Button  from '@mui/material/Button';
import { features, modules} from "../../data/ControlledLists";
import {CSVString2Array, Array2CSVString} from "../../Utilities/Utilities";
import Box from "@mui/material/Box";
import TabPanel from '@mui/lab/TabPanel';
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import TabContext from "@mui/lab/TabContext";
import { useAuthContext } from '../../context/AuthContext';
import { useLoginServer } from '../../context/LoginServerContext';
import CustomFieldsMetadata from '../../data/CustomFieldsMetadata';


const LicenceEdit = ({id, handleUpdateRows}) => {

    const [value, setValue] = useState('1');
    const [row, setRow] = useState(LicenceData);
    const [organizations, setOrganizations] = useState([]);
    const [errorMessage, setErrorMessage] = useState(false);
    const [savedMessage, setSavedMessage] = useState(false);
    const session = useSession();
    const loginServerURL = useLoginServer();
    const {currentToken} = useAuthContext();
    const [allFeatures, setAllFeatures] = useState([]);
    const [branding, setBranding] = useState([]);
    const [CFFeatures, setCFFeatures] = useState([]);
    const [flexLicenceTemplates, setFlexLicenceTemplates] = useState([]);

    console.log("Main Row: ",row);

    useEffect (() => {
        getBrandingFeatures();
        getAllFeatures();
        getFlexLicenceTemplates();
      try { 
        fetch(session.subscriptionServer + "/tables/subscription/" + id, {
            method: 'GET',
            headers:{"Content-type":"application/json"}
        }).then ((response) => response.json())
        .then ((data) => {
            setRow(data.queryable[0]);
            // console.log("point a");
            getOrganizations();
            // console.log("point b");
        }).catch ((error) => {
            console.log(error);
        });  
      } catch(err) {
        console.log(err);
      }
     
    },[id]);
  
    const getAllFeatures = () => {
        var rows = [CustomFieldsMetadata];
        let queryString = `?$filter=((id ne null) and (updatedAt ge 1970-01-01T20:00:00Z))&$orderby=updatedAt&$skip=0&top=1000`
        fetch(loginServerURL + "/tables/CustomFieldsMetaData" + queryString, {
            method: 'GET',
            headers:{
                "X-ZUMO-AUTH": currentToken,
                "Content-type":"application/json"
            }
        }).then ((response) => response.json())
        .then ((data) => {
            rows = data.filter((r) => r.isDeleted === false);
            var list = [];
            rows.forEach((p) => {
                list.push(p.featureName);
            });
            setCFFeatures(list);
        }).catch ((error) => {
            console.log("MD Error: ",error);
        });  
    }

    function getFlexLicenceTemplates () {
        let queryString = `?$filter=((id ne null) and (updatedAt ge 1970-01-01T20:00:00Z))&$orderby=updatedAt&$skip=0&top=1000`
        console.log("queryData: ",queryString)
      try { 
        fetch(session.subscriptionServer + "/tables/FlexLicenceTemplate/" + queryString, {
            method: 'GET',
            headers:{"Content-type":"application/json"}
        }).then ((response) => response.json())
        .then ((data) => {
          setFlexLicenceTemplates(data);
          
        }).catch ((error) => {
            console.log(error);
        });  
      } catch(err) {
        console.log(err);
      }
    }

    function getBrandingFeatures () {
        fetch(session.subscriptionServer + "/api/GetBrandingList" , {
            method: 'GET',
            headers:{
                "X-ZUMO-AUTH": currentToken,
                "Content-type":"application/json"
            }
        }).then ((response) => response.json())
        .then ((data) => {
            setBranding(data); 
        }).catch ((error) => {
            console.log("BrandingError Error: ",error);
            return [];
        });  
    }

   

    const getOrganizations = () => {
        try { 
            const sqlCommand = loginServerURL + "/api/Organizations/GetOrganizations";
            console.log(sqlCommand);
            fetch(sqlCommand, {
                method: 'GET',
                headers:{
                    "X-ZUMO-AUTH": currentToken,
                    "Content-type":"application/json"
                }
            }).then ((response) => {
              if(response.status>= 200 && response.status < 300){
                return response.json();
              } else {
                throw new Error(response.status);
              }
            })
            .then ((data) => {
                console.log("Organization fetched: ",data);
                setOrganizations(data)
            }).catch ((error) => {
                console.log(error);
            });  
        } catch(err) {
            console.log(err);
        }
    }
    
    const handleChange = (e, newValue) => {
        setValue(newValue);
      }
    
      const handleAdd = async (e) => {
        e.preventDefault();
        console.log("Save");
         try { 
            fetch(session.subscriptionServer + "/tables/subscription/" + row.id, {
                method: 'PATCH',
                body: JSON.stringify(row),
                headers:{"Content-type":"application/json"}
            }).then ((response) => response.json())
            .then ((data) => {
                setRow(data);
                handleUpdateRows(data);
                console.log("Updated: ",data);
                setSavedMessage(true);
            }).catch ((error) => {
                console.log(error);
                setErrorMessage(true);
            });  
        } catch(err) {
          console.log(err);
        }

      };
    
      const handleFieldUpdate = (event, newValue, id) => {
        console.log("Case: ",id,  newValue)
        setErrorMessage(false);
        setSavedMessage(false);
        const updatedDate = Date.UTC;
        if (id ==='name') {
            if (newValue === "kinetisense-flex") {
                setRow({...row, name: newValue, updatedAt: updatedDate })
            } else {
                setRow({...row, name: newValue, displayName: "", modules: "", features: "", updatedAt: updatedDate })
            }
        } else if (id ==='displayName') {
            flexLicenceTemplates.forEach((el) =>{
                console.log(el.name)
                if (el.name === newValue) {
                    setRow({...row, displayName:newValue, modules: el.modules, features: el.features, updatedAt: updatedDate });
                }
            })
        } else if (id ==='purchaseName') {
            setRow({...row, purchaseName: newValue, updatedAt: updatedDate });
        } else if (id ==='purchaseEmail') {
            setRow({...row, purchaseEmail: newValue, updatedAt: updatedDate });
        } else if (id ==='expirationDate') {
            setRow({...row, expirationDate: newValue, updatedAt: updatedDate });
        } else if (id ==='deviceIdList') {
            setRow({...row, deviceIdList: newValue, updatedAt: updatedDate });
        } else if (id ==='modules') {
            setRow({...row, modules: Array2CSVString(newValue), updatedAt: updatedDate  });
        } else if (id ==='features') {
            setRow({...row, features: Array2CSVString(newValue), updatedAt: updatedDate  });
        } else if (id ==='amount') {
            setRow({...row, amount: newValue, updatedAt: updatedDate });
        } else if (id ==='maxNumberOfDevices') {
            setRow({...row, maxNumberOfDevices: newValue, updatedAt: updatedDate });
        } else if (id ==='notes') {
            setRow({...row, notes: newValue, updatedAt: updatedDate });
        }  else if (id ==='description') {
          setRow({...row, description: newValue, updatedAt: updatedDate });
        }  else if (id ==='organization') {
          setRow({...row, organizationId: newValue, updatedAt: updatedDate });
        } else if (id ==='isTestLicence') {
            setRow({...row, isTestLicence: newValue, updatedAt: updatedDate });
        } else if (id ==='salesPersonEmail') {
            setRow({...row, salesPersonEmail: newValue, updatedAt: updatedDate });
        } else if (id ==='readOnlyDevices') {
            setRow({...row, readOnlyDevices: Array2CSVString(newValue), updatedAt: updatedDate });
        } else {
            console.log("Missing: ",id)
        }
      }

     

    return (
        
        <form onSubmit={handleAdd}>
            <div className="formInput">
                <Box>
                    <TabContext value={value} >
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                            <Tab label="General Information" value="1" />
                            <Tab label="Modules" value="2" />
                            <Tab label="Features" value="3" />
                            <Tab label="Read-Only Devices" value="4" />
                            </Tabs>
                        </Box>
                        <TabPanel value='1'>
                            <LicenceInformation 
                            data={row} 
                            title="Licence Information"
                            onFieldChange={(event,value, id) => handleFieldUpdate(event, value, id)}
                            organizations={organizations}
                            flexLicenceTemplates={flexLicenceTemplates}
                            />
                        </TabPanel>
                            <TabPanel value='2'>
                            <ListSelection 
                            baseList={modules.sort((a, b) => (a > b ? 1 : -1))} 
                            selectedList={CSVString2Array(row.modules).sort((a,b) => a > b ? 1 : -1)} 
                            title={"Modules"} 
                            id = "modules" 
                            onSelectionChange={(event,value, id) => handleFieldUpdate(event, value, id)} 
                            />
                        </TabPanel>
                        <TabPanel value='3'>
                            <ListSelection 
                            baseList={CFFeatures.concat(features).concat(branding).sort((a, b) => (a > b ? 1 : -1))} 
                            selectedList={CSVString2Array(row.features).sort((a,b) => a > b ? 1 : -1)} 
                            title={"Features"} 
                            id="features" 
                            onSelectionChange={(event,value, id) => handleFieldUpdate(event, value, id)} 
                            />
                        </TabPanel> 
                        <TabPanel value='4'>
                            <ListSelection 
                            baseList={CSVString2Array(row.deviceIdList).sort((a, b) => (a > b ? 1 : -1))} 
                            selectedList={CSVString2Array(row.readOnlyDevices).sort((a,b) => a > b ? 1 : -1)} 
                            title={"Read-Only Devices"} 
                            id="readOnlyDevices" 
                            onSelectionChange={(event,value, id) => handleFieldUpdate(event, value, id)} 
                            />
                        </TabPanel> 
                    </TabContext>
                </Box>
                <div className="formButtons" >
                    <div className="left">
                        {errorMessage && <div className="error">An error occured</div>}
                        {savedMessage && <div className="message">Licence Saved</div>}
                    </div>
                    <div className="right">
                        <Button  
                             type="submit"
                             variant="contained"
                             className="button"
                        >
                            Save
                        </Button>
                        
                    </div>
                </div>
            </div>
        </form>
            
    );
}

export default LicenceEdit;