
import "./Chart";
import "../widget/Widget";
import { modules } from "../../data/ControlledLists";
import { useEffect, useState } from "react";
import { BarChart, CartesianGrid, XAxis, YAxis,Tooltip,Legend, Bar } from "recharts";
import formatRFC3339 from "date-fns/formatRFC3339";

const LicencePerModuleChart = ({rows, handleOnGraphClick}) =>{
    const [data, setData] = useState([]);
    const [mod, setMod] = useState(modules);

    useEffect (() => {
        var arr = [];
        const currentLicences = rows.filter(row => row.expirationDate >= formatRFC3339(new Date()))
        for (var i = 0; i < mod.length; i++) {
           var moduleName = mod[i];
           var filtered = currentLicences.filter(row => {return String(row.modules).includes(moduleName);});
           arr.push({
                        module:mod[i],
                        amount: filtered.length
           })
        }
        setData(arr);
    },[rows])
      
    const handleOnClick = (e) => {
        const currentLicences = rows.filter(row => row.expirationDate >= formatRFC3339(new Date()))
        const filtered = currentLicences.filter(row => {return String(row.modules).includes(e.activeLabel) ;});
        handleOnGraphClick(filtered,"for module: "+e.activeLabel);

    }

    return( 
        <div className="chart">
            <div className="left" >
            <center className="title">Licence Per Module</center>
                <BarChart width={350} height={500} data={data} layout="vertical" margin={{ top: 5, right: 0, left: 100, bottom: 5  }} onClick={bob => handleOnClick(bob)}>
                    <CartesianGrid strokeDasharray="1 1" horizontal={false} stroke="gray" />
                    <XAxis  type="number"/>
                    <YAxis type="category" dataKey="module" interval={0}/>
                    <Tooltip />
                    <Legend  />
                    <Bar dataKey="amount" fill= "#009ce0"/>
                </BarChart>
            </div>
      </div>
    )
};

export default LicencePerModuleChart;