import "./Dialog.scss";

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Button from "@mui/material/Button";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

const InfoOkDialog = ({open, message, handleClose}) => {

    return (

            <Dialog 
                open={open} 
                fullWidth={true}
                maxWidth={"sm"}
                className="editdialog"
                >
                <DialogContent id="information">
                    <DialogContentText >
                   <div className="dialogContentHeader"> <InfoOutlinedIcon className="icon" fontSize="large"/><h2>Information</h2>                   </div>
                       <strong> {message}</strong>
                    </DialogContentText>
                </DialogContent>
                <DialogActions id="information">
                    <Button onClick={handleClose} autoFocus>
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
    )

}

export default InfoOkDialog;