import './Widget';
import { useState, useEffect } from "react";
import { ServerList } from "../../data/ControlledLists";

const SystemHealthWidget = () => {
    const [s1, setS1] = useState(false);
    const [s2, setS2] = useState(false);
    const [s3, setS3] = useState(false);
    const [s4, setS4] = useState(false);
    const [s5, setS5] = useState(false);
    const [s6, setS6] = useState(false);
    const [s7, setS7] = useState(false);
    const [s8, setS8] = useState(false);
    const [s9, setS9] = useState(false);
    const [s10, setS10] = useState(false);

    const [a1, setA1] = useState("");
    const [a2, setA2] = useState("");
    const [a3, setA3] = useState("");
    const [a4, setA4] = useState("");
    const [a5, setA5] = useState("");
    const [a6, setA6] = useState("");
    const [a7, setA7] = useState("");
    const [a8, setA8] = useState("");
    const [a9, setA9] = useState("");
    const [a10, setA10] = useState("");
    
    const report = [
        {name:"Education",found:s1, version:a1},
        {name:"DEV",found:s2, version:a2},
        {name:"East USA",found:s3, version: a3},
        {name:"Central Canada",found:s4, version: a4},
        {name:"West USA",found:s5, version: a5},
        {name:"East Asia",found:s6, version: a6},
        {name:"West Europe",found:s7, version: a7},
        {name:"South East Australia",found:s8, version: a8},
        {name:"Subscription",found:s9, version: a9},
        {name:"Subscription DEV",found:s10, version: a10},
        ].map((s) => (
            s.found 
            ?<div key={s.name} className="line"><div className="green">{s.name}</div><div className="version">{s.version}</div></div>
            :<div key={s.name} className="line"><div className="red">{s.name}</div></div>
     ))

    useEffect(() => {

       
            fetch("https://kinetisensecloudeducation.azurewebsites.net/api/version")
            .then(res => {
                if (res.ok === true) { 
                    setS1(true);
                }  
                return res.text();
            }).then(data => {
                var p = data.indexOf('(');
                setA1(data.substring(p))
            }).catch(err =>{
                 console.log(err);
             })
           
             fetch("https://kinetisensecloudDevApp.azurewebsites.net/api/version")
             .then(res => {
                if (res.ok === true) { 
                    setS2(true);
                }  
                return res.text();
            }).then(data => {
                var p = data.indexOf('(');
                setA2(data.substring(p))
            }).catch(err =>{
                 console.log(err);
             })

             fetch("https://kinetisensecloudeastusapp.azurewebsites.net/api/version")
             .then(res => {
                if (res.ok === true) { 
                    setS3(true);
                }  
                return res.text();
            }).then(data => {
                var p = data.indexOf('(');
                setA3(data.substring(p))
            }).catch(err =>{
                 console.log(err);
             })

             fetch("https://kinetisensecloudcentralcanada.azurewebsites.net/api/version")
             .then(res => {
                if (res.ok === true) { 
                    setS4(true);
                }  
                return res.text();
            }).then(data => {
                var p = data.indexOf('(');
                setA4(data.substring(p))
            }).catch(err =>{
                 console.log(err);
             })

             fetch("https://kinetisensecloudwestusapp.azurewebsites.net/api/version")
             .then(res => {
                if (res.ok === true) { 
                    setS5(true);
                }  
                return res.text();
            }).then(data => {
                var p = data.indexOf('(');
                setA5(data.substring(p))
            }).catch(err =>{
                 console.log(err);
             })

             fetch("https://kinetisensecloudeastasiaapp.azurewebsites.net/api/version")
             .then(res => {
                if (res.ok === true) { 
                    setS6(true);
                }  
                return res.text();
            }).then(data => {
                var p = data.indexOf('(');
                setA6(data.substring(p))
            }).catch(err =>{
                 console.log(err);
             })

             fetch("https://kinetisensecloudwesteurope.azurewebsites.net/api/version")
             .then(res => {
                if (res.ok === true) { 
                    setS7(true);
                }  
                return res.text();
            }).then(data => {
                var p = data.indexOf('(');
                setA7(data.substring(p))
            }).catch(err =>{
                 console.log(err);
             })

             fetch("https://kinetisensecloudsoutheastaustralia.azurewebsites.net/api/version")
             .then(res => {
                if (res.ok === true) { 
                    setS8(true);
                }  
                return res.text();
            }).then(data => {
                var p = data.indexOf('(');
                setA8(data.substring(p))
            }).catch(err =>{
                 console.log(err);
             })

             fetch("https://kinetisensesubscriptionsystem.azurewebsites.net/api/version")
             .then(res => {
                if (res.ok === true) { 
                    setS9(true);
                }  
                return res.text();
            }).then(data => {
                var p = data.indexOf('(');
                setA9(data.substring(p))
            }).catch(err =>{
                 console.log(err);
             })

             fetch("https://kinetisensesubscriptionsystemdev.azurewebsites.net/api/version")
             .then(res => {
                if (res.ok === true) { 
                    setS10(true);
                }  
                return res.text();
            }).then(data => {
                var p = data.indexOf('(');
                setA10(data.substring(p))
            }).catch(err =>{
                 console.log(err);
             })

    },[]);


    return (
        <div className="systemhealth">
        <div className="health">
          <span className="title">System Health</span>
          <span className="left">
                {report}
          </span>
        </div>
       
      </div>
    );
}

export default SystemHealthWidget;