const CustomFieldsMetadata = {
        "featureName": "",
        "metaData": "{\"clinicCustomFieldTemplate\": [], \"practitionerCustomFieldTemplate\": [],\"patientCustomFieldTemplate\": [],\"assessmentCustomFieldTemplate\": []}",
        "isDeleted": false,
        "errorCode": null,
        "errorMessage": null,
        "id": "",
        "version": "",
        "createdAt": "",
        "updatedAt": "",
        "deleted": false
};

export default CustomFieldsMetadata;
