import "./Dialog.scss";

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import OrganizationEdit from "../organization/OrganizationEdit";
const EditOrganizationDialog = ({open, id, handleClose, handleUpdateRows, organizations}) => {

    return (

        <Dialog 
        PaperProps={{
          sx: {
            minHeight: 520
          }
        }}
        className="editdialog" 
        open={open} 
        fullWidth={true}
        maxWidth={"sm"}
        >
        <DialogTitle className="dialogtitle">
          <div className="left">
          Organization
          </div>
            <IconButton onClick={handleClose} className="right">
              <CloseIcon />
            </IconButton>
        </DialogTitle>
        <DialogContent  className="dialogcontent" >
            <div style={{padding:"10px"}}>
          <OrganizationEdit id ={id} handleUpdateRows={handleUpdateRows} organizations={organizations} />
          </div>
        </DialogContent>
      </Dialog>
    );

}

export default EditOrganizationDialog;