import "./datatable.scss";
import "./licenceDataTable.scss";

import { DataGrid } from "@mui/x-data-grid";
import { OrganizationColumns } from "../../datatablesource";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import { useSession } from "../../context/SessionContext";
import EditOrganizationDialog from "../dialogs/EditOrganizationDialog";
import ViewOrganizationDialog from "../dialogs/ViewOrganizationDialog";
import DeleteOrganizationDialog from "../dialogs/DeleteOrganizationDialog";
import NewOrganizationDialog from "../dialogs/NewOrganizationDialog";
import OrganizationData from "../../data/OrganizationData";
import { useLoginServer } from "../../context/LoginServerContext";
import { useAuthContext } from "../../context/AuthContext";
import { RotatingLines } from "react-loader-spinner";
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';


const OrganizationDataTable = (props) => {

    const [rows, setRows] = useState([]);
    const [id, setId] = useState();
    const [edit, setEdit] = useState(false);
    const [view, setView] = useState(false);
    const [del, setDelete] = useState(false);
    const [searchString, setSearchString] = useState("");
    const [search, setSearch] = useState(false);
    const [title, setTitle] = useState("");
    const [add, setAdd] = useState(false);
    const [refresh, setRefresh] = useState(false)
    const [skip,setSkip] = useState(0);
  
    const loginServerURL = useLoginServer();
    const {currentToken} = useAuthContext();

    console.log("LoginServer: ", loginServerURL)
    
    console.log("ROWS: ", rows)
    console.log("Id: ",id);
    console.log("Search Flag: ", search)
    useEffect (() => {
      try { 
        const sqlCommand = loginServerURL + "/api/Organizations/GetOrganizations";
        console.log(sqlCommand);
        fetch(sqlCommand, {
            method: 'GET',
            headers:{
                "X-ZUMO-AUTH": currentToken,
                "Content-type":"application/json"
            }
        }).then ((response) => {
          if(response.status>= 200 && response.status < 300){
            return response.json();
          } else {
            throw new Error(response.status);
          }
        })
        .then ((data) => {
            console.log("Organizations: ",data);
            setRows(data);
        }).catch ((error) => {
            console.log(error);
        });  
    } catch(err) {
        console.log(err);
    }
},[refresh]);


    const handleClearSearchString = () =>{
      
      setSearchString("");
    }

    const handleOnSubmit = (event) => {
      event.preventDefault()
      setSearch(!search);
    }

  const handleCloseEditDialog = () => {
    setEdit(false);
  }
  const handleCloseViewDialog = () => {
    setView(false);
  }
  const handleCloseDeleteDialog = () => {
    setDelete(false);
  }

  const handleCloseNewDialog = () => {
    setAdd(false);
    setRefresh(!refresh);
  }

  const handleAdd = () => {
    setAdd(true);
    console.log("ADD");
  }

  const handleEdit = async (id) =>{
    setEdit(true);
    setId(id);
    
  }
  const handleView = (id, organizationName) => {
    setView(true);
    setId(id);
    setTitle(organizationName+" - Detail")
  }

  const handleDelete = (id) => {
    setDelete(true);
    setId(id);
  }

  const actionColumn = [
    {
      field: "action",
      headerName: "Action",
      width: 120,
      renderCell: (params) => {
        return (
          <div className="cellAction">
            <RemoveRedEyeOutlinedIcon style={{color:"green", cursor: "pointer"}} onClick={() => handleView(params.row.id, params.row.name)}>View</RemoveRedEyeOutlinedIcon>
              <ModeEditOutlineOutlinedIcon style={{color:"DodgerBlue", cursor: "pointer"}} onClick={() => handleEdit(params.row.id)}>Edit</ModeEditOutlineOutlinedIcon>
            <DeleteOutlineOutlinedIcon style={{color:"red", cursor: "pointer"}} onClick={() => handleDelete(params.row.id)}>Delete</DeleteOutlineOutlinedIcon>
          </div>
        );
      },
    },
  ];

  const handleAddRow = (row) =>{
    setAdd(false)
    setRefresh(!refresh);
  }

  const handleRemoveFromRows = (id) =>{
    const newList = rows.filter((r) => r.id !== id );
    setRows(newList);
  }

  const handleUpdateRows = (r) =>{
   setRefresh(!refresh);
  }


  return (
    <>
      <EditOrganizationDialog id={id} handleClose = {handleCloseEditDialog} open={edit} handleUpdateRows={handleUpdateRows} organizations={rows}/>
      <ViewOrganizationDialog id={id} handleClose = {handleCloseViewDialog} open={view} title={title}/>
      <DeleteOrganizationDialog id={id} handleClose = {handleCloseDeleteDialog} open={del} handleDeleteRow={handleRemoveFromRows} />
      <NewOrganizationDialog handleClose={handleCloseNewDialog} open={add} handleAddRow={handleAddRow} data={OrganizationData} organizations={rows}/>

    <div className="datatable" style={{height:660}}>
      <div className="datatableTitle">
       Organizations
       <AddCircleOutlineOutlinedIcon style={{color:"green", cursor: "pointer"}} onClick={handleAdd}>
          New Organization
        </AddCircleOutlineOutlinedIcon>
      </div>
      <DataGrid
        className="datagrid"
        rows={rows}
        columns={OrganizationColumns.concat(actionColumn)}
        pageSize={15}
        rowsPerPageOptions={[15]}
        //checkboxSelection
        density='compact'
      />
    </div>
    </>
  );
};

export default OrganizationDataTable;
