const ExerciseTextData = {
    "id": "",
    "exerciseId": "",
    "languageCode": "EN-US",
    "name": "",
    "instructions": "",
    "isDeleted": false,
    "errorCode": null,
    "errorMessage": null,
    "version": "",
    "createdAt": "",
    "updatedAt": "",
    "deleted": false
}

export default ExerciseTextData;