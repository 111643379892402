import "./navbar.scss";
import LanguageOutlinedIcon from "@mui/icons-material/LanguageOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import { DarkModeContext } from "../../context/darkModeContext";
import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSession, useUpdateSession } from "../../context/SessionContext";
import { useLoginServer } from "../../context/LoginServerContext";
import { GetServerNameFromURL } from "../../Utilities/Utilities";

const Navbar = (props) => {
    const { dispatch } = useContext(DarkModeContext);
    const [searchString, setSearchString] = useState()
    const [server, setServer] = useState();
    const navigate = useNavigate();

    const session = useSession();
    const updateSession = useUpdateSession();
    const loginServerURL = useLoginServer();
    
  
  const handleServerChange = (event) => {
      updateSession({...session, subscriptionServer: event.target.value});
  }

  const handleLanguageChange = (event) => {
    updateSession({...session, language: event.target.value});
}

  const handleClearSearchString = (event) => {
    setSearchString("");
  }

  const handleFullScreen = () =>{
    if (session.maximize) {
      document.body.exitFullscreen();
      updateSession({...session, fullscreen: false});
    } else {
      document.body.requestFullscreen();
      updateSession({...session, fullscreen: true});

    }
  }

  return (
    <div className="navbar">
      <div className="wrapper">
          <div className="serverBox">
            <div className="label">Subscription Server:</div>
              <select
                  style={{color:"green"}}
                  className="label"
                  value={session.subscriptionServer}
                  onChange={(event, value) => handleServerChange(event)}
              >
                  <option value={"https://kinetisensesubscriptionsystem.azurewebsites.net"}>Production</option>
                  <option value={"https://kinetisensesubscriptionsystemdev.azurewebsites.net"}>Development</option>
                  <option value={"http://localhost:5021"}>Local</option>

              </select>
          </div>
        <div className="serverBox">
            <div className="label" style={{paddingLeft:"10px"}}>Regional Server:</div>
            <div className="label" style={{paddingLeft:"10px", paddingRight:"10px", color:"green"}}>{GetServerNameFromURL(loginServerURL)}</div>
        </div>
        <div className="items">
            <div className="item">
                <div className="serverBox">
                  <LanguageOutlinedIcon className="icon" />
                  <select
                      value={session.language}
                      onChange={(event, value) => handleLanguageChange(event)}
                      style={{width:"100px"}}
                  >
                    <option value="0">English</option>
                    <option value="1">French</option>
                    <option value="2">Spanish</option>

                  </select>
                </div>
            </div>
            <div className="item">
                <DarkModeOutlinedIcon
                  className="icon"
                  onClick={() => dispatch({ type: "TOGGLE" })}
                />
            </div>
          {/* <div className="item">
            <FullscreenExitOutlinedIcon 
            className="icon"
            onClick={handleFullScreen}
            />
          </div> */}
          {/* <div className="item">
            <NotificationsNoneOutlinedIcon className="icon" />
            <div className="counter">1</div>
          </div>
          <div className="item">
            <ChatBubbleOutlineOutlinedIcon className="icon" />
            <div className="counter">2</div>
          </div>
          <div className="item">
            <ListOutlinedIcon className="icon" />
          </div>
          <div className="item">
            <img
              src="https://images.pexels.com/photos/941693/pexels-photo-941693.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500"
              alt=""
              className="avatar"
            />
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default Navbar;
