import { TextField } from "@mui/material";
import { String2NumberOfItems } from "../../Utilities/Utilities";

const LicenceDetail = ({data}) => {

    // console.log("ID: ",data.id);
    // console.log("Data: ",data);

   
    return (
        <div className="panel">
            <div className="row">
                <TextField
                    
                    id="licenceKey" 
                    label="Licence key" 
                    variant="outlined"
                    className="TextField"
                    value={data.licenseKey}
                    size="small"
                    InputProps={
                        {readOnly:true}
                    }
                    InputLabelProps={{
                        shrink: true,
                        }}
                />
                <TextField
                    
                    id="clinicId" 
                    label="clinic Id" 
                    variant="outlined"
                    className="TextField"
                    value={data.clinicId}
                    size="small"
                    InputProps={
                        {readOnly:true}
                    }
                    InputLabelProps={{
                        shrink: true,
                        }}
                />
            </div>
            <div className="row">
                <TextField
                    id="licenceType" 
                    label="Licence type" 
                    variant="outlined"
                    className="TextField"
                    value={data.name}
                    size="small"
                    InputProps={
                        {readOnly:true}
                    }
                    InputLabelProps={{
                        shrink: true,
                        }}
                />
                <TextField
                    id="expirationDate" 
                    label="Expiration Date" 
                    variant="outlined"
                    className="TextField"
                    value={data.expirationDate}
                    size="small"
                    InputProps={
                        {readOnly:true}
                    }
                    InputLabelProps={{
                        shrink: true,
                        }}
                />
            </div>
            <div className="row">
                <TextField
                    
                    id="purchaseName" 
                    label="Purchase Name" 
                    variant="outlined"
                    className="TextField"
                    value={data.purchaseName}
                    size="small"
                    InputProps={
                        {readOnly:true}
                    }
                    InputLabelProps={{
                        shrink: true,
                        }}
                />
                <TextField
                    
                    id="purchaseEmail" 
                    label="Purchase Email" 
                    variant="outlined"
                    className="TextField"
                    value={data.purchaseEmail}
                    size="small"
                    InputProps={
                        {readOnly:true}
                    }
                    InputLabelProps={{
                        shrink: true,
                        }}
                />
            </div>
            <div className="row">
                <TextField
                    
                    id="description" 
                    label="Regional Server Name" 
                    variant="outlined"
                    className="TextField"
                    value={data.description}
                    size="small"
                    InputProps={
                        {readOnly:true}
                    }
                    InputLabelProps={{
                        shrink: true,
                        }}
                />
                        <TextField
                            
                            id="organizationId" 
                            label="Organization Id" 
                            variant="outlined"
                            className="TextField"
                            value={data.organizationId}
                            size="small"
                            InputProps={
                                {readOnly:true}
                            }
                            InputLabelProps={{
                                shrink: true,
                                }}
                        />
            </div>
          
            <div className="row">
                <TextField style={{width:"1200px"}}
                    id="amount" 
                    
                    label="Sales Contact Email" 
                    variant="outlined"
                    className="TextField"
                    value={data.salesPersonEmail}
                    size="small"
                    InputProps={
                        {readOnly:true}
                    }
                    InputLabelProps={{
                        shrink: true,
                        }}
                />
                <TextField
                    id="amount" 
                    label="Seats" 
                    variant="outlined"
                    className="TextField"
                    value={data.amount}
                    size="small"
                    InputProps={
                        {readOnly:true}
                    }
                    InputLabelProps={{
                        shrink: true,
                        }}
                />
                 <TextField
                    id="maxdev" 
                    label="Max Devices" 
                    variant="outlined"
                    className="TextField"
                    value={data.maxNumberOfDevices}
                    size="small"
                    InputProps={
                        {readOnly:true}
                    }
                    InputLabelProps={{
                        shrink: true,
                        }}
                />
                 <TextField
                    id="devnb" 
                    label="Device used" 
                    variant="outlined"
                    className="TextField"
                    value={String2NumberOfItems(data.deviceIdList)}
                    size="small"
                    InputProps={
                        {readOnly:true}
                    }
                    InputLabelProps={{
                        shrink: true,
                        }}
                />
                 <TextField
                    id="rodevnb" 
                    label="R/O Devices" 
                    variant="outlined"
                    className="TextField"
                    value={String2NumberOfItems(data.readOnlyDevices)}
                    size="small"
                    InputProps={
                        {readOnly:true}
                    }
                    InputLabelProps={{
                        shrink: true,
                        }}
                />
                 <TextField
                    id="IsTestLicence" 
                    label="Is Test Licence" 
                    variant="outlined"
                    className="TextField"
                    value={data.isTestLicence}
                    size="small"
                    InputProps={
                        {readOnly:true}
                    }
                    InputLabelProps={{
                        shrink: true,
                        }}
                />

            </div>
            <div>
                <TextField
                    fullWidth
                    id="modules" 
                    label="Modules" 
                    variant="outlined"
                    className="TextField"
                    value={data.modules}
                    size="small"
                    InputProps={
                        {readOnly:true}
                    }
                    InputLabelProps={{
                        shrink: true,
                        }}
                />
            </div>
            <div>
                <TextField
                    fullWidth
                    id="features" 
                    label="features" 
                    variant="outlined"
                    className="TextField"
                    value={data.features}
                    size="small"
                    InputProps={
                        {readOnly:true}
                    }
                    InputLabelProps={{
                        shrink: true,
                        }}
                />
            </div>
           
            {/* <div>
                <TextField
                    fullWidth
                    id="organizationId" 
                    label="Organization Id" 
                    variant="outlined"
                    className="TextField"
                    value={data.licenceKey}
                    size="small"
                    InputProps={
                        {readOnly:true}
                    }
                    InputLabelProps={{
                        shrink: true,
                        }}
                />
            </div> */}
           
            <div>
                <TextField
                    fullWidth
                    id="notes" 
                    label="Notes" 
                    variant="outlined"
                    className="TextField"
                    value={data.notes}
                    size="small"
                    multiline
                    InputProps={
                        {readOnly:true}
                    }
                    InputLabelProps={{
                        shrink: true,
                        }}
                />
            </div>
         </div>

        
    )
}

export default LicenceDetail;
