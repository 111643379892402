import "./Dialog.scss";

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Button from "@mui/material/Button";
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';


const SuccessOkDialog = ({open, message, handleClose}) => {
    return ( <Dialog 
        open={open} 
        fullWidth={true}
        maxWidth={"sm"}
        className="editdialog"
        >
        <DialogContent id="success">
            <DialogContentText >
           <div className="dialogContentHeader"> <CheckCircleOutlinedIcon className="icon" fontSize="large"/><h2>Success</h2>  </div>
               <strong> {message}</strong>
            </DialogContentText>
        </DialogContent>
        <DialogActions id="success">
            <Button onClick={handleClose} autoFocus>
                OK
            </Button>
        </DialogActions>
    </Dialog>
    )
}

export default SuccessOkDialog;