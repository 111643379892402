import "./datatable.scss";
import "./licenceDataTable.scss";

import { DataGrid } from "@mui/x-data-grid";
import { FlexLicenceTemplateColumns } from "../../datatablesource";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { useSession } from "../../context/SessionContext";
import { RotatingLines } from "react-loader-spinner";
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import EditFlexLicenceTemplateDialog from "../dialogs/EditFlexLicenceTemplateDialog";
import DeleteFlexLicenceTemplateDialog from "../dialogs/DeleteFlexLicenceTemplateDialog";


const FlexLicenceTemplateDatatable = (props) => {

    const [rows, setRows] = useState([]);
    const [id, setId] = useState();
    const [edit, setEdit] = useState(false);
    const [view, setView] = useState(false);
    const [del, setDelete] = useState(false);
    const [searchString, setSearchString] = useState("");
    const session = useSession();
    const [search, setSearch] = useState(false);
    const [skip, setSkip] = useState(0);
    const [isLoading, setLoading] = useState(false);
    const [add, setAdd] = useState(false);

   
    
    console.log("ROWS: ", rows)
    // console.log("Id: ",id);
    // console.log("Search Flag: ", search)
    useEffect (() => {
            let queryString = `?$filter=((id ne null) and (updatedAt ge 1970-01-01T20:00:00Z))&$orderby=updatedAt&$skip=0&top=1000&search=${searchString}`
            console.log("queryData: ",queryString)
          try { 
            fetch(session.subscriptionServer + "/tables/FlexLicenceTemplate/" + queryString, {
                method: 'GET',
                headers:{"Content-type":"application/json"}
            }).then ((response) => response.json())
            .then ((data) => {
              setRows(data);
            }).catch ((error) => {
                console.log(error);
            });  
          } catch(err) {
            console.log(err);
          }
    },[search, skip]);

    const handleClearSearchString = () =>{
      
      setSearchString("");
    }

    const handleOnSubmit = (event) => {
      event.preventDefault()
      setRows([]);
      setSearch(!search);
      setSkip(0);
      setLoading(true);
    }

  const handleCloseEditDialog = () => {
    setEdit(false);
  }
  const handleCloseAddDialog = () => {
    setAdd(false);
  }
  const handleCloseDeleteDialog = () => {
    setDelete(false);
  }

  const handleEdit = async (id) =>{
    setEdit(true);
    setId(id);
    
  }
  

  const handleDelete = (id) => {
    setDelete(true);
    setId(id);
  }

  const handleAdd = () => {
  setAdd(true);
}

  const actionColumn = [
    {
      field: "action",
      headerName: "Action",
      width: 100,
      renderCell: (params) => {
        return (
          <div className="cellAction">
            {/* <RemoveRedEyeOutlinedIcon style={{color:"green", cursor: "pointer"}} onClick={() => handleView(params.row.id)}>View</RemoveRedEyeOutlinedIcon> */}
              <ModeEditOutlineOutlinedIcon style={{color:"DodgerBlue", cursor: "pointer"}} onClick={() => handleEdit(params.row.id)}>Edit</ModeEditOutlineOutlinedIcon>
            <DeleteOutlineOutlinedIcon style={{color:"red", cursor: "pointer"}} onClick={() => handleDelete(params.row.id)}>Delete</DeleteOutlineOutlinedIcon>
          </div>
        );
      },
    },
  ];

  const handleRemoveFromRows = (id) =>{
    const newList = rows.filter((r) => r.id !== id );
    setRows(newList);
  }

 
  const handleUpdateRows = (r) =>{
    console.log("handleUpdateRows: ", r)
      let i =0;
      let j = -1;
      rows.forEach((el) => {
          if (el.id === r.id) {
              j=i;
          }
          i++;
      })
      const newList = [...rows];
      if (i>0 && j === -1){
        newList.push(r);
      } else {
        newList[j]=r;
      }
      setRows(newList);
  }


  return (
    <>
      <EditFlexLicenceTemplateDialog id={"new"} handleClose = {handleCloseAddDialog} open={add} handleUpdateRows={handleUpdateRows} list={rows}/>
      <EditFlexLicenceTemplateDialog id={id} handleClose = {handleCloseEditDialog} open={edit} handleUpdateRows={handleUpdateRows} list={rows}/>
      <DeleteFlexLicenceTemplateDialog id={id} handleClose = {handleCloseDeleteDialog} open={del} handleDeleteRow={handleRemoveFromRows} />
      
     
    { isLoading &&
      <center style={{paddingTop:100}}>
          <RotatingLines  strokeColor="grey"  strokeWidth="2"  animationDuration="1.5"  width="150"  visible={isLoading} />
      </center>
    }
    {!isLoading &&
        <div className="datatable" style={{height:660}}>
          <div className="datatableTitle">
          Flex Licence Templates
            <AddCircleOutlineOutlinedIcon style={{color:"green", cursor: "pointer"}} onClick={() => handleAdd()}>
              New Flex Licence Template
            </AddCircleOutlineOutlinedIcon>
          </div>
          <DataGrid
            className="datagrid"
            // rows={rows.sort((a,b) => a.name > b.name ? 1 : -1)}
            rows={rows}
            columns={FlexLicenceTemplateColumns.concat(actionColumn)}
            pageSize={15}
            rowsPerPageOptions={[15]}
            //checkboxSelection
            density='compact'
          />
        </div>
    }
    </>
  );
};




export default FlexLicenceTemplateDatatable;
