import "./Dialog.scss";

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import LicenceUsage from '../Licences/LicenceUsage';
import { Modal } from "@mui/material";
import zIndex from "@mui/material/styles/zIndex";

const LicenceUsageDialog = (props) => {
    return ( <Dialog 
        PaperProps={{
          sx: {
            minHeight: 920,
          }
        }}
          className="editdialog" 
          open={props.open} 
          // onClose={handleCloseViewDialog}
          fullWidth={true}
          maxWidth={"lg"}
          >
          <DialogTitle className="dialogtitle">
            <div className="left">
            Licence Usage
            </div>
              <IconButton onClick={props.handleClose} className="right">
                <CloseIcon />
              </IconButton>
          </DialogTitle>
          <DialogContent  className="dialogcontent">
            <LicenceUsage id ={props.id}/>
          </DialogContent>
        </Dialog>
    )

}

export default LicenceUsageDialog;