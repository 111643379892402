import "./Dialog.scss";

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import LicenceList from "../Licences/LicenceList";
import ExerciseLookupDataTable from "../datatable/ExerciseLookupDataTable";


const ExerciseLookupListDialog = ({list, open, handleClose, handleExerciseSelected, market, group}) => {
    return ( <Dialog 
        PaperProps={{
          sx: {
            minHeight: 700
          }
        }}
          className="editdialog" 
          open={open} 
          fullWidth={true}
          maxWidth={"md"}
          >
          <DialogTitle className="dialogtitle">
            <div className="left">
            Exercise Lookup List
            </div>
              <IconButton onClick={handleClose} className="right">
                <CloseIcon />
              </IconButton>
          </DialogTitle>
          <DialogContent  className="dialogcontent">
            <ExerciseLookupDataTable list={list} handleClose={handleClose} handleExerciseSelected={handleExerciseSelected} market={market} group={group}/>
          </DialogContent>
        </Dialog>
    )

}

export default ExerciseLookupListDialog;