import React from "react";
import {useState, useEffect} from 'react';
import Box from "@mui/material/Box";
import  Button  from '@mui/material/Button';
import { useLoginServer } from "../../../context/LoginServerContext";
import uuid from "react-uuid";
import SuccessOkDialog from "../../dialogs/SuccessOkDialog";
import { OrganizationUserData } from "../../../data/OrganizationUserData";
import OrganizationUserInformation from "./OrganizationUserInformation";
import { useAuthContext } from "../../../context/AuthContext";
import {CSVString2Array, Array2CSVString} from "../../../Utilities/Utilities";

const alertStates = {"info":false,"warning":false,"error":false,"success":false};

const OrganizationUserUpdate = ({id, handleUpdate, handleClose, organizationId}) => {

    const [row, setRow] = useState(OrganizationUserData);
    const [errorFlag, setErrorFlag] = useState(false);
    const [savedFlag, setSavedFlag] = useState(false);
    const [warningFlag, setWarningFlag] = useState(false);
    const [message, setMessage] = useState("");
    const [showDialog, setShowDialog] = useState({error:false, success:false, message:""});
    const loginServerURL = useLoginServer();
    const {currentToken} = useAuthContext();

    console.log("User New Row: ", [row, id, organizationId]);

    useEffect (() => {
        setRow({...row, organizationId:organizationId})
    },[])

    useEffect(() => {
        try { 
            const sqlCommand = loginServerURL + "/tables/organizationuser/"+id;
            console.log(sqlCommand);
            fetch(sqlCommand, {
                method: 'GET',
                headers:{
                    "X-ZUMO-AUTH": currentToken,
                    "Content-type":"application/json"
                }
            })
            .then ((data) => {
                console.log("User: ",data);
                if(data.status >= 200 && data.status < 300) {
                    return data.json();
                } else {
                    throw new Error(data.status);
                }
            }).then ((resp) => {
                console.log("Resp: ",resp.queryable[0]);
                let record = resp.queryable[0];
                setRow(resp.queryable[0]);
                if (record.phones.length > 0){
                    console.log("phone",resp.queryable[0].phones[0].value)
                    setRow({...record, phone:resp.queryable[0].phones[0].value})
                }
            })
            .catch ((error) => {
                console.log(error);
                setErrorFlag(true);
            });  
        } catch(err) {
            console.log(err);
            setErrorFlag(true);
        }

    },[])
    
      const handleFieldUpdate = (event, newValue, id) => {
        //console.log("Case: ",id,  newValue)
        setErrorFlag(false);
        setSavedFlag(false);
        const updatedDate = Date.UTC;
        if (id ==='name') {
            setRow({...row, name: newValue })
        } else if (id ==='username') {
            setRow({...row, username: newValue, updatedAt: updatedDate });
        } else if (id ==='name') {
            setRow({...row, name: newValue, updatedAt: updatedDate });
        } else if (id ==='middleName') {
            setRow({...row, middleName: newValue, updatedAt: updatedDate });
        } else if (id ==='surname') {
            setRow({...row, surname: newValue, updatedAt: updatedDate });
        } else if (id ==='email') {
            setRow({...row, email: newValue, updatedAt: updatedDate })
        } else if (id ==='phone') {
            const p = [{id:null, key:"work", value:newValue, clinicId:null, personId:null, isDeleted:false, delete:false}];
            setRow({...row, phones: p, phone:newValue, updatedAt: updatedDate })
        } else if (id ==='roles') {
            setRow({...row, roles: Array2CSVString(newValue), updatedAt: updatedDate })
        } else if (id ==='notes') {
            setRow({...row, notes: newValue, updatedAt: updatedDate })
        } else if (id ==='isActive') {
            setRow({...row, isActive: newValue, updatedAt: updatedDate })
        } else {
            console.log("Missing: ",id)
        }
      }
      
      const accountValidation= () => {
        setWarningFlag(false);
        setMessage("")
        var flag = false;
        if (row.username === "" || row.username === null ){
            setMessage("Enter Username");
            setWarningFlag(true);
        } else if (row.email === "" || row.email === null){
            setMessage("Enter Username");
            setWarningFlag(true);
        } if (row.email.includes('@')){
            setMessage("Invalid Email address");
            setWarningFlag(true);
        }
          
        return !flag;
    }

    const handleAdd = (event) =>{
            setErrorFlag(false);
            setSavedFlag(false);
            event.preventDefault();
            if (accountValidation()) {

                console.log("Updated account: ",row)

                try { 
                    fetch(loginServerURL+"/tables/OrganizationUser/"+id, {
                        method: 'PATCH',
                        body: JSON.stringify(row),
                        headers:{
                            "X-ZUMO-AUTH": currentToken,
                            "Content-type":"application/json"
                        }
                    }).then ((data) => {
                        console.log("Accounts: ",data);
                        if(data.status >= 200 && data.status < 300) {
                            setSavedFlag(true);
                            handleUpdate(row);
                        } else {
                            throw new Error(data.status);
                        }
                    }).catch ((error) => {
                        console.log(error);
                        setErrorFlag(true);
                    });  
                } catch(err) {
                    console.log(err);
                    setErrorFlag(true);
                }
            }
    }

    const handleCloseDialogs = () => {
        setShowDialog({...showDialog, error:false, success:false, message:""})
    }

    const handleDisable = () => {
        setRow({...row, isActive: false})
    }
    const handleEnable = () => {
        setRow({...row, isActive: true})
    }

    const handleResetPassword = () => {
        console.log("Reset password");
    }

    return (
        <>
            <SuccessOkDialog open={showDialog.success} message={showDialog.message} handleClose={handleCloseDialogs}/>
          <form onSubmit={handleAdd}>
            <div className="formInput" style={{height:"600px"}}>
                <Box>
                    <OrganizationUserInformation 
                    data={row} 
                    title={"Account Information"} 
                    onFieldChange={(event,value, id) => handleFieldUpdate(event, value, id)}
                    />
                </Box>
                <div className="formButtons" >
                    <div className="left">
                        {errorFlag && <div className="error">An error occured</div>}
                        {savedFlag && <div className="message">Account Saved</div>}
                    </div>
                    <div className = "right">
                        {row.isActive ?
                            <Button  
                                type="button"
                                variant="contained"
                                className="button"
                                onClick={handleDisable}
                                >
                                Disable
                            </Button> 
                            :
                            <Button  
                                type="button"
                                variant="contained"
                                className="button"
                                onClick={handleEnable}
                                >
                                Enable
                            </Button>
                        }
                        <Button  
                            type="button"
                            variant="contained"
                            className="button"
                            onClick={handleResetPassword}
                            >
                            Reset Password
                        </Button>
                        <Button  
                            type="submit"
                            variant="contained"
                            className="button"
                            >
                            Save
                        </Button>
                    </div>
                </div>
            </div>
        </form>
    </>
            
    );
}

export default OrganizationUserUpdate;