import "./chart.scss";
import React, { useState, useCallback } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend
} from "recharts";




const COLOURS = ['#ff6900', '#fcb900', '#78dcb5', '#00d084', '#8ed1fc', '#abb8c3', '#eb144c', '#f78da7', '#9900ef', '#2196f3', 'teal', '#ffeb3d'];  
export default function LineChartLicG({series}) {
  const [opacity, setOpacity] = useState({
    Bal:     2,
    CliGait: 2,
    FXSTS:   2,
    Funct:   2,
    ROFGait: 2,
    HRaise:  2,
    KAMS:    2,
    MCtSib:  2,
    OHSQ:    2,
    Posture: 2,
    RevL:    2,
    ROM:     2,
    SLH:     2,
    STS:     2,
    TUAG:    2,
    VertJ:   2,
    PAngel:  2,
    WChair:  2
  });
  const [lineSize, setLineSize] = useState({
    Bal:     1,
    CliGait: 1,
    FXSTS:   1,
    Funct:   1,
    ROFGait: 1,
    HRaise:  1,
    KAMS:    1,
    MCtSib:  1,
    OHSQ:    1,
    Posture: 1,
    RevL:    1,
    ROM:     1,
    SLH:     1,
    STS:     1,
    TUAG:    1,
    VertJ:   1,
    PAngel:  1,
    WChair:  1
  });

  console.log("LineGraphG Data:",series);

  const handleMouseEnter = useCallback(
    (o) => {
      const { dataKey } = o;
console.log("dataKey: ",dataKey);
      //setOpacity({ ...opacity, [dataKey]: 1 });
      setLineSize({ ...lineSize, [dataKey]: 4 });
    },
    [opacity, setOpacity, lineSize, setLineSize]
  );

  const handleMouseLeave = useCallback(
    (o) => {
      const { dataKey } = o;
      //setOpacity({ ...opacity, [dataKey]: 1 });
        setLineSize({ ...lineSize, [dataKey]: 1 });
    },
    [opacity, setOpacity, lineSize, setLineSize]
  );

  return (
    <div>
      <LineChart
      className="linechart"
        width={1000}
        height={300}
        data={series}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip contentStyle={{color: "darkgray",columns: 2, fontSize:"11px" }} />
        <Legend
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          
        />

        <Line
          type="monotone"
          dataKey="Bal"
          strokeOpacity={opacity.Bal}
          stroke={COLOURS[0]}
          activeDot={{ r: 8 }}
          strokeWidth={lineSize.Bal}
        />
        <Line
          type="monotone"
          dataKey="CliGait"
          strokeOpacity={opacity.CliGait}
          stroke={COLOURS[1]}
          //activeDot={{ r: 8 }}
          strokeWidth={lineSize.CliGait}
        />
        <Line
          type="monotone"
          dataKey="5XSTS"
          strokeOpacity={opacity.FXSTS}
          stroke={COLOURS[2]}
          //activeDot={{ r: 8 }}
          strokeWidth={lineSize.FXSTS}
        />
        <Line
          type="monotone"
          dataKey="Funct"
          strokeOpacity={opacity.Funct}
          stroke={COLOURS[3]}
          strokeWidth={lineSize.Funct}
        />
        <Line
          type="monotone"
          dataKey="ROFGait"
          strokeOpacity={opacity.ROFGait}
          stroke={COLOURS[4]}
          strokeWidth={lineSize.ROFGait}
        />
        <Line
          type="monotone"
          dataKey="HRaise"
          strokeOpacity={opacity.HRaise}
          stroke={COLOURS[5]}
          strokeWidth={lineSize.HRaise}
        />
        <Line
          type="monotone"
          dataKey="KAMS"
          strokeOpacity={opacity.KAMS}
          stroke={COLOURS[6]}
          strokeWidth={lineSize.KAMS}
        />
         <Line
          type="monotone"
          dataKey="MCtSib"
          strokeOpacity={opacity.MCtSib}
          stroke={COLOURS[7]}
          strokeWidth={lineSize.MCtSib}
        />
        <Line
          type="monotone"
          dataKey="OHSQ"
          strokeOpacity={opacity.OHSQ}
          stroke={COLOURS[8]}
          strokeWidth={lineSize.OHSQ}
        />
        <Line
          type="monotone"
          dataKey="Posture"
          strokeOpacity={opacity.Posture}
          stroke={COLOURS[9]}
          strokeWidth={lineSize.Posture}
        />
        <Line
          type="monotone"
          legendType="plainline"
          dataKey="RevL"
          strokeOpacity={opacity.RevL}
          stroke={COLOURS[0]}
          strokeDasharray="3 3"
          strokeWidth={lineSize.RevL}
        />
        <Line
          type="monotone"
          legendType="plainline"
          dataKey="ROM"
          strokeOpacity={opacity.ROM}
          stroke={COLOURS[1]}
          strokeDasharray="3 3"
          strokeWidth={lineSize.ROM}
        />
        <Line
          type="monotone"
          legendType="plainline"
          dataKey="SLH"
          strokeOpacity={opacity.SLH}
          stroke={COLOURS[2]}
          strokeDasharray="3 3"
          strokeWidth={lineSize.SLH}
        />
         <Line
          type="monotone"
          legendType="plainline"
          dataKey="STS"
          strokeOpacity={opacity.STS}
          stroke={COLOURS[3]}
          strokeDasharray="3 3"
          strokeWidth={lineSize.STS}
        />
         <Line
          type="monotone"
          legendType="plainline"
          dataKey="TUAG"
          strokeOpacity={opacity.TUAG}
          stroke={COLOURS[4]}
          strokeDasharray="3 3"
          strokeWidth={lineSize.TUAG}
        />
        <Line
          type="monotone"
          legendType="plainline"
          dataKey="VertJ"
          strokeOpacity={opacity.VertJ}
          stroke={COLOURS[5]}
          strokeDasharray="3 3"
          strokeWidth={lineSize.VertJ}
        />
        <Line
          type="monotone"
          legendType="plainline"
          dataKey="PAngel"
          strokeOpacity={opacity.PAngel}
          stroke={COLOURS[6]}
          strokeDasharray="3 3"
          strokeWidth={lineSize.PAngel}
        />
        <Line
          type="monotone"
          legendType="plainline"
          dataKey="WChair"
          strokeOpacity={opacity.WChair}
          stroke={COLOURS[7]}
          strokeDasharray="3 3"
          strokeWidth={lineSize.WChair}
        />

      </LineChart>
      
    </div>
  );
}
