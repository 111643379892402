import { ConstructionOutlined } from "@mui/icons-material";
import { createContext, useContext, useState, useEffect } from "react";
import { SessionData } from "../data/SessionData";

const SessionContext = createContext();
const UpdateSessionContext = createContext()


export const useSession = () => {
    return useContext(SessionContext);
}

export const useUpdateSession = () => {
    return useContext(UpdateSessionContext);
}

const initialSession = () => {
  if (localStorage.getItem("KinetisenseSession") === null) {
    console.log("Session not found");
    return SessionData;
} else {
  console.log("Session found")
    return JSON.parse(localStorage.getItem("KinetisenseSession"));
}
}

export const SessionProvider = ({ children }) => {
    const[session, setSession] = useState(initialSession());

    console.log("Session: ",session);

  const updateSession = (object) => {
    console.log("Session Object: ", object);
    setSession(object);
    localStorage.setItem("KinetisenseSession",JSON.stringify(object))
  }

  return (
    <SessionContext.Provider value={session}>
        <UpdateSessionContext.Provider value={updateSession}>
            {children}
        </UpdateSessionContext.Provider>
    </SessionContext.Provider>
  );
};
