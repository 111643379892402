import "./licencelist.scss"
import Sidebar from "../../components/sidebar/Sidebar"
import Navbar from "../../components/navbar/Navbar"
import CustomFieldsMetaListDataTable from "../../components/datatable/CustomFieldsMetaListDataTable";
const CustomFieldsMetaDataList = (props) => {
  return (
    <div className="home">
      <Sidebar/>
      <div className="homeContainer">
        <Navbar/>
        <CustomFieldsMetaListDataTable />
      </div>
    </div>
  )
}

export default CustomFieldsMetaDataList