import React from "react";
import {useState, useEffect} from 'react';
import OrganizationData from "../../data/OrganizationData";
import Box from "@mui/material/Box";
import  Button  from '@mui/material/Button';
import { useSession } from "../../context/SessionContext";
import OrganizationInformation from "./OrganizationInformation";
import { useLoginServer } from "../../context/LoginServerContext";
import { useAuthContext } from "../../context/AuthContext";


const OrganizationEdit = ({id, handleUpdateRows, organizations}) => {

    const [value, setValue] = useState('1');
    const [row, setRow] = useState(OrganizationData);
    const [errorMessage, setErrorMessage] = useState(false);
    const [savedMessage, setSavedMessage] = useState(false);
    const session = useSession();
    const loginServerURL = useLoginServer();
    const {currentToken} = useAuthContext();
    const [warningFlag, setWarningFlag] = useState(false);
    const [message, setMessage] = useState("");

    console.log("Main Row: ",row);
    
    useEffect (() => {
        
            try { 
                const sqlCommand = loginServerURL + "/tables/organization/"+id;
                console.log(sqlCommand);
                fetch(sqlCommand, {
                    method: 'GET',
                    headers:{
                        "X-ZUMO-AUTH": currentToken,
                        "Content-type":"application/json"
                    }
                }).then ((response) => {
                  if(response.status>= 200 && response.status < 300){
                    return response.json();
                  } else {
                    throw new Error(response.status);
                  }
                })
                .then ((data) => {
                    console.log("Organization fetched: ",data.queryable[0]);
                    setRow(data.queryable[0])
                }).catch ((error) => {
                    console.log(error);
                });  
            } catch(err) {
                console.log(err);
            }
    },[]);
  
    
      const handleAdd = async (e) => {
        e.preventDefault();
        console.log("Save");
         try { 
          fetch(loginServerURL + "/tables/organization/" + row.id, {
              method: 'PATCH',
              body: JSON.stringify(row),
              headers:{
                "X-ZUMO-AUTH": currentToken,
                "Content-type":"application/json"
            }
          }).then ((response) => {
            if (response.status>=200 && response.status <300) {
            return response.json();
            } else {
                throw new Error(response.status)
            }
          })
          .then ((data) => {
              setRow(data);
              handleUpdateRows(data);
              console.log("Updated: ",data);
              setSavedMessage(true);
          }).catch ((error) => {
              console.log(error);
              setErrorMessage(true);
          });  
        } catch(err) {
          console.log(err);
        }

      };

      const validation= () => {
        setWarningFlag(false);
        setMessage("")
        var flag = false;
        if (row.name === "" || row.username === null ){
            setMessage("Enter Organization Name");
            setWarningFlag(true);
        } else if (row.theme === "" || row.email === null){
            setMessage("Select a Theme");
            setWarningFlag(true);
        } 
          
        return !flag;
      }
    
      const handleFieldUpdate = (event, newValue, id) => {
        console.log("Case: ",id,  newValue)
        setErrorMessage(false);
        setSavedMessage(false);
        const updatedDate = Date.UTC;
        if (id ==='name') {
            setRow({...row, name: newValue })
        } else if (id ==='organizationKey') {
            setRow({...row, organizationKey: newValue, updatedAt: updatedDate })
        } else if (id ==='organizationId') {
            setRow({...row, organizationId: newValue, updatedAt: updatedDate })
        } else if (id ==='theme') {
            setRow({...row, theme: newValue, updatedAt: updatedDate })
        } else if (id ==='notes') {
            setRow({...row, notes: newValue, updatedAt: updatedDate })
        }  else {
            console.log("Missing: ",id)
        }
      }

    return (
        
        <form onSubmit={handleAdd}>
            <div className="formInput" >
            {/* <div className="formInput"  Style={{margin:"10px"}}> */}
                <Box>
                    <OrganizationInformation 
                    data={row} 
                    title={"Organization"} 
                    onFieldChange={(event,value, id) => handleFieldUpdate(event, value, id)}
                    organizations={organizations}
                    />
                </Box>
                <div className="formButtons" >
                    <div className="left">
                        {errorMessage && <div className="error">An error occurred</div>}
                        {savedMessage && <div className="message">Organization Saved</div>}
                        {warningFlag && <div className="warning">{message}</div>}
                    </div>
                    <div className="right">
                        <Button  
                            type="submit"
                            variant="contained"
                            className="button"
                        >
                            Save
                        </Button>
                    </div>
                </div>
            </div>
        </form>
            
    );
}

export default OrganizationEdit;