import "./Dialog.scss";

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Button from "@mui/material/Button";
import WarningAmberOutlinedIcon from "@mui/icons-material/WarningAmberOutlined";
const WarningOkDialog = ({open, message, handleClose}) => {

    return (
        <Dialog 
        open={open} 
        fullWidth={true}
        maxWidth={"sm"}
        className="editdialog"
        >
        <DialogContent id="warning">
            <DialogContentText >
           <div className="dialogContentHeader"> <WarningAmberOutlinedIcon className="icon" fontSize="large"/><h2>Warning</h2>   </div>
               <strong> {message}</strong>
            </DialogContentText>
        </DialogContent>
        <DialogActions id="warning">
            <Button onClick={handleClose} autoFocus>
                OK
            </Button>
        </DialogActions>
    </Dialog>
    )
}
export default WarningOkDialog