import {useState, useEffect} from 'react';
import { useSession } from "../../context/SessionContext";
import  Button  from '@mui/material/Button';
import { features, modules} from "../../data/ControlledLists";
import {CSVString2Array, Array2CSVString} from "../../Utilities/Utilities";
import { useAuthContext } from '../../context/AuthContext';
import { useLoginServer } from '../../context/LoginServerContext';
import CustomFieldsMetadata from '../../data/CustomFieldsMetadata';
import { FlexLicenceTemplateData } from '../../data/FlexLicenceTemplateData';
import uuid from 'react-uuid';
import FlexLicenceTemplateInformation from './FlexLicenceTemplateInformation';
import { set } from 'date-fns';


const FlexLicenceTemplateEdit = ({id, handleUpdateRows, handleClose, list}) => {

    const [row, setRow] = useState(FlexLicenceTemplateData);
    const [errorMessage, setErrorMessage] = useState(false);
    const [savedMessage, setSavedMessage] = useState(false);
    const [message, setMessage] = useState("");
    const session = useSession();
    const loginServerURL = useLoginServer();
    const {currentToken} = useAuthContext();
    const [branding, setBranding] = useState([]);
    const [CFFeatures, setCFFeatures] = useState([]);

    console.log("Main Row: ",row);
    //console.log("Params: ", id, list);

    useEffect (() => {
        getBrandingFeatures();
        getAllFeatures();
        if (id !== "new") {
            try { 
                fetch(session.subscriptionServer + "/tables/FlexLicenceTemplate/" + id, {
                    method: 'GET',
                    headers:{"Content-type":"application/json"}
                }).then ((response) => response.json())
                .then ((data) => {
                    setRow(data.queryable[0]);
                }).catch ((error) => {
                    console.log(error);
                });  
            } catch(err) {
                console.log(err);
            }
        }
     
    },[]);
  
    const getAllFeatures = () => {
        var rows = [CustomFieldsMetadata];
        let queryString = `?$filter=((id ne null) and (updatedAt ge 1970-01-01T20:00:00Z))&$orderby=updatedAt&$skip=0&top=1000`
        fetch(loginServerURL + "/tables/CustomFieldsMetaData" + queryString, {
            method: 'GET',
            headers:{
                "X-ZUMO-AUTH": currentToken,
                "Content-type":"application/json"
            }
        }).then ((response) => response.json())
        .then ((data) => {
            rows = data.filter((r) => r.isDeleted === false);
            var list = [];
            rows.forEach((p) => {
                list.push(p.featureName);
            });
            setCFFeatures(list);
        }).catch ((error) => {
            console.log("MD Error: ",error);
        });  
    }

    function getBrandingFeatures () {
        fetch(session.subscriptionServer + "/api/GetBrandingList" , {
            method: 'GET',
            headers:{
                "X-ZUMO-AUTH": currentToken,
                "Content-type":"application/json"
            }
        }).then ((response) => response.json())
        .then ((data) => {
            setBranding(data); 
        }).catch ((error) => {
            console.log("BrandingError Error: ",error);
            return [];
        });  
    }
    
    const handleAdd = async (e) => {
        e.preventDefault();
        console.log("isValid: ",isValid(row, id));
        if (isValid(row, id)) {
            console.log("Save");
            var method = "";
            var URL = "";
            var newRow = row;
            if (id ==="new") {
                method = "POST";
                URL = session.subscriptionServer + "/tables/FlexLicenceTemplate";
                newRow.id = uuid();

            } else {
                method = "PATCH";
                URL = session.subscriptionServer + "/tables/FlexLicenceTemplate/" + id;
            }
            try { 
                fetch(URL, {
                    method: method,
                    body: JSON.stringify(newRow),
                    headers:{"Content-type":"application/json"}
                }).then ((response) => response.json())
                .then ((data) => {
                    setRow(data);
                    handleUpdateRows(data);
                    console.log("Updated: ",data);
                    setSavedMessage(true);
                }).catch ((error) => {
                    console.log(error);
                    setErrorMessage(true);
                    setMessage(error);
                });  
            } catch(err) {
                console.log(err);
            }
            if (id === "new") {
                handleClose();
                console.log("Close window");
            } 
        }
     };
    
      const handleFieldUpdate = (event, newValue, id) => {
        console.log("Case: ",id,  newValue)
        setErrorMessage(false);
        setSavedMessage(false);
        const updatedDate = Date.UTC;
        if (id ==='name') {
            setRow({...row, name: newValue, updatedAt: updatedDate })
        } else if (id ==='modules') {
            setRow({...row, modules: Array2CSVString(newValue), updatedAt: updatedDate  });
        } else if (id ==='features') {
            setRow({...row, features: Array2CSVString(newValue), updatedAt: updatedDate  });
        }  else {
            console.log("Missing: ",id)
        }
      }

      const isValid = (r, id) => {
        var result = false;
        var count = list.filter((el) => el.name.toLowerCase() === r.name.toLowerCase()).length;
        if (r.name === "") {
            result = false;
            setErrorMessage(true);
            setMessage("Name is required");
        } else {
            if (id === "new") {
                if (count === 0) {
                    result = true;
                    
                } else {
                    result = false
                    setErrorMessage(true);
                    setMessage("Name already exists");
                }
            } else {
                if (count === 1) {
                    result =  true;
                } else {
                    result = false;
                    setErrorMessage(true);
                    setMessage("Name already exits");
                }
            }
            console.log("Result: ",result);
            return result;
        }
    }
    

    return (
        
        <form onSubmit={handleAdd}>
            <div className="formInput">
                   
                    <div>
                        <FlexLicenceTemplateInformation 
                            modules={modules} 
                            features={CFFeatures.concat(features).concat(branding).sort((a, b) => (a > b ? 1 : -1))} 
                            selectedList={CSVString2Array(row.modules).sort((a,b) => a > b ? 1 : -1)} 
                            onFieldChange={(event,value, id) => handleFieldUpdate(event, value, id)} 
                            data={row}
                        />
                    </div>
                   
                <div className="formButtons" >
                    <div className="left">
                        {errorMessage && <div className="error">{message}</div>}
                        {savedMessage && <div className="message">Flex Licence Template Saved</div>}
                    </div>
                    <div className="right">
                        <Button  
                            type="submit"
                            variant="contained"
                            className="button"
                        >
                            Save
                        </Button>
                    </div>
                </div>
            </div>
        </form>
            
    );
}

export default FlexLicenceTemplateEdit;