const LicenceData = {
    "purchaseDate": "",
    "expirationDate": "",
    "amount": 0.0,
    "application": 0,
    "paymentMethod": "",
    "licenseKey": "",
    "name": "kinetisense-360",
    "activationCount": 0,
    "isValid": false,
    "isPremium": false,
    "isKAMS": false,
    "isWorkflow": false,
    "isDiamondLabel": false,
    "isWhiteLabel": false,
    "uniqueMatchingId":"",
    "paymentId": "",
    "purchaseName": "",
    "purchaseEmail": "",
    "isDeleted": false,
    "errorCode": "",
    "errorMessage": "",
    "clinicId": "",
    "organizationId": null,
    "description":"",
    "modules": "",
    "features": "",
    "appleReceiptNumber": "",
    "deviceIdList": "",
    "maxNumberOfDevices": 0,
    "appleStoreLatestTransactionNumber": "",
    "appleStoreProductId": "",
    "notes": "",
    "id": "",
    "version": null,
    "createdAt": "",
    "updatedAt": "",
    "deleted": false,
    "displayName":"",
    "isTestLicence": false,
    "salesPersonEmail": "",
    "readOnlyDevices": ""
}

export default LicenceData;