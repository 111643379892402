export const CorrectiveData = {
    
        "level": 1,
        "isMobility": false,
        "isStability": false,
        "isDeleted": false,
        "joint": "",
        "exerciseId": "",
        "errorCode": null,
        "errorMessage": null,
        "exercise": null,
        "id": "",
        "version": null,
        "createdAt": "",
        "updatedAt": "",
        "deleted": false
    
}