

export const modules = [
    "Balance",
    "ClinicalGait",
    "FiveXSitToStand",
    "Functional",
    "Gait",
    "HandFlexibility",
    "HeelRaise",
    "ModifiedCtSib",
    "ModifiedVeles",
    "OverheadSquat",
    "Posture",
    "PostureAngel",
    "ROM",
    "ReverseLunge",
    "SingleLegHop",
    "SitToStand",
    "TimedUpAndGo",
    "VerticalJump",
    "Wheelchair"
]

export const features = [
    "AdvancedCsvExport",
    "AdvancedDataTools",
    "API",
    "BulkUpload",
    "Correctives",
    "Correctives_Core",
    "Correctives_RockTape",
    "Correctives_PTGenie",
    "DualRoms", 
    "FpmExport",
    "KAMS", 
    "KamsExpress",
    "KamsGolf",
    "KAMSIL",
    "KAMSSeniorLiving", // to remove
    "LoadExternalVideo",
    "NeutralShoulderRoms", 
    "PatientAppInvite",
    "PatientGroups",
    "RiskOfFallWorkflow",
    "SupineRoms", 
    "WhiteLabel",
    "Workflow" 
]

export const LicenceTypes = [
    "kinetisense-flex",
    "kinetisense-360",
    "kinetisense-diamond",
    "kinetisense-whitelabel",
    "kinetisense-premium",
    "kinetisense-workflow",
    "kinetisense-kams",
    "kinetisense-sdk",
    "kinetisense-360-longitude6"
]

export const AppsType = [
    {value:0,label:"Kinetisense"},
    {value:1,label:"Parker"},
    {value:2,label:"Metis"},
    {value:3,label:"Fluid"},
    {value:4,label:"KONGiQ"},
    {value:5,label:"Longitude6"},
    {value:6,label:"UofA"}
];

export const ServerList = [
        {url:"https://kinetisensecloudeducation.azurewebsites.net", name:"Education"},
        {url:"https://kinetisensecloudDevApp.azurewebsites.net", name:"DEV"},
        {url:"https://kinetisensecloudeastusapp.azurewebsites.net", name:"East USA"},
        {url:"https://kinetisensecloudcentralcanada.azurewebsites.net", name:"Central Canada"},
        {url:"https://kinetisensecloudwestusapp.azurewebsites.net", name:"West USA"},
        {url:"https://kinetisensecloudeastasiaapp.azurewebsites.net", name:"East Asia"},
        {url:"https://kinetisensecloudwesteurope.azurewebsites.net", name:"West Europe"},
        {url:"https://kinetisensecloudsoutheastaustralia.azurewebsites.net", name:"Australia"},
        {url:"https://kinetisensesubscriptionsystem.azurewebsites.net", name:"Subscription"},
        {url:"https://kinetisensesubscriptionsystemdev.azurewebsites.net", name:"Subscription DEV"},
        {url:"http://localhost:5000", name:"Local"}
]

// export const LicenceTemplates = [
//     {
//         displayName:"Kinetisense Flex 360",
//         modules:"ROM,Posture,Functional,Balance,ModifiedVeles,OverheadSquat,PostureAngel,ReverseLunge,VerticalJump,SingleLegHop,ClinicalGait",
//         features:"NeutralShoulderRoms,SupineRoms,DualRoms,Workflow,KAMS"
//     },
//     {    
//         displayName:"Kinetisense Flex Diamond",
//         modules:"ROM,Posture,Functional,Balance,ModifiedVeles,OverheadSquat,PostureAngel,ReverseLunge,VerticalJump,SingleLegHop,Gait,ClinicalGait,Wheelchair,TimedUpAndGo,SitToStand,FiveXSitToStand,ModifiedCtSib",
//         features:"AdvancedDataTools,AdvancedCsvExport,BulkUpload,LoadExternalVideo,FpmExport,PatientGroups,RiskOfFallWorkflow,WhiteLabel,NeutralShoulderRoms,SupineRoms,DualRoms,Workflow,KAMS,PatientAppInvite"
//     },
//     {
//         displayName:"Kinetisense Flex Senior Living",
//         modules:"ROM,Posture,Functional,Balance,ModifiedVeles,OverheadSquat,PostureAngel,ReverseLunge,VerticalJump,Gait,ClinicalGait,Wheelchair,TimedUpAndGo,SitToStand,FiveXSitToStand,ModifiedCtSib",
//         features:"AdvancedDataTools,AdvancedCsvExport,BulkUpload,LoadExternalVideo,FpmExport,PatientGroups,RiskOfFallWorkflow,WhiteLabel,NeutralShoulderRoms,SupineRoms,DualRoms,Workflow,PatientAppInvite"
//     },
//     {
//         displayName:"Kinetisense Flex Academic",
//         modules:"Gait,ClinicalGait,Wheelchair,TimedUpAndGo,FiveXSitToStand,ModifiedCtSib",
//         features:"AdvancedDataTools,AdvancedCsvExport,BulkUpload,LoadExternalVideo,FpmExport,PatientGroups,RiskOfFallWorkflow,WhiteLabel,NeutralShoulderRoms,SupineRoms,DualRoms,Workflow,PatientAppInvite"
//     },
//     {
//         displayName:"Kinetisense Aegis",
//         modules:"ROM,Posture,Functional,Balance,ModifiedVeles,OverheadSquat,PostureAngel,ReverseLunge,VerticalJump,SingleLegHop,Gait,ClinicalGait,Wheelchair,TimedUpAndGo,SitToStand,FiveXSitToStand,ModifiedCtSib",
//         features:"AdvancedDataTools,AdvancedCsvExport,BulkUpload,FpmExport,PatientGroups,RiskOfFallWorkflow,WhiteLabel,NeutralShoulderRoms,SupineRoms,DualRoms,Workflow,KAMS,KAMSSeniorLiving,Branding_Aegis,PatientAppInvite"
//     },
//     {
//         displayName:"Kinetisense PTGenie",
//         modules:"ROM,Posture,Functional,Balance,ModifiedVeles,OverheadSquat,PostureAngel,ReverseLunge,VerticalJump,SingleLegHop,Gait,ClinicalGait,Wheelchair,TimedUpAndGo,SitToStand,FiveXSitToStand,ModifiedCtSib",
//         features:"AdvancedDataTools,AdvancedCsvExport,BulkUpload,LoadExternalVideo,FpmExport,PatientGroups,RiskOfFallWorkflow,WhiteLabel,NeutralShoulderRoms,SupineRoms,DualRoms,Workflow,KAMS,Branding_PTGenie,PatientAppInvite"
//     },
//     {
//         displayName:"Kinetisense Symbria",
//         modules:"ROM,Posture,Functional,Balance,ModifiedVeles,OverheadSquat,PostureAngel,ReverseLunge,VerticalJump,SingleLegHop,Gait,ClinicalGait,Wheelchair,TimedUpAndGo,SitToStand,FiveXSitToStand,ModifiedCtSib",
//         features:"AdvancedDataTools,AdvancedCsvExport,BulkUpload,LoadExternalVideo,FpmExport,PatientGroups,RiskOfFallWorkflow,WhiteLabel,NeutralShoulderRoms,SupineRoms,DualRoms,Workflow,KAMS,Branding_Symbria,PatientAppInvite"
//     }
    
// ];

export const OrganizationThemes = [
    "Kinetisense",
    "PTGenie",
    "Symbria"
]

export const OrganizationUserRoles = [
    "Administrator",
    "Viewer",
    "Dashboard"
]

export const CorrectivesMarkets = [
    "General",
    "Senior Living"
]

export const CorrectivesGroups = [
    "Core",
    "RockTape",
    "PTGenie"
]

export const CorrectivesLevels = [
    "1",
    "2",
    "3"
]

export const CorrectivesJoints = [
    "ANKLE",
    "CERVICAL",
    "CORE",
    "HIP",
    "KNEE",
    "LUMBAR",
    "OCCIPITAL",
    "SCAPULA",
    "SHOULDER",
    "SI_JOINT",
    "THORACIC",
    "WRIST"
]

export const languageList = [
    {id:"EN-US", name:"English (USA)"}, 
    {id:"FR-FR", name:"French (France)"}, 
    {id:"ES", name:"Spanish"}, 
    {id:"DE", name:"German"}, 
    {id:"PT-BR", name:"Portuguese (Brasil)"}, 
    {id:"PL", name:"Polish"}, 
    {id:"ZH-CH", name:"Chinese (Simplified)"}, 
    {id:"JA", name:"Japanese"},
    {id:"NL-NL", name:"Dutch (Netherlands"},
]

export const ClinicFields = [
   {name:"Clinic.Name",type:"string"},
   {name:"Clinic.VatNumber",type:"string"},
   {name:"Clinic.PatientGroup",type:"string"},
   {name:"Clinic.Address",type:"string"},
   {name:"Clinic.Phone",type:"string"},
   {name:"CurrentUser.IsAdministrator",type:"bool"}
]

export const PatientFields = [
    {name: "Patient.Name", type:"string"},
    // {name: "Patient.MiddleName", type:"string"},
    {name: "Patient.Surname", type:"string"},
    {name: "Patient.Email", type:"string"},
    {name: "Patient.IsMale", type:"bool"},
    {name: "Patient.BirthDay", type:"date"},
    {name: "Patient.Phone", type:"string"},
    {name: "Patient.Address", type:"string"},
    //{name: "Patient.HealthcareNumber", type:"string"},
    {name: "Patient.Height", type:"number"},
    {name: "Patient.Weight", type:"number"},
    {name: "Patient.Notes", type:"string"},
    // {name: "Patient.School", type:"string"},
    {name: "Patient.IsRightHanded", type:"bool"},
    {name: "Patient.IsRightFooted", type:"bool"},
    {name: "Patient.IsClubSportParticipant", type:"bool"},
    {name: "Patient.IsHighSchoolSportParticipant", type:"bool"},
    {name: "Patient.HasPlayedContactSport", type:"bool"},
    {name: "Patient.SportsPlayed_csv", type:"string"},
    {name: "Patient.HasKnownConditions", type:"bool"},
    {name: "Patient.NumberOfKnownConditions", type:"number"},
    {name: "Patient.HasKnownConcussions", type:"bool"},
    {name: "Patient.NumberOfKnownConcussions", type:"number"},
    {name: "Patient.HasMusculoSkeletalInjuries", type:"bool"},
    {name: "Patient.MusculoSkeletalInjuries_csv", type:"bool"},
    {name: "Patient.IsWearingGlasses", type:"bool"},
    {name: "Patient.HasVisionDisorder", type:"bool"},
    {name: "Patient.VisionDisorders_csv", type:"string"},
    {name: "Patient.HasHearingLoss", type:"bool"},
    {name: "Patient.HearingLoss", type:"number"},
    {name: "Patient.HasLearningDisability", type:"bool"},
    {name: "Patient.PatientGroup", type:"string"}
]

export const PractitionerFields = [
    {name: "Practitioner.Name", type:"string"},
    // {name: "Practitioner.MiddleName", type:"string"},
    {name: "Practitioner.Surname", type:"string"},
    {name: "Practitioner.Email", type:"string"},
    {name: "Practitioner.Phone", type:"string"},
    {name: "Practitioner.Address", type:"string"},
    {name: "Practitioner.Bio", type:"string"},
    {name: "Practitioner.Specialty", type:"string"},
    {name: "Practitioner.IsAdministrator", type:"bool"},
    {name: "Practitioner.IsOperator", type:"bool"},
]

export const AssessmentFields = [
    {name: "Assessment.HydrationLevel", type:"number"},
    {name: "Assessment.EnergyLevel",  type:"number"},
    {name: "Assessment.PainType",  type:"string"},
    {name: "Assessment.PainIntensity",  type:"number"},
    {name: "Assessment.SensorType",  type:"string"},
    {name: "Assessment.IsShared",  type:"bool"},
    {name: "Assessment.MovementSkipped",  type:"bool"}
]

export const SSOTypes = [
    "None",
    "GoogleOAuth",
    "AzureSAML20",
    "AzureOAuth",
    "CernerOAuth"
]