import * as React from 'react';
import TextField from '@mui/material/TextField';
import {DesktopDatePicker} from '@mui/x-date-pickers/DesktopDatePicker'
import  Button  from '@mui/material/Button';
import Autocomplete from '@mui/material/Autocomplete';
import { LicenceTypes } from '../../data/ControlledLists';
import { String2NumberOfItems } from '../../Utilities/Utilities';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import ClearIcon from '@mui/icons-material/Clear';
// import IconButton from '@material-ui/core/IconButton';
import IconButton from '@mui/material/IconButton';

const getDate = (date) => {
    var d = new Date(date);
    var year = d.getFullYear();
    var month = d.getMonth() + 1;
    var day = d.getDate();
    var datestring = year  + "/" + month + "/" + day;
    return datestring;
}
 const  LicenceInformation = ({data, onFieldChange, organizations, flexLicenceTemplates}) => {    

    const [value, setValue] = React.useState(new Date(getDate(data.expirationDate)));

    const handleChange = (newValue) => {
      setValue(newValue);
      //console.log(newValue);
      try {
         onFieldChange(null, newValue.toISOString(), "expirationDate")
      } catch (err) {
         
      }
    };
   const max=100;
   const min = 0;
   //console.log("Organizations: ",organizations)

   const showTemplates = () => {
        console.log("Show Templates")

        const templates = flexLicenceTemplates.map((el) => {
            return el.name;
        });
        
        return (
            <div>
                <Autocomplete
                id="template"
                className="combobox"
                autoHighlight={true}
                disablePortal
                noOptionsText="Licence Template"
                value={data.displayName}
                size='small'
                options={templates.sort((a,b) => a > b ? 1 : -1)}
                onChange={(event, value) => onFieldChange(event, value, "displayName")}
                renderInput={(params) => <TextField {...params} label="Licence Template" />}
            />
            </div>
        );
    }

    if (data.purchaseEmail !== undefined) {
    return (
        <div>

            <div>
                <DesktopDatePicker
                    className="DatePicker"
                    id="expirationDate"
                    label="Licence Expiration Date"
                    size='small'
                    value={data.expirationDate}
                    onChange={(newValue) => {handleChange(newValue)}}
                    // onDateChange={(newValue) =>  {handleDatePicker(newValue)}}
                    renderInput={(params) => <TextField {...params} />}
                />
            </div>
            <div>
               
                <Autocomplete
                id="name"
                className="combobox"
                autoHighlight={true}
                disablePortal
                noOptionsText="Licence Type"
                value={data.name}
                size='small'
                options={LicenceTypes}
                onChange={(event, value) => onFieldChange(event, value, "name")}
                renderInput={(params) => <TextField {...params} label="Licence Type" />}
               
                />
            </div >
            {data.name === 'kinetisense-flex' && showTemplates()}
            <div className='e-input-group e-small'>
                <TextField
                    fullWidth
                    id="purchaseName" 
                    label="Purchase Name" 
                    variant="outlined"
                    className="TextField"
                    size='small'
                    value={data.purchaseName}
                    onChange={(event, value) => onFieldChange(event, event.target.value, event.target.id)}
                    InputLabelProps={{
                        shrink: true,
                      }}
                />
            </div>
            <div>
                <TextField
                    fullWidth
                    id="purchaseEmail" 
                    label="Purchase Email" 
                    variant="outlined"
                    className="TextField"
                    size='small'
                    value={data.purchaseEmail}
                    onChange={(event, value) => onFieldChange(event, event.target.value, event.target.id)}
                    InputLabelProps={{
                        shrink: true,
                      }}
                />
            </div>
            <div>
                <TextField
                    fullWidth
                    id="description" 
                    label="Server Name" 
                    variant="outlined"
                    className="TextField"
                    size='small'
                    value={data.description}
                    onChange={(event, value) => onFieldChange(event, event.target.value, event.target.id)}
                    InputLabelProps={{
                        shrink: true,
                      }}
                />
             </div>
             <div>
                <TextField
                    fullWidth
                    id="salesPersonEmail" 
                    label="Sales Contact Email" 
                    variant="outlined"
                    className="TextField"
                    size='small'
                    value={data.salesPersonEmail}
                    onChange={(event, value) => onFieldChange(event, event.target.value, event.target.id)}
                    InputLabelProps={{
                        shrink: true,
                      }}
                />
             </div>
             <div>
                <FormControl fullWidth className='TextField'>
                    <InputLabel id="Organization-label">Organization</InputLabel>
                    <Select
                        labelId="Organization"
                        id="organization"
                        value={data.organizationId}
                        label="Organization"
                        size="small"
                        onChange={(event, value) => onFieldChange(event, event.target.value, "organization")}
                    >
                        <MenuItem value={""}>""</MenuItem>
                        {organizations.map(row => {return (<MenuItem value={row.id}>{row.name}</MenuItem>)} )}
                        
                    </Select>
                </FormControl>
             </div>
            <div className='horizontal'>
                <TextField
                    // fullWidth
                    style={{marginRight:0, width:90}}
                    id="amount" 
                    label="# of seats" 
                    variant="outlined"
                    type="number"
                    className="TextField"
                    size='small'
                    InputProps={{
                        inputProps: { 
                            max: {max}, min: {min} 
                        }
                    }}
                    value={data.amount}
                    onChange={(event) => {
                        var value = parseInt(event.target.value, 10);
                        if (value > max) value = max;
                        if (value < min) value = min;
                        onFieldChange(event, value, event.target.id
                        )}}
                    InputLabelProps={{
                        shrink: true,
                      }}
                />
             {/* </div>
            <div> */}
                <TextField
                    // fullWidth
                    style={{marginRight:0, width:120}}
                    id="maxNumberOfDevices" 
                    label="Max # of dev" 
                    variant="outlined"
                    type="number"
                    className="TextField"
                    size='small'
                    InputProps={{
                        inputProps: { 
                            max: {max}, min: {min} 
                        }
                    }}
                    value={data.maxNumberOfDevices}
                    onChange={(event) => {
                        var value = parseInt(event.target.value, 10);
                        if (value > max) value = max;
                        if (value < min) value = min;
                        onFieldChange(event, value, event.target.id
                        )}}
                    InputLabelProps={{
                        shrink: true,
                      }}
                /> 
            {/* </div>
            <div> */}
                    <TextField
                        style={{width:90, marginRight:0}}    
                        id="ResetList" 
                        label="Dev used" 
                        variant="outlined"
                        value={String2NumberOfItems(data.deviceIdList)}
                        type="number"
                        className="TextField"
                        size='small'
                        disabled={true}
                        InputLabelProps={{
                            shrink: true,
                        }}

                    /> 
            {/* </div>
            <div> */}
                        <IconButton
                            onClick={(event, value) => onFieldChange(event, data.readOnlyDevices, "deviceIdList")}
                            variant="contained"
                            style={{marginLeft:-40}}
                            size='small'
                            label="Clear"
                            color='secondary'
                        >
                            <ClearIcon color='red' />
                        </IconButton>

                        <TextField
                        style={{width:90, marginRight:5}}    
                        id="RODevicesNum" 
                        label="# R/O Dev" 
                        variant="outlined"
                        value={String2NumberOfItems(data.readOnlyDevices)}
                        type="number"
                        className="TextField"
                        size='small'
                        disabled={true}
                        InputLabelProps={{
                            shrink: true,
                        }}

                    /> 
                        <div className='Checkbox-box' style={{marginLeft:5}}>
                        <span class="Checkbox-title">Is Test Lic</span>
                        <FormControlLabel 
                            id="isTestLicence"
                            value={data.isTestLicence}
                            size='small'
                            control={<Checkbox 
                                style={{marginTop:0, marginLeft:-50}}
                                checked={data.isTestLicence} 
                                color="primary"
                                id="isTestLicence"
                                size='small'
                                onChange={(event) => {onFieldChange(event, event.target.checked, event.target.id)}}
                            />}
                            // label=""
                            // labelPlacement="top"
                            onChange={(event) => {onFieldChange(event, event.target.checked, event.target.id)}}
                        />
                    </div>
                  
            </div>
            <div>
            <TextField
                fullWidth
                id="notes"
                label="Notes"
                multiline
                rows={3}
                value={data.notes}
                variant="outlined"
                InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(event, value) => onFieldChange(event, event.target.value, event.target.id)}
        />
            </div>
        </div>
    );
    } else {
        return (<label>Loading...</label>)
    }
}

export default LicenceInformation;